angular.module('app.main.public.short-url')
    .controller('ShortUrlCtrl', ShortUrlCtrl);

ShortUrlCtrl.$inject = [
    '$location',
    '$log',
    '$scope',
    '$state',
    '$window',
    '$document',
    '$mdSidenav',
    '$q',
    '$timeout',
    'AuthSvc',
];

function ShortUrlCtrl(
    $location,
    $log,
    $scope,
    $state,
    $window,
    $document,
    $mdSidenav,
    $q,
    $timeout,
    AuthSvc,
) {
    let self = this;
    let handlers = {};

    angular.extend(self, {
        loading: true,
        handlers: handlers,
    });

    this.$onInit = () => {
        if (AuthSvc.isAuthenticated()) {
            AuthSvc.redirect();
        }
    };

    // Handlers --------------------------------------------------------------------------------------------------------

}
