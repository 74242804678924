angular.module('app.main.shared')
	.factory('JobsPrequeueSvc', function (RequestSvc) {

		function process() {
			return RequestSvc.get('api/jobs-prequeue/process');
		}

		return {
			process: process
		};

	});
