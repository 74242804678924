angular.module('app.main.customer.account-setup', [])
    .component('paymentMethodSave', {
        templateUrl: 'app/main/modules/customer/components/paymentMethodSave/PaymentMethodSave.html',
        controller: PaymentMethodSave,
        bindings: {
            user: '<',
            // setupIntent: '=',
        },
    })
;

PaymentMethodSave.$inject = [
    '$scope',
    '$state',
    '$q',
    '$filter',
    '$timeout',
    '$window',
    '$localStorage',
    'moment',
    'StripeElements',
    'PaymentCardSvc',
];

function PaymentMethodSave(
    $scope,
    $state,
    $q,
    $filter,
    $timeout,
    $window,
    $localStorage,
    moment,
    StripeElements,
    PaymentCardSvc,
) {
    let self = this;
    let handlers = {};

    angular.extend(self, {
        handlers: handlers,
        loading: true,
        setupIntent: {},
        cardNumberElement: {},
        form: [
            //
        ],
    });

    let elementStyles = {
        base: {
            color: '#36495F',
            fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            ':focus': {
                color: '#424770',
            },

            '::placeholder': {
                color: '#C0CCDA',
            },

            ':focus::placeholder': {
                color: '#C0CCDA',
            },
        },
        invalid: {
            color: '#fff',
            ':focus': {
                color: '#424770',
            },
            // '::placeholder': {
            //     color: '#FFCCA5',
            // },
        },
    };

    this.$onInit = () => {
        console.log('loading PaymentMethodSave');

        PaymentCardSvc.createSetupIntent()
            .then((response) => {
                // console.log(response);

                self.setupIntent = response.data.setup_intent;
                // Find
                let elements = StripeElements.elements();
                // Create
                let cardNumberElement   = elements.create('cardNumber', {style: elementStyles});
                let cardExpElement      = elements.create('cardExpiry', {style: elementStyles});
                let cardCvcElement      = elements.create('cardCvc', {style: elementStyles});
                // Mount
                cardNumberElement.mount('#stripe-card-number');
                cardExpElement.mount('#stripe-card-expiry');
                cardCvcElement.mount('#stripe-card-cvc');
                // Hmm
                cardNumberElement.on('change', handleChange);
                cardExpElement.on('change', handleChange);
                cardCvcElement.on('change', handleChange);
            }).finally(() => {
                self.loading = false;
                console.log(self.setupIntent);
        });
    };

    function handleChange (e) {
        console.log(e);
        this.cardErrors = e.error ? e.error.message : ''
    }

    // Handlers --------------------------------------------------------------------------------------------------------

    handlers.submit = (params) => {
        console.log('submitted');
        console.log(params);
    };
}