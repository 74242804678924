angular.module('app.main.customer.property.overview', [

])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.customer.property.overview', {
				url: '/overview/{propertyId}',
				params: {
					propertyId: undefined,
				},
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_property/_overview/PropertyOverview.html',
						controller: 'PropertyOverviewCtrl',
						controllerAs: 'Overview',
					}
				},
				ncyBreadcrumb: {
					label: 'Overview | {{ property }}',
				},
			})
		;

	}]);
