angular.module('app.main.public')
	.controller('IndexCtrl', [
		'$log',
		'$scope',
		'$state',
		'$window',
		'$localStorage',
		'$mdMedia',
		'$document',
		'$timeout',
		'$mdSidenav',
		'focus',
		'$intercom',
		'AuthSvc',
		function ($log,
				  $scope,
				  $state,
				  $window,
				  $localStorage,
				  $mdMedia,
				  $document,
				  $timeout,
				  $mdSidenav,
				  focus,
				  $intercom,
				  AuthSvc) {


			let self = this;

			let handlers = {};

			angular.extend(self, {
				handlers: handlers,
				form: {},
				account: {},
				defaultBeds: 3,
				cookiePolicyAccepted: true,
			});

			this.$onInit = () => {
				self.account = $localStorage.account || {};


				if (angular.isObject(self.account.services)) {
					self.applicationInProgress = true;
				}


				$scope.$watch(function() { return $mdMedia('gt-sm'); }, function(big) {
					if (big) {
						self.scrollOffset = 0;
					}
					else {
						self.scrollOffset = 0;
					}
				});

				if (AuthSvc.isAuthenticated()) {
					self.authenticated = true;
				}
				else {
					self.authenticated = false;
				}

				$timeout(() => {
					focus('postcode');
					self.cookiePolicyAccepted = $localStorage.cookiePolicyAccepted;
					self.loaded = true;
					const trustbox = document.getElementById('trustbox');
					window.Trustpilot.loadFromElement(trustbox);
				}, 500);
			};

			handlers.acceptCookiePolicy = () => {
				$localStorage.cookiePolicyAccepted = true;
				self.cookiePolicyAccepted = true;
			};

			handlers.addressLookupClick = (subForm) => {
				$localStorage.account = {
					postcode_entered: self.form.postcode.toUpperCase(),
				};

				$state.go('main.customer.property.build');
			};

			handlers.scrollIt = () => {
				let someElement = angular.element(document.getElementById('anchor'));
				let $element = angular.element('body');
				$element.scrollToElement(someElement, 100, 750);
			};

			handlers.showCompareClick = () => {
				let someElement = angular.element(document.getElementById('how-we-compare'));
				let $element = angular.element(document.getElementById('public-view'));
				$element.scrollToElement(someElement, 100, 750);
			};

			handlers.showIntercom = () => {
				// $intercom.show();
			};

			handlers.buildPackageClick = () => {
				$state.go('main.customer.property.build');
			};


			handlers.continueApplicationClick = () => {
				// figure out where the user is in their application, starting at the end
				if (angular.isObject(self.account.card)) {
					$state.go('main.customer.property.payment-info');
				}
				else if (angular.isObject(self.account.details)) {
					$state.go('main.customer.property.personal-details');
				}
				else if (angular.isObject(self.account.services)) {
					$state.go('main.customer.property.package-selection');
				}
			};

		}
	]);

