angular.module('app.main.public.auth')
	.factory('RegisterSvc', function (RequestSvc) {

		function register(params) {
			return RequestSvc.post('api/register/create', params);
		}

		return {
			register: register
		};

	});
