angular.module('app.main.public', [
	'app.main.public.auth',
	'app.main.public.connection-acknowledgement',
	'app.main.public.short-url',
	'app.main.public.magic-link',
])
	.config(['$stateProvider', function ($stateProvider) {
		$stateProvider

			.state('main.public.index', {
				url: '/',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Index.html',
						controller: 'IndexCtrl',
						controllerAs: 'Index',
					}
				},
				ncyBreadcrumb: {
					label: 'Home'
				},
				data: {
					showLogo: false,
				}
			})

			.state('main.public.quote', {
				url: '/quote',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/customer/_property/_package-builder/PackageBuilder.html',
						controller: 'PackageBuilderCtrl',
						controllerAs: 'Builder',
					}
				},
				ncyBreadcrumb: {
					label: 'Build a package'
				},
				data: {
					checkLogin: false,
				}
			})

			.state('main.public.index2', {
				url: '/2',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Index2.html',
						controller: 'IndexCtrl',
						controllerAs: 'Index',
					}
				},
				ncyBreadcrumb: {
					label: 'Home'
				},
				data: {
					showLogo: false,
				}
			})

			.state('main.public.terms', {
				url: '^/terms',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Terms.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Terms and Conditions'
				},
			})

			.state('main.public.privacy', {
				url: '^/privacy-policy',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/PrivacyPolicy.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Privacy Policy'
				},
			})

			.state('main.public.accessibility', {
				url: '^/accessibility-policy',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/AccessibilityPolicy.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Accessibility'
				},
			})

			.state('main.public.contact', {
				url: '^/contact',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Contact.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Contact'
				},
			})

			.state('main.public.press', {
				url: '^/press-enquiries',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/PressEnquiries.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Press'
				},
			})

			.state('main.public.careers', {
				url: '^/careers-at-mango',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Careers.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Careers'
				},
			})

			.state('main.public.complaints', {
				url: '^/complaints',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Complaints.html',
						controller: 'AncillaryCtrl'
					}
				},
				ncyBreadcrumb: {
					label: 'Complaints'
				},
			})

			.state('main.public.tariffs', {
				url: '^/tariffs',
				params: {
					cookies: undefined,
				},
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Tariffs.html',
						controller: 'TariffCtrl',
						controllerAs: 'Tariff',
					}
				},
				ncyBreadcrumb: {
					label: 'Tariffs'
				},
			})

			// .state('main.public.voucher-terms', {
			// 	url: '^/voucher-terms',
			// 	views: {
			// 		'content@main.public': {
			// 			templateUrl: 'app/main/modules/public/templates/VoucherTerms.html',
			// 		}
			// 	},
			// 	ncyBreadcrumb: {
			// 		label: 'Contract Terms'
			// 	},
			// })

			.state('main.public.contract-terms', {
				url: '^/contract-terms',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/ContractTerms.html',
						controller: 'ContractTermsCtrl',
						controllerAs: 'ContractTerms',
					}
				},
				ncyBreadcrumb: {
					label: 'Contract Terms'
				},
			})

			.state('main.public.howItWorks', {
				url: '^/how-it-works',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/HowItWorks.html',
					},
				},
				ncyBreadcrumb: {
					label: 'How it works',
				},
				data: {
					navThemeColour: 'dark',
				}
			})

			.state('main.public.compare', {
				url: '^/how-we-compare',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/HowWeCompare.html',
					},
				},
				ncyBreadcrumb: {
					label: 'How we compare',
				},
				data: {
					navThemeColour: 'dark',
				}
			})

			.state('main.public.services', {
				url: '^/services',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/Services.html',
					},
				},
				ncyBreadcrumb: {
					label: 'Additional services and bolt-ons',
				},
				data: {
					navThemeColour: 'dark',
				}
			})

			.state('main.public.allInOnePlace', {
				url: '^/all-in-one-place',
				views: {
					'content@main.public': {
						templateUrl: 'app/main/modules/public/templates/AllInOnePlace.html',
					},
				},
				ncyBreadcrumb: {
					label: 'All your bills in one place',
				},
				data: {
					navThemeColour: 'dark',
				}
			})

			// Misc ------------------------------------------------------------------------------------

			// .state('main.public.styleguide', {
			// 	url: '/style-guide',
			// 	views: {
			// 		'content@main.public': {
			// 			templateUrl: 'app/main/modules/public/templates/styleguide/StyleGuide.html',
			// 		}
			// 	},
			// 	ncyBreadcrumb: {
			// 		label: 'Style Guide'
			// 	}
			// })
		;

	}]);
