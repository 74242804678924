angular.module('app.main')
	.directive('popover2', function ($document, $window, $timeout) {
		return {
			restrict: 'AE',
			scope: {
				open: '=?',
				onSelect: '=?',
				onClose: '&',
			},
			transclude: true,
			replace: true,
			template: '<div ng-transclude class="popover-container"></div>',
			link: function (scope, el, attr, ngModelCtrl) {
				scope.config = scope.$parent.$eval(attr.popoverConfig);
				scope.isOpen = false;

			},
			controller: function ($scope) {
			}
		};
	});


angular.module('app.main')
	.directive('popoverTrigger2', function ($document, $window, $timeout, popoverSvc, $parse, Popover2Svc) {
		return {
			restrict: 'E',
			require: '^popover2',
			transclude: true,
			replace: true,
			template: '<div ng-transclude class="popover-trigger"></div>',
			link: function (scope, el, attr, ngModelCtrl) {

				if (attr.popoverWidth === 'full') {
					el.css({'display': 'block'});
					el.parent()
						.css({'display': 'block'});
				}

				// If a user clicks on any of these elements then it will automatically close
				let closeOnClick = [
					'a',
					'button',
					'submit',
				];

				$document.on('click', function (e) {
					let config = scope.$parent.config;
					// let keepOpen = config.hasOwnProperty('keepOpen') && config.keepOpen;
					let targetType = e.target.tagName.toLowerCase();
					let popoverContainerEl = el.parent();

					let clickedOnCloseableElement = closeOnClick.includes(e.target.tagName.toLowerCase());

					// Check if we've clicked on anything outside our popup OR on a closeable element
					if ((el !== e.target && !popoverContainerEl[0].contains(e.target)) || (clickedOnCloseableElement) && scope.$parent.isOpen) {
						closePopover();
					}
				});

				// Trigger when user clicks anywhere within the trigger element
				el.on('click', (event) => {
					event.stopPropagation();
					if (!scope.$parent.isOpen) {
						closeAllOpenPopovers();
						openPopover();
					}
					else {
						closePopover();
					}
				});

				el.on('mouseover', (event) => {
					let config = scope.$parent.config;
					if (config.hasOwnProperty('openOnHover') && config.openOnHover) {
						event.stopPropagation();
						if (!scope.$parent.isOpen) {
							openPopover();
						}
					}
				});

				function openPopover() {
					let config = scope.$parent.config;
					// Get the content element (it's always the following element after trigger)
					let transcludeContentEl = el.next('.popover-content-transclude');
					setPosition(el, transcludeContentEl);

					if (config.hasOwnProperty('openOnHover') && config.openOnHover) {
						let body = angular.element(document.body);
						body.on('mouseover', (event) => {
							if (!el.parent()[0].contains(event.target)) {
								closePopover();
								$('body')
									.off();
							}
						});
					}
					Popover2Svc.add({
						el: transcludeContentEl,
						scope: scope,
					});
					scope.$parent.open = true;
				}

				function closePopover() {
					let transcludeContentEl = el.next('.popover-content-transclude');
					let contentEl = transcludeContentEl.find('.popover-content');

					transcludeContentEl.css({
						display: 'none',
					});
					contentEl.css({
						display: 'none',
					});
					scope.$parent.isOpen = false;
					scope.$parent.open = false;
					Popover2Svc.get()
						.forEach((item, index) => {
							if (item.scope === scope) {
								item.scope.$parent.isOpen = false;
								Popover2Svc.get()
									.splice(Popover2Svc.get()
										.indexOf(item), 1);
							}
						});
				}

				function closeAllOpenPopovers() {
					Popover2Svc.get()
						.forEach((item, index) => {
							angular.element(item.el)
								.css({'display': 'none'});
							angular.element(item.el)
								.find('.popover-content')
								.css({'display': 'none'});
							item.scope.$parent.isOpen = false;
							Popover2Svc.get()
								.splice(Popover2Svc.get()
									.indexOf(item), 1);

						});

					let transcludeEls = document.querySelectorAll('.popover-content-transclude');
					transcludeEls.forEach((item, index) => {
						angular.element(item)
							.css({'display': 'none'});
						angular.element(item)
							.find('.popover-content')
							.css({'display': 'none'});
					});
				}


				function setPosition(triggerEl, transcludeContentEl) {
					let config = scope.$parent.config;
					let contentEl = transcludeContentEl.find('.popover-content');

					// Reset all animation classes
					contentEl.removeClass('up-animation down-animation');

					let bottom = 0, left = 0, top = 0, right = 0, paddingTop = 0, paddingBottom = 0;
					let minWidth = 200; // default
					let borderRadius = 0; // default
					let contentAnimation = 'down-animation' + ' ' + config.xPosition;

					if (config.hasOwnProperty('yPosition') && config.yPosition === 'top') {
						// Position element below the trigger
						bottom = triggerEl.height();
						top = 'auto';
						paddingBottom = 15;
						contentAnimation = 'up-animation';
					}
					else {
						// position element above trigger
						bottom = 'auto';
						top = triggerEl.height();
						paddingTop = 15;
					}

					// Align element to the right of the trigger
					if (config.hasOwnProperty('xPosition') && config.xPosition === 'right') {
						left = 'auto';
						right = 0;
					}

					if (config.hasOwnProperty('minWidth')) {
						minWidth = config.minWidth;
					}

					if (config.hasOwnProperty('borderRadius')) {
						borderRadius = config.borderRadius;
					}

					setTranscludeContentElPosition(transcludeContentEl, bottom, top, left, right, minWidth);
					setContentElPosition(contentEl, paddingTop, paddingBottom, borderRadius, contentAnimation);

					// Check if it's in bounds, if not change top/bottom
					// todo:: re-position left/right
					let bounding = contentEl[0].getBoundingClientRect();
					if (bounding.top < 0) {
						// Top is out of viewport
						// Reset all animation classes
						contentEl.removeClass('up-animation down-animation');
						bottom = 'auto';
						top = triggerEl.height();
						paddingTop = 15;
						contentAnimation = 'down-animation';
						setTranscludeContentElPosition(transcludeContentEl, bottom, top, left, right);
						setContentElPosition(contentEl, paddingTop, paddingBottom, contentAnimation);
					}

					if (bounding.left < 0) {
						// Left side is out of viewoprt
						console.log('left is out of view');
					}

					if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
						// Bottom is out of viewport
						// Reset all animation classes
						contentEl.removeClass('up-animation down-animation');

						bottom = triggerEl.height();
						top = 'auto';
						paddingBottom = 15;
						contentAnimation = 'up-animation';

						setTranscludeContentElPosition(transcludeContentEl, bottom, top, left, right);
						setContentElPosition(contentEl, paddingTop, paddingBottom, contentAnimation);
					}

					if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
						// Right side is out of viewport
						console.log('right is out of view');
					}
					scope.$parent.isOpen = true;
				}

				function setTranscludeContentElPosition(el, bottom, top, left, right, minWidth) {
					el.css({
						display: 'inline-block',
						bottom: bottom,
						top: top,
						left: left,
						right: right,
						'min-width': minWidth,
					});
				}

				function setContentElPosition(el, paddingTop, paddingBottom, borderRadius, animationClass) {
					el.addClass(animationClass);
					el.css({
						'display': 'inline-block',
						'padding-top': paddingTop,
						'padding-bottom': paddingBottom,
						'border-radius': borderRadius,
					});
				}

			}
		};
	});


angular.module('app.main')
	.directive('popoverContent2', function ($document) {
		return {
			restrict: 'E',
			require: '^popover2',
			transclude: true,
			replace: true,
			template: '<div  class="popover-content-transclude"><div ng-transclude class="popover-content"></div>',
			// templateUrl: 'app/main/directives/popover/popover.html',
			link: function (scope) {


			}
		};
	});

angular.module('app.main')
	.service('Popover2Svc', function () {
		let self = this;
		let popoverArray = [];

		self.add = function (item) {
			popoverArray.push(item);
		};

		self.get = function () {
			return popoverArray;
		};

		self.empty = function () {
			popoverArray = [];
		}
	});
