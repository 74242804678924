angular.module('app.main').controller('PublicCtrl', ['$scope', '$document', function ($scope, $document) {




	var self = $scope.PublicCtrl = {
		methods: {

		}
	}



}]);
