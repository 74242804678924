angular.module('app.main').directive("moveNextOnMaxlength", function() {
	return {
		restrict: "A",
		link: function($scope, element, attr) {
			element.on("input", function(e) {
				if(element.val().length == element.attr("maxlength")) {
					var $nextElement = angular.element(document.querySelector('#'+attr.moveNextOnMaxlength));
					if($nextElement.length) {
						$nextElement[0].select();
					}
				}
			});
		}
	}
});