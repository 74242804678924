angular.module('app.main.shared.utility')
	.factory('UtilityContractSvc', [
		'RequestSvc',
		function (RequestSvc) {

			var customerApiBasePath = 'api/customer/contract/';
			var adminApiBasePath = 'api/admin/utility/contract/';

			function transformContract(contract) {
				if (angular.isObject(contract)) {
					if (angular.isString(contract.starts_at)) {
						contract.starts_at = contract.starts_at ? moment(contract.starts_at)
								.toDate() : null;
					}
					if (angular.isString(contract.ends_at)) {
						contract.ends_at = contract.ends_at ? moment(contract.ends_at)
								.toDate() : null;
					}
				}
				return contract;
			}

			return {
				index: function (params) {
					return RequestSvc.get(customerApiBasePath + 'index', params);
				},
				options: function (params) {
					return RequestSvc.get(customerApiBasePath + 'options', params);
				},
				oldContractOptions: function (params) {
					return RequestSvc.get(customerApiBasePath + 'old-contract-options', params);
				},
				save: function (contract) {
					return RequestSvc.post(customerApiBasePath + 'save', contract, function (value) {
						if (angular.isObject(value.contract)) {
							value.contract = transformContract(value.contract);
						}
						return value;
					});
				},
				read: function (id, params) {
					return RequestSvc.get(customerApiBasePath + 'read/' + id, params, function (value) {
						if (angular.isObject(value.contract)) {
							value.contract = transformContract(value.contract);
						}
						return value;
					});
				},
				remove: function(params) {
					return RequestSvc.post(adminApiBasePath + 'remove', params);
				},
				energyUsageChart: function(params) {
					return RequestSvc.get(customerApiBasePath + 'energyUsageChart', params);
				},
				chargesChart: function(params) {
					return RequestSvc.get(customerApiBasePath + 'chargesChart', params);
				},
				transformContract: transformContract
			};

		}
	]);
