angular.module('app.main.shared.property')
	.component('propertySummaryPanels', {
		templateUrl: 'app/main/modules/customer/_property/_shared/propertySummaryComponent/PropertySummaryPanels.html',
		controller: PropertySummaryPanelsController,
		bindings: {
			property: '<',
		},
	});

PropertySummaryPanelsController.$inject = [
	'$scope',
	'FileDownloadSvc',
];

function PropertySummaryPanelsController(
	$scope,
	FileDownloadSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		handlers: handlers,
	});

	this.$onInit = () => {
		$scope.$watch(() => {
			return self.property;
		}, (value) => {
			if (angular.isObject(value)) {
				self.property = value;
				initChart();
			}
		});
	};

	function initChart() {
		if (self.property.active_budget_period) {
			let status = self.property.active_budget_period.forecast_status;
			let barColour = '';
			let trackColour = '';
			let stringMessage = '';
			let chartMessage = '';
			let difference = self.property.active_budget_period.budget_forecast_difference;
			if (status === 'under-budget') {
				barColour = '#13CE66';
				trackColour = '#f5fdf4';
				let underBudget = self.property.active_budget_period.budget_spend - self.property.active_budget_period.forecast_spend;
				chartMessage = '£' + underBudget.toFixed(0) + ' under budget';
				stringMessage = 'Your property is currently ' + (100 - difference) + '% under budget with ' + self.property.active_budget_period.time_remaining_label + ' remaining.';
			}
			else if (status === 'over-budget') {
				barColour = '#FF7849';
				trackColour = '#fbd692';
				difference -= 100;
				let overBudget = self.property.active_budget_period.forecast_spend - self.property.active_budget_period.budget_spend;
				chartMessage = '£' + overBudget.toFixed(0) + ' over budget';
				stringMessage = 'Your property is currently ' + difference + '% over budget with ' + self.property.active_budget_period.time_remaining_label + ' remaining.';
			}
			else if (status === 'over-fair-usage') {
				barColour = '#FF4949';
				trackColour = '#f9f2f2';
				difference = self.property.active_budget_period.forecast_fair_usage_difference - 100;
				let overBudget = self.property.active_budget_period.forecast_spend - self.property.active_budget_period.fair_usage;
				chartMessage = '£' + overBudget.toFixed(0) + ' over fair usage';
				stringMessage = 'Your property is currently ' + difference + '% over fair usage with ' + self.property.active_budget_period.time_remaining_label + ' remaining.';
			}
			angular.extend(self.property.active_budget_period, {
				budget_forecast_difference: difference,
				chart_message: chartMessage,
				string_message: stringMessage,
				pieOptions: {
					size: 120,
					animate:{
						duration:1000,
						enabled:true
					},
					barColor:barColour,
					trackColor: trackColour,
					scaleColor:false,
					lineWidth:10,
					lineCap:'circle'
				}
			});
			self.chartLoaded = true;
		}
	}

	handlers.downloadUsageStatementClicked = (usageStatement) => {
		FileDownloadSvc.download(usageStatement.file_id);
	}
}
