angular.module('app.main.shared')
	.factory('BankLookupSvc', function (RequestSvc) {

		function validate(params) {
			return RequestSvc.post('api/bank/validate', params);
		}

		return {
			validate: validate
		};

	});
