angular.module('app.main.public.auth')
	.controller('ForgotPasswordCtrl', ForgotPasswordCtrl);

ForgotPasswordCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'focus',
	'$timeout',
	'AuthSvc',
];

function ForgotPasswordCtrl($scope, $state, $q, focus, $timeout, AuthSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
		form: {},
	});

	this.$onInit = () => {
		self.loading = true;
		$timeout(() => {
			focus('email');
		}, 1000);
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = (isValid) => {
		if (isValid) {
			return AuthSvc.forgotPassword(self.form)
				.then(function (response) {
					self.emailSent = true;
				}, function (errors) {
					self.formErrors = errors;
				});
		}
		else {
			self.form.submitted = true;
		}
	};
}