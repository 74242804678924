angular.module('app.main.customer')
	.controller('PropertyIndexCtrl', PropertyIndexCtrl);

PropertyIndexCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$filter',
	'$localStorage',
	'AuthSvc',
	'PropertySvc',
	'CustomerAccountSvc',
];

function PropertyIndexCtrl(
	$scope,
	$state,
	$q,
	$filter,
	$localStorage,
	AuthSvc,
	PropertySvc,
	CustomerAccountSvc) {

	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		vouchers: [{}],
	});

	this.$onInit = () => {
		self.activeAccount = AuthSvc.getRawAccount();
		let promises = {
			properties: PropertySvc.index({
				order: '-created_at',
				page: 1,
				limit: 150,
				filter: {
					account: self.activeAccount.id
				}
			}),
			account: CustomerAccountSvc.read({id: self.activeAccount.id}),
		};

		$q.all(promises).then((responses) => {
			self.properties = responses.properties.data.property_collection;
			setChartColours();
			self.account = responses.account.data.account;
			self.loading = false;
			self.vouchers = $filter('filter')(self.properties, function (item) {
				if (item.voucher !== null) {
					let expiresAt = new Date(item.voucher.ends_at);
					let now = new Date();
					if (expiresAt > now) {
						return item.voucher;
					}
				}
			});
		});
	};

	function setChartColours() {
		angular.forEach(self.properties, (property) => {
			if (property.active_budget_period) {
				let status = property.active_budget_period.forecast_status;
				let barColour = '';
				let trackColour = '';
				let difference = property.active_budget_period.budget_forecast_difference;
				if (status === 'under-budget') {
					barColour = '#13CE66';
					trackColour = '#f5fdf4';
				}
				else if (status === 'over-budget') {
					barColour = '#FF7849';
					trackColour = '#fbd692';
					difference -= 100;
				}
				else if (status === 'over-fair-usage') {
					barColour = '#FF4949';
					trackColour = '#f9f2f2';
					difference = property.active_budget_period.forecast_fair_usage_difference - 100;
				}
				angular.extend(property.active_budget_period, {
					budget_forecast_difference: difference,
					pieOptions: {
						size: 50,
						animate:{
							duration:1000,
							enabled:true
						},
						barColor:barColour,
						trackColor: trackColour,
						scaleColor:false,
						lineWidth:10,
						lineCap:'circle'
					}
				});
			}
		});
	}
	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.addPropertyClick = () => {
		// delete $localStorage.account;
		$state.go('main.customer.property.add');
	};

}
