angular.module('app.main.customer')
	.controller('CustomerContactUsCtrl', CustomerContactUsCtrl);

CustomerContactUsCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
];

function CustomerContactUsCtrl(
	$scope,
	$state,
	$q,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	this.$onInit = () => {
		self.loading = true;
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = () => {

	};
}
