angular.module('app.main')
	.directive('dump', function () {
		return {
			restrict: 'E',
			require: [],
			scope: {
				ngModel: '='
			},
			replace: true,
			template: '<span ng-bind-html="ngModel | json"></span>'
		}
	});
