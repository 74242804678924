angular.module('app.main')
	.controller('DrawMenuCtrl', [
		'$rootScope',
		'$scope',
		'$state',
		function ($rootScope,
				  $scope,
				  $state) {

			let self = this;

			self.methods = {};

			self.$onInit = () => {
				loadData();
			};

			function loadData() {

			}

		}
	]);
