angular.module('app.main.customer.account-settings')
	.controller('AccountSettingSaveCtrl', AccountSettingSaveCtrl);

AccountSettingSaveCtrl.$inject = [
	'$rootScope',
	'$scope',
	'$state',
	'$q',
	'focus',
	'notify',
	'AuthSvc',
	'AccountSvc',
];

function AccountSettingSaveCtrl(
	$rootScope,
	$scope,
	$state,
	$q,
	focus,
	notify,
	AuthSvc,
	AccountSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
	});

	this.$onInit = () => {
		self.activeAccount = AuthSvc.getRawAccount();
		let params = {
			id: self.activeAccount.id,
		};
		AccountSvc.read(params).then((response) => {
			self.form = response.data.account;
			if (self.form.company_name) {
				self.showCompany = true;
			}
			self.loading = false;
		});
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.showCompanyClick = () => {
		if (self.showCompany) {
			self.showCompany = false;
			self.form.company_name = null;
			self.form.vat_no = null;
		}
		else {
			self.showCompany = true;
			focus('company_name');
		}
	};

	handlers.submitClick = (isValid) => {
		if (isValid) {
			return AccountSvc.save(self.form).then((response) => {
				notify({
					message: 'Successfully updated account',
				});
				self.errors = null;
				$rootScope.$broadcast('onAccountUpdated', response.data.account);
			}, (errors) => {
				self.errors = errors;
			});
		}
		else {
			self.submitted = true;
		}
	};
}
