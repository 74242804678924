angular.module('app.main.public.connection-acknowledgement', [])
	.config([
		'$stateProvider',
		function ($stateProvider) {

			let baseTemplateUrl = 'app/main/modules/public/modules/connection-acknowledgement/templates/';

			// New Connection Acknowledgement --------------------------------------------------------------

			$stateProvider
				.state('main.public.connection-acknowledgement', {
					url: '/external/connection-acknowledgement',
					views: {
						'content@main.public': {
							templateUrl: baseTemplateUrl + 'Index.html',
							controller: 'ConnectionAcknowledgementCtrl',
							controllerAs: 'ConnectionAcknowledgement'
						}
					},
					ncyBreadcrumb: {
						label: 'Connection Request Acknowledgement'
					},
					data: {
						'navBarTheme': 'dark'
					}
				});
		}
	]);
