angular.module('app.main.public.auth')
	.controller('IdleLogoutCtrl', [
		'$log',
		'$state',
		'$rootScope',
		'$scope',
		'$mdDialog',
		'AuthSvc',
		'Idle',
		'$interval',
		function ($log,
				  $state,
				  $rootScope,
				  $scope,
				  $mdDialog,
				  AuthSvc,
				  Idle,
				  $interval) {


			$scope.$on('onTimeoutExpired', () => {
				$mdDialog.hide();
			});

			function logout() {
				$mdDialog.hide();
				AuthSvc.logout()
					.then(function () {
						$state.go('main.public.index');
					});
			}

			function login() {
				AuthSvc.login();
				$mdDialog.hide();
			}

			let self = $scope.IdleLogoutCtrl = {
				methods: {
					logout: logout,
					login: login
				}
			}


		}
	]);