angular.module('app.main.customer.account-setup')
    .factory('AccountSetupSvc', function (RequestSvc) {

        return {
            setup: setup,
        };

        function setup(params) {
            return RequestSvc.post('api/customer/account/setup', params);
        }
    });
