angular.module('app.main.customer')
	.controller('PaymentMethodsCtrl', PaymentMethodsCtrl);

PaymentMethodsCtrl.$inject = [
	'$window',
	'$scope',
	'$state',
	'$q',
	'$filter',
	'$timeout',
	'AuthSvc',
	'StripeElements',
	'PaymentCardSvc',
	'CustomerAccountSvc',
	'UtilityContractSvc',
];

function PaymentMethodsCtrl(
	$window,
	$scope,
	$state,
	$q,
	$filter,
	$timeout,
	AuthSvc,
	StripeElements,
	PaymentCardSvc,
	CustomerAccountSvc,
	UtilityContractSvc
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		form: {
			address: {},
			card: {},
			directDebit: {
				paymentDay: 1,
				sortCode: {
					firstSet: null,
					secondSet: null,
					thirdSet: null
				},
				accountName: null,
				accountNumber: null,
			},
		},
		confirmDirectDebitName: null,
		confirmDirectDebitAccount: null,
		errors: [],
		addCard: false,
		setupIntent: {},
		setupLoading: false,
		//
		acceptTerms: false,
		paymentDays: [],
		authReqPaymentMethods: [],
		stripeInvoiceId: undefined,
		activeAccount: undefined,
		activeAddress: undefined,
		//
		hasDirectDebitPaymentMethod: false,
		hasStripePaymentMethod: false,
		changeDirectDebitMandateDay: false,
	});

	this.$onInit = () => {
		self.activeAccount = AuthSvc.getRawAccount();
		self.user = AuthSvc.getRawUser();

		if (self.activeAccount.dd_available) {
			setDirectDebitDays(28);
		}

		let promises = {
			account: CustomerAccountSvc.read({id: self.activeAccount.id}),
			contracts: UtilityContractSvc.index({limit: 200, page:1, filter: {customer_account: {id: self.activeAccount.id}}}), // replace.
		};

		$q.all(promises)
			.then((responses) => {
				self.activeAddress = responses.account.data.account.address;
				self.contracts = responses.contracts.data.contract_collection;
				getPaymentMethods();
			});
	};

	function getPaymentMethods() {
		self.loading = true;
		PaymentCardSvc.index({account: {id: self.activeAccount.id}}).then((response) => {
			self.paymentMethods = response.data.payment_method_collection;

			angular.forEach(self.paymentMethods, (method) => {
				if (method.stripe_payment_methods) {
					self.hasStripePaymentMethod = true;
				}
				if (method.letts_pay_tenant !== null) {
					self.hasDirectDebitPaymentMethod = true;
					self.activeDirectDebit = method.letts_pay_tenant;
				}
			});
		}).finally(() => {
			self.loading = false;
		});
	}

	function setDirectDebitDays(days = 28) {
		for (let i = 1; i <= days; i++) {
			let a = i % 10,
				b = i % 100,
				debitDay = null;
			if (a === 1 && b !== 11) {
				debitDay = i + "st";
			} else if (a === 2 && b !== 12) {
				debitDay = i + "nd";
			} else if (a === 3 && b !== 13) {
				debitDay = i + "rd";
			} else {
				debitDay = i + "th";
			}
			self.paymentDays.push({label: debitDay, value: i});
		}
	}

	function formatSortcode(str) { // @todo replace with returned value. to remove.
		let chunks = str.match(/.{1,3}/g);
		return chunks.join("-");
	}

	// Handlers --------------------------------------------------------------------------------------------------------

	handlers.addCardClick = () => {
		self.addCard = !self.addCard;
		self.form.is_default = true;
	};

	handlers.confirmationClick = (value) => {
		if (value === 0) {
			self.acceptTerms = false;
		}
	};

	// Stripe Payment Method

	handlers.setStripeDefaultClick = (paymentMethod) => {
		return PaymentCardSvc.setStripeDefaultCard(paymentMethod).then((response) => {
			getPaymentMethods();
		});
	};

	handlers.stripeAuthorisation = (paymentMethod) => {
		/**
		 * 	Forces attachment of Payment Method against Payment Intent, then retries stripe.js handler for Auth
		 * 	When/if Auth failed, PM is removed from PI, so this 'refreshes' process by attaching all over again
		 */
		self.setupLoading = true;
		self.errors = [];

		PaymentCardSvc.updateStripeInvoicePaymentMethod(paymentMethod).then((response) => {
			self.errors = [];
			StripeElements.handleCardPayment(response.data.payment_method.stripe_pi_client_secret).then(function(result) {
				if (result.error) {
					self.errors.push(result.error.message);
				}
				PaymentCardSvc.updateStripePaymentMethod(result).then((response) => {
					if (angular.isObject(response.data.payment_method.authenticated_at)) {
						$state.reload();
					}
				}, (response) => {
					self.errors.push(response.error.message);
				});
			}).finally(() => {
				self.setupLoading = false;
			});
		}, (error) => {
			self.errors.push(error.error.message);
		});
	};

	handlers.initStripePaymentSetup = () => {
		self.elements = StripeElements.elements();

		PaymentCardSvc.createStripeSetupIntent()
			.then((response) => {
				$timeout(() => {
					self.setupIntent = response.data.setup_intent;
					self.paymentInitComplete = true;
				});
			});

		let elementStyles = {
			base: {
				color: '#36495F',
				fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':focus': {
					color: '#424770',
				},
				'::placeholder': {
					color: '#333',
				},
				':focus::placeholder': {
					color: '#333',
				},
			},
			invalid: {
				color: '#FF4949',
				':focus': {
					color: '#FF4949',
				},
				'::placeholder': {
					color: '#FF4949',
				},
			},
		};

		self.cardNumberElement = self.elements.create('cardNumber', {style: elementStyles});
		self.cardExpElement = self.elements.create('cardExpiry', {style: elementStyles});
		self.cardCvcElement = self.elements.create('cardCvc', {style: elementStyles});

		self.cardNumberElement.mount('#card-number-element');
		self.cardExpElement.mount('#card-expiry-element');
		self.cardCvcElement.mount('#card-cvc-element');
	};

	handlers.removeStripeCardClick = (paymentMethod) => {
		return PaymentCardSvc.removeStripeCard(paymentMethod).then((response) => {
			getPaymentMethods();
		});
	};

	handlers.submitNewStripeCard = () => {
		self.setupLoading = true;
		self.errors = [];

		StripeElements.handleCardSetup(
			self.setupIntent.client_secret, self.cardNumberElement, {
				payment_method_data: {
					billing_details: {name: self.form.card.name_on_card}
				}
			}
		).then(function(result) {
			if (result.error) {
				$timeout(() => {
					self.errors = []; // clear previous errors
					self.errors.push(result.error.message);
				});
			} else {
				angular.extend(self.form.card, {
					setupIntent: result.setupIntent,
					is_default: self.form.is_default,
					account: self.activeAccount,
				});
				PaymentCardSvc.createSubscriptionPaymentMethod({account: self.activeAccount})
					.then((response) => {
						PaymentCardSvc.addStripeCard(self.form.card).then((response) => {
							self.addCard = false;
							self.form.card = {};
							self.cardNumberElement.clear();
							self.cardExpElement.clear();
							self.cardCvcElement.clear();
							getPaymentMethods();
						}, (errors) => {
							self.errors = errors;
						});
					}, (errors) => {
						self.errors = errors;
					});
			}
		}).finally(() => {
			self.setupLoading = false;
		});
	};

	// Direct Debit Payment Method

	handlers.submitDebitMandateDay = () => {
		let form = {
			account: {
				id: self.activeAccount.id,
			},
			dd_date: self.form.directDebit.paymentDay,
			apply: true,
		};
		PaymentCardSvc.changePaymentDate(form)
			.then(response => {
				// @todo actually do something with response
				// console.log(response);
			}, error => {
				$window.scrollTo(0, 0);
				$timeout(() => {
					self.errors = error;
				});
			});
	};

	handlers.directDebitMandateDayClick = () => {
		self.changeDirectDebitMandateDay = !self.changeDirectDebitMandateDay;
	};

	handlers.submitNewDirectDebit = () => {
		let form;
		self.errors = [];
		if (typeof self.activeAddress !== "object" || self.activeAddress === null) {
			$window.scrollTo(0, 0);
			$timeout(() => {
				self.errors.push('Account does not have a valid address. Please update your address details.');
			});
		} else {
			self.setupLoading = true;
			const sortCode = `${self.form.directDebit.sortCode.firstSet}${self.form.directDebit.sortCode.secondSet}${self.form.directDebit.sortCode.thirdSet}`;
			form = {
				account: {
					id: self.activeAccount.id,
				},
				account_name: self.form.directDebit.accountName,
				account_number: self.form.directDebit.accountNumber,
				sort_code: sortCode,
				// dd_date: self.form.directDebit.paymentDay,
			};
			PaymentCardSvc.submitDirectDebit(form)
				.then(response => {
					angular.extend(self.activeAccount, {
						setup_payment_methods_count: 1
					});
					AuthSvc.setAccount(self.activeAccount);
					$state.go($state.current, {}, {reload: true});
				}, error => {
					$window.scrollTo(0, 0);
					$timeout(() => {
						self.errors = error;
					});
				})
				.finally(() => {
					self.setupLoading = false;
				});
		}
	};
}
