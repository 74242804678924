angular.module('app.main')
	.controller('PanelCtrl', [
		'$scope',
		'$rootScope',
		'$timeout',
		'params',
		'mdPanelRef',
		function ($scope,
				  $rootScope,
				  $timeout,
				  params,
				  mdPanelRef) {


			this.name = 'Panel';
			var self = this;
			self.showContent = true;

			// $rootScope.$on('onPanelOpenComplete', function () {
			// 	self.showContent = true;
			// });
			//
			$rootScope.$on('onBrowserBackButtonClick', function() {
				close();
			});

			self.methods = {
				init: init,
				close: close
			};

			function init() {
				if (!angular.isString(params.templateUrl)) {
					throw 'You must specify a templateUrl';
				}
				else {
					self.content = params.templateUrl;
				}

				self.params = params;
				if (self.params.fixed) {
					self.params.layoutAlign = 'center none';
				}
				else {
					self.params.layoutAlign = 'center center';
				}
			}

			function close() {
				self.showContent = false;
				$timeout(function() {
					mdPanelRef.close();
				},400);
			}


		}
	]);
