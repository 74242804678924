angular.module('app.main.public')
	.controller('SingleContactCtrl', SingleContactCtrl);

SingleContactCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$timeout',
];

function SingleContactCtrl($scope, $state, $q, $timeout) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	this.$onInit = () => {
		$timeout(() => {
			const trustboxBottom = document.getElementById('trustbox-bottom');
			window.Trustpilot.loadFromElement(trustboxBottom);
		},500);
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function () {

	};
}