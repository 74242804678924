angular.module('app.main')
	.controller('ConfirmationDialogCtrl', [
		'$log',
		'$scope',
		'$mdDialog',
		'data',
		'submitCallback',
		function ($log,
				  $scope,
				  $mdDialog,
				  data,
				  submitCallback) {


			$scope.title = data.title;
			$scope.subTitle = data.subTitle;
			$scope.cancelText = (angular.isString(data.cancelText)) ? data.cancelText : 'Cancel';
			$scope.submitText = (angular.isString(data.submitText)) ? data.submitText : 'Submit';
			$scope.submitActionType = data.submitActionType;

			function cancel() {
				$mdDialog.cancel();
			}

			function submit() {
				if (angular.isFunction(submitCallback)) {
					return submitCallback().then(() => {
						$mdDialog.hide();
					});
				} else {
					$mdDialog.hide();
				}
			}

			let self = $scope.Dialog = {
				methods: {
					cancel: cancel,
					submit: submit
				}
			}
		}
	]);