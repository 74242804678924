angular.module('app')
	.factory('GoogleAdWordsSvc', function ($window) {
		// Conversion labels
		var google_conversion_label = {
			'switch': "ofA1CJCkknQQnKmrkwM"
		};
		// Basic settings for AdWords Conversion
		var googleTrackConversion = function (conversion_label) {
			$window.google_trackConversion({
				google_conversion_id: 845862044,
				google_conversion_language: "en",
				google_conversion_format: "3",
				google_conversion_color: "1FB6FF",
				google_conversion_label: google_conversion_label[conversion_label],
				google_conversion_value: 150,
				google_remarketing_only: false
			});
		};

		return {
			sendSwitchConversion: function () {
				// Trigger register-customer conversion
				googleTrackConversion('switch');
			}
		};
	});