angular.module('app.main.customer')
	.controller('TariffOverviewCtrl', TariffOverviewCtrl);

TariffOverviewCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$filter',
	'$mdBottomSheet',
	'$mdDialog',
	'params',
	'BudgetSvc',
];

function TariffOverviewCtrl(
	$scope,
	$state,
	$q,
	$filter,
	$mdBottomSheet,
	$mdDialog,
	params,
	BudgetSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
	});

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.init = () => {
		self.type = params.type;
		let postcode = params.postcode;
		let roomCount = params.room_count;
		self.broadbandProductType = params.broadbandProductType;
		self.utilityTypeValue = params.utilityType.value;
		self.utilityTypeLabel = params.utilityType.label;
		self.utilityTypeIconSrc = params.utilityType.icon_path;
		self.rates = params.rates;
		console.log(params);

		if (!angular.isObject(self.rates)) {
			BudgetSvc.rates({
				postcode: postcode,
				bed_size: roomCount,
			}).then((response) => {
				self.rates = response.data.rates;
				setupTariffs();
				self.loading = false;
			});
		}
		else {
			setupTariffs();
			self.loading = false;
		}
	};

	function setupTariffs() {
		self.electric = $filter('filter')(self.rates, {utility: 'electric'}, true)[0];
		self.gas = $filter('filter')(self.rates, {utility: 'gas'}, true)[0];
		let broadband = $filter('filter')(self.rates, {utility: 'broadband'}, true)[0];

		if (angular.isObject(self.broadbandProductType)) {
			self.broadbandTariff = $filter('filter')(broadband.rates, (item) => {
				return self.broadbandProductType.id === item.broadband_product_type.id;
			});

			if (self.broadbandTariff.length) {
				self.broadbandTariff = self.broadbandTariff[0];
			}
		}
	}

	handlers.closeClick = () => {
		if (self.type === 'sheet') {
			$mdBottomSheet.hide({rates: self.rates});
		}
		else {
			$mdDialog.hide({rates: self.rates});
		}
	};
}
