angular.module('app.main.shared.utility')
	.directive('utilityBillReadings', [
		'$global',
		'UtilityReadingTypeSvc',
		function ($global,
				  UtilityReadingTypeSvc) {
			return {
				restrict: 'E',
				require: [
					'^form'
				],
				scope: {
					readings: '=ngModel'
				},
				templateUrl: 'app/main/modules/shared/modules/utility/directives/utilityBillReadings/UtilityBillReadings.html',
				link: function link(scope, element, attrs, controllers) {
					// make readings into array
					scope.form = controllers[0];
					scope.readings = scope.readings || [];
					scope.config = $global.config;

					angular.extend(scope, {
						loading: true,
						//form: controllers[0],
						options: {},
						addReading: function () {
							scope.readings.push({})
						},
						removeReading: function (reading) {
							if (reading.id) {
								reading.delete = true;
							}
							else {
								var index = scope.readings.indexOf(reading);
								scope.readings.splice(index, 1);
							}
						}
					});

					UtilityReadingTypeSvc.options()
						.then(function (response) {
							scope.options.readingType = response.data.reading_type_options;
							scope.loading = false;
						});
				}
			};
		}
	]);
