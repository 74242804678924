angular.module('app.main')
	.factory('$umModal', [
		'$window',
		'$state',
		'$mdPanel',
		'$rootScope',
		function ($window,
				  $state,
				  $mdPanel,
				  $rootScope) {

			var dialog = null;

			var panelState = {};
			var animation = $mdPanel.newPanelAnimation();
			animation.withAnimation($mdPanel.animation.FADE);
			animation.duration(200);

			var defaultPanelConfig = {
				templateUrl: 'app/main/directives/modal/umModal.html',
				controller: 'umModalController',
				animation: animation,
				attachTo: angular.element(document.body),
				hasBackdrop: true,
				trapFocus: true,
				clickOutsideToClose: false,
				escapeToClose: true,
				focusOnOpen: true,
				locals: {panelState: panelState},
				onDomAdded: function () {

				},
				onDomRemoved: function (a) {
					$state.go('main.customer.home.index', {}, {notify: false});
				}

			};

			$rootScope.$on('loggingOut', function () {
				if (angular.isDefined(self.panelRef)) {
					self.panelRef.close();
				}
			});

			function show(panelConfig, _templateUrl) {
				if (!angular.isObject(panelConfig.locals.modalState)) {
					angular.extend(panelConfig.locals, {
						modalState: {}
					});
				}
				angular.merge(panelConfig.locals.modalState, {
					templateUrl: _templateUrl
				});

				angular.merge(defaultPanelConfig, panelConfig);
				$mdPanel.open(defaultPanelConfig)
					.then(function (response) {
						self.panelRef = response;
					});
			}

			return {
				show: show
			};

		}
	]);

angular.module('app.main')
	.controller('umModalController',
		[
			'$scope',
			'modalState',
			'mdPanelRef',
			'$timeout',
			function ($scope, modalState, mdPanelRef, $timeout) {
				$scope.templateUrl = modalState.templateUrl;
				$scope.panelSize = angular.isDefined(modalState.panelSize) ? 'um-panel-' + modalState.panelSize : 'um-panel-md';
				$scope.modalState = modalState;

				$timeout(function () {
					$scope.showModalContent = true;
				}, 100);

				$scope.close = function () {
					$scope.showModalContent = false;
					$timeout(function () {
						mdPanelRef.close();
					}, 100);
				}
			}
		]);