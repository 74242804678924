angular.module('app.main.public.auth')
	.controller('LoginCtrl', LoginCtrl);

LoginCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'focus',
	'$timeout',
	'notify',
	'AuthSvc',
];

function LoginCtrl($scope, $state, $q, focus, $timeout, notify, AuthSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
		form: {},
		emailInvalid: false,
	});

	this.$onInit = () => {
		if (AuthSvc.isAuthenticated()) {
			AuthSvc.redirect();
		}
		self.loading = true;
		$timeout(() => {
			focus('email');
		}, 1000);
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.sendMagicLinkClick = () => {
		if (angular.isUndefined(self.form.email) || !self.form.email.length) {
			self.emailInvalid = true;
		}
		else {
			AuthSvc.sendMagicLink({email: self.form.email})
				.then((response) => {
					notify({
						message: 'Magic link sent',
					});
				});
		}
	};

	handlers.submitClick = (isValid) => {
		if (isValid) {
			return AuthSvc.loginWithCredentials(self.form)
				.then(function (response) {
					/**
					 *	Response.data.reset is passed if the user has an `invite_code` and `activate` = 0
					 * 	This will force a password change on login.
					 */
					if (response.data.reset) {
						$state.go('main.public.auth.reset-password', {key: response.data.reset});
					} else {
						AuthSvc.redirect();
					}
				}, function (errors) {
					self.formErrors = errors;
				});
		}
		else {
			self.form.submitted = true;
		}
	};
}