angular.module('app.main.public.auth')
	.factory('AuthSvc', [
		'$window',
		'$state',
		'$rootScope',
		'$stateParams',
		'$q',
		'RequestSvc',
		'$timeout',
		'Idle',
		'$intercom',
		'$localStorage',
		function ($window,
				  $state,
				  $rootScope,
				  $stateParams,
				  $q,
				  RequestSvc,
				  $timeout,
				  Idle,
				  $intercom,
				  $localStorage) {

			let user = null;
			let activeAccount = null;
			let accounts = null;

			function loginWithCredentials(credentials) {

				angular.extend(credentials, {
					client_type: 'client',
				});

				let promise = RequestSvc.post('api/authenticate', credentials);
				promise.then(function (response) {
					user = response.data.user;
					if (response.data.account.length) {
						accounts = response.data.account;
						setActiveAccount(accounts[0]);
					}
					$localStorage.authToken = {
						token: response.data.user.token,
						expires: response.data.expires_at,
					};
					if (!angular.isDefined(response.data.reset)) {
						// login();
					}
					// user = response.data.user;
					// $localStorage.checkLogin = true;
					// $intercom.update({
					// 	email: user.email,
					// });
				}, function(error) {
					logout();
					$state.go('main.public.auth.login');
				});

				return promise;
			}

			function login() {
				let deferred = $q.defer();
				loginWithCredentials({}).then((response) => {
					user = response.data.user;
					if ($localStorage.userId !== user.id) {
						$localStorage.userId = user.id;
						$localStorage.defaultAccount = undefined;
					}
					$localStorage.checkLogin = true;
					$rootScope.$broadcast('onLogin', {user: user});
					accounts = response.data.account;
					setActiveAccount(accounts[0]);
					deferred.resolve(user);
				});
				// let deferred = $q.defer();
				//
				// let promise = RequestSvc.get('api/userFromToken');
				// promise.then(function (response) {
				// 	user = response.data.user;
				// 	if ($localStorage.userId !== user.id) {
				// 		$localStorage.userId = user.id;
				// 		$localStorage.defaultAccount = undefined;
				// 	}
				// 	$localStorage.checkLogin = true;
				// 	$rootScope.$broadcast('onLogin', {user: user});
				// 	accounts = response.data.accounts;
				// 	setActiveAccount();
				//
				// 	deferred.resolve(user);
				// }, function (error) {
				// 	$localStorage.checkLogin = false;
				// });

				return deferred.promise;
			}

			function setActiveAccount() {
				if ($localStorage.defaultAccount) {
					// make sure our localstorage account is still in our account list
					let tmpActiveAccount = $localStorage.defaultAccount;
					let foundExisting = false;
					angular.forEach(accounts, (_account) => {
						if (_account.id === tmpActiveAccount.id) {
							// exists in our collection, overwrite it in local storage for good measure (helps if we add attributes later on api side)
							$localStorage.defaultAccount = _account; // tmpActiveAccount
							activeAccount = _account; // tmpActiveAccount
							foundExisting = true;
						}
					});
					if (!foundExisting) { // didn't find it, maybe deleted, default to first one again
						activeAccount = accounts[0];
						$localStorage.defaultAccount = activeAccount;
					}
				}
				else {
					activeAccount = accounts[0];
					$localStorage.defaultAccount = activeAccount;
				}
			}

			function loginWithToken(token) {
				let promise = RequestSvc.post('api/loginWithToken', {token: token});
				promise.then(function (response) {
					user = response.data.user;
					$localStorage.checkLogin = true;
					$rootScope.$broadcast('onLogin', {user: user});
				}, function (error) {
					$localStorage.checkLogin = false;
				});
				return promise;
			}

			function markQuickStartGuideAsRead() {
				return RequestSvc.post('api/mark-start-guide-read');
			}

			function sendMagicLink(param) {
				return RequestSvc.post('api/request-magic-link', param);
			}

			function register(params) {
				return RequestSvc.post('api/register', params);
			}

			function redirect() {
				let redirect = $stateParams.redirect;
				if ($state.is('empty')) {
					$state.go('root');
				}
				else if (angular.isString(redirect) && redirect.length) {
					$window.location.href = redirect;
				}
				else {
					$state.go('main.customer.dashboard.index');
					// $state.go('main.customer.property.index');
					return;
				}
			}

			function logout() {
				return RequestSvc.post('api/logout')
					.then(function (response) {
						user = null;
						$rootScope.$broadcast('AuthLogout');
					}).finally(() => {
						delete $localStorage.authToken;
					});
			}

			function forgotPassword(form) {
				let promise = RequestSvc.post('api/forgot-password', form);
				return promise;
			}

			function validatePasswordToken(form) {
				let promise = RequestSvc.post('api/validate-password-token', form);
				return promise;
			}

			function validateActivateToken(form) {
				let promise = RequestSvc.post('api/validate-activate-token', form);
				return promise;
			}

			function activateAccount(form) {
				let promise = RequestSvc.post('api/activate-account', form);
				return promise;
			}

			function resetPassword(form) {
				let promise = RequestSvc.post('api/reset-password', form);
				return promise;
			}

			function mentionUsers() {
				return RequestSvc.get('api/mention-users');
			}

			function setUser(_user) {
				user = _user;
			}

			function getUser() {
				let deferred = $q.defer();

				if (user) {
					deferred.resolve(user);
				}
				else {
					login()
						.finally(function () {
							deferred.resolve(user);
						});
				}

				return deferred.promise;
			}

			function getRawUser() {
				return user;
			}

			function getRawAccount() {
				return activeAccount;
			}

			function getRawAccounts() {
				return accounts;
			}

			function setAccount(_account) {
				activeAccount = _account;
			}

			function pushAccount(_account) {
				accounts.push(_account);
			}

			function isAuthenticated() {
				return (angular.isObject(user));
			}

			function intercomUpdateUser(user) {
				// $intercom.update(user);
			}

			function startImpersonating(_user) {
				// let deferred = $q.defer();
				// let promise = RequestSvc.get('api/admin/user/start-impersonating/' + _user.id);
				//
				// promise.then(function (response) {
				// 	user = response.data.user;
				// 	$timeout(function () {
				// 		angular.extend($rootScope, {
				// 			auth: {
				// 				user: user
				// 			}
				// 		});
				// 		deferred.resolve(user);
				// 		$rootScope.$broadcast('onLogin', {user: user});
				// 	}, 100);
				// });
				//
				// return deferred.promise;
			}

			function stopImpersonating() {
				// return RequestSvc.get('api/user/stop-impersonating').then(function(response) {
				// 	login().then(function(){
				// 		angular.extend(user, {
				// 			impersonating: false
				// 		});
				// 		if ($localStorage.impersonateRedirect) {
				// 			$window.location.href = $localStorage.impersonateRedirect;
				// 		}
				// 		else {
				// 			$state.go('main.admin.user.index');
				// 		}
				// 	});
				// });
			}

			function can(permissions, all) {
				if (!angular.isObject(user)) return;
				if (is('Admin')) {
					return true;
				}

				let requiredPermissions = angular.isArray(permissions) ? permissions : [permissions];
				let matchAll = Boolean(all);
				let requiredCount = requiredPermissions.length;
				let matchCount = 0;

				let userPermissions = user.permission_list;

				angular.forEach(requiredPermissions, function (value) {
					if (userPermissions.indexOf(value) >= 0) {
						matchCount++;
					}

					if (angular.isObject(user.impersonated_by)) {
						let impersonatedByPermissionList = user.impersonated_by.permission_list;
						if (impersonatedByPermissionList.indexOf(value) >= 0) {
							matchCount++;
						}
					}
				});


				return (matchAll === true && requiredCount === matchCount) || (matchAll === false && matchCount > 0);
			}

			function is(roles, all) {
				if (!angular.isObject(user)) return;
				if (user.role_list === 'Admin') return true;

				let requiredRoles = angular.isArray(roles) ? roles : [roles];
				let matchAll = Boolean(all);
				let requiredCount = requiredRoles.length;
				let matchCount = 0;
				let userRoles = user.role_list;

				angular.forEach(requiredRoles, function (value) {
					if (userRoles.indexOf(value) >= 0) {
						matchCount++;
					}
				});

				if ((matchAll === true && requiredCount === matchCount) || (matchAll === false && matchCount > 0)) {
					return true;
				}
				else {
					return false;
				}
			}

			function validateEmail(email) {
				return RequestSvc.post('api/validate-email', {email: email});
			}

			function loginWithGoogle() {
				RequestSvc.get('api/login-with-google');
			}

			return {
				login: login,
				// register: register,
				logout: logout,
				redirect: redirect,
				getUser: getUser,
				setUser: setUser,
				isAuthenticated: isAuthenticated,
				activateAccount: activateAccount,
				validatePasswordToken: validatePasswordToken,
				validateActivateToken: validateActivateToken,
				forgotPassword: forgotPassword,
				resetPassword: resetPassword,
				startImpersonating: startImpersonating,
				stopImpersonating: stopImpersonating,
				can: can,
				is: is,
				getRawUser: getRawUser,
				getRawAccount: getRawAccount,
				getRawAccounts: getRawAccounts,
				setAccount: setAccount,
				intercomUpdateUser: intercomUpdateUser,
				mentionUsers: mentionUsers,
				validateEmail: validateEmail,
				sendMagicLink: sendMagicLink,
				loginWithCredentials: loginWithCredentials,
				loginWithToken: loginWithToken,
				loginWithGoogle: loginWithGoogle,
				register: register,
				pushAccount: pushAccount,
				markQuickStartGuideAsRead: markQuickStartGuideAsRead,
			};

		}
	]);
