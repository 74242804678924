angular.module('app.main.public.magic-link', [])
    .config([
        '$stateProvider',
        function ($stateProvider) {

            let baseTemplateUrl = 'app/main/modules/public/modules/magic-link/templates/';

            $stateProvider
                .state('main.public.magic-link', {
                    url: '^/magic-link',
                    abstract: true,
                    data: {
                        checkLogin: false,
                    },
                })

                .state('main.public.magic-link.index', {
                    url: '^/magic-link/{code}',
                    params: {
                        code: null
                    },
                    views: {
                        'content@main.public': {
                            templateUrl: baseTemplateUrl + 'MagicLinkMain.html',
                            controller: 'MagicLinkCtrl',
                            controllerAs: 'MagicLink'
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Magic Link'
                    },
                });
        }
]);
