angular.module('app.main.customer', [
	'app.main.customer.property',
	'app.main.customer.myAccount',
	'app.main.customer.account-settings',
	// v2 / potential rework into 'new' structure
	// 'app.main.customer.account-setup',
	'app.main.customer.property.services',
])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.customer.dashboard', {
				abstract: true,
				data: {
					checkLogin: true,
				}
			})
			.state('main.customer.dashboard.index', {
				url: '^/dashboard',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_dashboard/Dashboard.html',
						controller: 'DashboardCtrl',
						controllerAs: 'Dashboard',
					}
				},
				ncyBreadcrumb: {
					label: 'Dashboard'
				},
			})

			.state('main.customer.dashboard.quick-start-guide', {
				url: '^/quick-start-guide',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_dashboard/_quickStartGuide/QuickStart.html',
						controller: 'QuickStartCtrl',
						controllerAs: 'Start',
					}
				},
				ncyBreadcrumb: {
					label: 'Quick Start Guide'
				},
				data: {
					hidePaymentRequiredBanner: true,
				}

			})


		;

	}]);
