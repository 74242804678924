angular.module('app.main.customer')
	.factory('ReferralSvc', function (RequestSvc) {

		function validate(params) {
			return RequestSvc.post('api/referral/validate-code', params);
		}

		return {
			validate: validate,
		};

	});
