angular.module('app.main.shared')
	.factory('StatementPaymentsSvc', function (RequestSvc) {

		function statementIndex(params) {
			return RequestSvc.get('api/admin/statement-payments/statement/index', params);
		}

		function statementsUnpaid(params) {
			return RequestSvc.get('api/admin/statement-payments/statement/unpaid', params);
		}

		function paymentTypeOptions(params) {
			return RequestSvc.get('api/admin/statement-payments/payment-type-options', params);
		}

		function savePaymentAllocation(params) {
			return RequestSvc.post('api/admin/statement-payments/allocation/save', params);
		}

		return {
			statement: {
				index: statementIndex,
				unpaid: statementsUnpaid,
			},
			savePaymentAllocation: savePaymentAllocation,
			paymentTypeOptions: paymentTypeOptions,
		};

	});
