angular.module('app.main.public')
	.controller('UtilitiesWithBenefitsCtrl', UtilitiesWithBenefitsCtrl);

UtilitiesWithBenefitsCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
];

function UtilitiesWithBenefitsCtrl($scope, $state, $q) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	this.$onInit = () => {
		self.breakpoints = [
			{
				breakpoint: 1282,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 948,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},

		];

		self.loading = true;
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function () {

	};
}