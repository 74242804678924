var countTo = angular.module('countTo', [])
	.directive('countTo', ['$timeout', function ($timeout) {
		return {
			replace: false,
			scope: true,
			link: function (scope, element, attrs) {

				var e = element[0];
				var num, refreshInterval, duration, steps, step, countTo, value, increment;

				var calculate = function () {
					refreshInterval = 30;
					step = 0;
					scope.timoutId = null;
					countTo = parseFloat(Math.round(attrs.countTo * 100)/100) || 0;
					scope.value = parseFloat(Math.round(attrs.value * 100) /100) || 0;
					duration = (parseFloat(attrs.duration) * 1000) || 0;
					steps = Math.ceil(duration / refreshInterval);
					increment = ((countTo - scope.value) / steps);
					num = scope.value;
				}

				var tick = function () {
					scope.timoutId = $timeout(function () {
						num += increment;
						step++;
						if (step >= steps) {
							$timeout.cancel(scope.timoutId);
							num = countTo;
							e.innerHTML = parseFloat(Math.round(countTo * 100) / 100).toFixed(2) ;
						} else {
							e.innerHTML = parseFloat(Math.round(num * 100) / 100).toFixed(2);
							tick();
						}
					}, refreshInterval);

				}

				var start = function () {
					if (scope.timoutId) {
						$timeout.cancel(scope.timoutId);
					}
					calculate();
					tick();
				}

				attrs.$observe('countTo', function (val) {
					if (val) {
						start();
					}
				});

				attrs.$observe('value', function (val) {
					start();
				});

				return true;
			}
		}

	}]);