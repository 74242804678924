angular.module('app.public.customer.property', [

])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.public.customer.property', {
				abstract: true,
			})

			.state('main.public.customer.property.address-lookup', {
				url: '/property/address',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/addressLookup/AddressLookup.html',
						controller: 'AddressLookupCtrl',
						controllerAs: 'AddressLookup',
					}
				},
				ncyBreadcrumb: {
					label: 'Select address'
				},
			})

			.state('main.public.customer.property.property-size', {
				url: '/property/property-size',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/propertySize/PropertySize.html',
						controller: 'PropertySizeCtrl',
						controllerAs: 'PropertySize',
					}
				},
				ncyBreadcrumb: {
					label: 'Choose property size'
				},
			})

			.state('main.public.customer.property.package-selection', {
				url: '/property/package-selection',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/package/PackageSelection.html',
						controller: 'PackageSelectionCtrl',
						controllerAs: 'PackageSelection',
					}
				},
				ncyBreadcrumb: {
					label: 'Choose your package'
				},
			})

			.state('main.public.customer.property.join', {
				url: '/property/join',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/join/Join.html',
						controller: 'JoinCtrl',
						controllerAs: 'Join',
					}
				},
				ncyBreadcrumb: {
					label: 'Join'
				},
			})

			.state('main.public.customer.property.personal-details', {
				url: '/property/details',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/personalDetails/PersonalDetails.html',
						controller: 'PersonalDetailsCtrl',
						controllerAs: 'Details',
					}
				},
				ncyBreadcrumb: {
					label: 'Your details'
				},
			})

			.state('main.public.customer.property.payment-info', {
				url: '/property/payment',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/paymentInfo/PaymentInfo.html',
						controller: 'PaymentInfoCtrl',
						controllerAs: 'Payment',
					}
				},
				ncyBreadcrumb: {
					label: 'Payment'
				},
			})

			.state('main.public.customer.property.confirmation', {
				url: '/property/confirmation',
				views: {
					'content@main.public.customer': {
						templateUrl: 'app/main/modules/shared/modules/property/_add/confirmation/Confirmation.html',
						controller: 'ConfirmationCtrl',
						controllerAs: 'Confirm',
					}
				},
				ncyBreadcrumb: {
					label: 'Confirmation'
				},
			})


		;

	}]);
