angular.module('app.main')
	.directive('requireObject', [
		'UtilityReadingTypeSvc',
		function (UtilityReadingTypeSvc) {
			return {
				restrict: 'A',
				require: 'ngModel',
				link: function link(scope, element, attrs, controller) {
					scope.$watch(function () {
						return controller.$modelValue;
					}, function (newValue) {
						controller.$setValidity('objectRequired', angular.isObject(newValue));
					});
				}
			};
		}
	]);
