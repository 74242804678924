angular.module('app.main.customer')
	.controller('MyAccountMainCtrl', MyAccountMainCtrl);

MyAccountMainCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
];

function MyAccountMainCtrl($scope, $state, $q) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	$scope.$on('accountChanged', (event, group) => {
		$state.go('main.customer.dashboard.index');
	});

	this.$onInit = () => {
		self.loading = true;
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function () {

	};
}
