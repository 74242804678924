angular.module('app.main.customer.account-setup', [])
    .component('accountSetup', {
        templateUrl: 'app/main/modules/customer/components/account-setup/templates/AccountSetup.html',
        controller: 'AccountSetupCtrl',
        controllerAs: 'AccountSetup',
        bindings: {
            user: '<',
        },
    })
;
