angular.module('app.main.public.magic-link')
    .controller('MagicLinkCtrl', MagicLinkCtrl);

MagicLinkCtrl.$inject = [
    '$location',
    '$log',
    '$scope',
    '$state',
    '$window',
    '$document',
    '$mdSidenav',
    '$q',
    '$timeout',
    '$localStorage',
    'AuthSvc',
    'MagicLinkSvc',
];

function MagicLinkCtrl(
    $location,
    $log,
    $scope,
    $state,
    $window,
    $document,
    $mdSidenav,
    $q,
    $timeout,
    $localStorage,
    AuthSvc,
    MagicLinkSvc,
) {
    let self = this;
    let handlers = {};

    if (!angular.isDefined($state.params.code) || $state.params.code === null) {
        $state.go('main.public.index');
    }

    angular.extend(self, {
        loading: true,
        handlers: handlers,
        code: $state.params.code,
        expired: false,
    });

    this.$onInit = () => {
        let tokenRequest = MagicLinkSvc.loginMagicLink({code: self.code})
            .then((response) => {

                $localStorage.authToken = {
                    token: response.data.token,
                    expires: response.data.expires_at,
                };
                $timeout(() => { // brief fake loading screen
                    AuthSvc.loginWithToken()
                        .then(() => {
                            $state.go('main.customer.my-account.profile');
                        }, () => {
                            $state.go('main.public.auth.login');
                        });
                }, 1200);
            }, () => {
                self.expired = true;
            });

        let promises = [
            tokenRequest,
        ];

        $q.all(promises)
            .then(() => {
                self.loading = false;
            }, () => {
                console.log('something went wrong');
            }).finally(() => {
            //
        });
    };

    // Handlers --------------------------------------------------------------------------------------------------------

}
