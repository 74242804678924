angular.module('app.main.customer')
	.factory('PaymentCardSvc', function (RequestSvc) {

		function index(params) {
			return RequestSvc.get('api/payment-methods/index', params);
		}

		function submitDirectDebit(params) {
			return RequestSvc.post('api/customer/account/letts-pay/add-details', params);
		}

		function changePaymentDate(params) {
			return RequestSvc.post('api/customer/account/letts-pay/change-payment-date', params);
		}

		function createSubscriptionPaymentMethod(params) {
			return RequestSvc.post('api/customer/account/stripe/create-subscription-payment-method', params);
		}

		function addStripeCard(params) {
			return RequestSvc.post('api/customer/account/stripe/add-card', params);
		}

		function removeStripeCard(params) {
			return RequestSvc.post('api/customer/account/stripe/remove-card', params);
		}

		function createStripeSetupIntent(params) {
			return RequestSvc.post('api/customer/account/stripe/create-setup-intent', params);
		}

		// function save(params) {
		// 	return RequestSvc.post('api/payment-methods/save', params);
		// }

		// function remove(params) {
		// 	return RequestSvc.post('api/payment-methods/remove', params);
		// }

		// function saveCard(params) {
		// 	return RequestSvc.post('api/payment-methods/save-card', params);
		// }

		// function createPaymentMethod(params) {
		// 	return RequestSvc.post('api/payment-methods/create', params);
		// }

		function updateStripePaymentMethod(params) {
			return RequestSvc.post('api/customer/account/stripe/update', params)
		}

		function setStripeDefaultCard(params) {
			return RequestSvc.post('api/customer/account/stripe/set-default-card', params);
		}

		function updateStripeInvoicePaymentMethod(params) {
			return RequestSvc.post('api/customer/account/stripe/update-invoice-pm', params)
		}

		return {
			index: index,
			submitDirectDebit: submitDirectDebit,
			changePaymentDate: changePaymentDate,
			createSubscriptionPaymentMethod: createSubscriptionPaymentMethod,
			addStripeCard: addStripeCard,
			removeStripeCard: removeStripeCard,
			createStripeSetupIntent: createStripeSetupIntent,
			// save: save,
			// remove: remove,
			// saveCard: saveCard,
			setStripeDefaultCard: setStripeDefaultCard,
			// createPaymentMethod: createPaymentMethod,
			updateStripePaymentMethod: updateStripePaymentMethod,
			updateStripeInvoicePaymentMethod: updateStripeInvoicePaymentMethod,
		};
	});
