angular.module('app.main.customer')
    .controller('PropertyOverviewCtrl', PropertyOverviewCtrl);

PropertyOverviewCtrl.$inject = [
    '$scope',
    '$state',
    '$q',
    '$window',
    '$timeout',
    '$filter',
    '$localStorage',
    'moment',
    'ConfirmationDialog',
    'PropertySvc',
    'EndpointSvc',
    'UtilityContractSvc',
    'AuthSvc',
    'UtilityTypeSvc',
    'BudgetSvc',
    'BroadbandProductTypeSvc',
    'AccountServiceRemindersSvc',
];

function PropertyOverviewCtrl(
    $scope,
    $state,
    $q,
    $window,
    $timeout,
    $filter,
    $localStorage,
    moment,
    ConfirmationDialog,
    PropertySvc,
    EndpointSvc,
    UtilityContractSvc,
    AuthSvc,
    UtilityTypeSvc,
    BudgetSvc,
    BroadbandProductTypeSvc,
    AccountServiceRemindersSvc,
) {

    if (!$state.params.propertyId) {
		$state.go('404');
        return;
    }

    let self = this;
    let handlers = {};
    let methods = {};

    angular.extend(self, {
        propertyId: $state.params.propertyId,
        loading: true,
        handlers: handlers,
        methods: methods,
        //
        resubmitted: false,
        resubmissionStatus: false,
        tmpFields: {
            services: []
        },
        options: {
            types: {},
            utilityTypes: [],
        },
        contracts: [],
        selectedServices: [],
        tabSelectedTemplate: "app/main/modules/customer/_property/_overview/includes/services.html",
        now: moment().utc(),
    });

    this.$onInit = () => {
        self.activeAccount = AuthSvc.getRawAccount();
        let promises = {
            property: PropertySvc.read(self.propertyId),
            reminders: AccountServiceRemindersSvc.index({filter: {property: self.propertyId}}),
            endpoints: self.methods.endpointIndex(),
        };

        $q.all(promises)
            .then((responses) => {
                $scope.property = responses.property.data.property.address.line_1; // for browser breadcrumb
                self.property = responses.property.data.property;
                self.reminders = responses.reminders.data.service_reminder_collection;
            }).finally(() => {
            self.loading = false;
        });
    };

    methods.endpointIndex = () => {
        EndpointSvc.index({
            filter: {
                property: {
                    id: self.propertyId,
                }
            }
        }).then((response) => {
            self.endpoints = response.data.endpoint_collection;

            angular.forEach(self.endpoints, (endpoint) => {
                angular.forEach(endpoint.contracts, function (contract) {
                    let allowResubmit = false;
                    if (angular.isString(contract.sent_to_supplier)) {
                        if (moment().add(10, 'days').diff(moment(contract.sent_to_supplier), 'days') > 10) {
                            allowResubmit = true;
                        }
                    }
                    contract.allowResubmit = allowResubmit;
                });
            });
        });
    };

    // Handlers --------------------------------------------------------------------------------------------------------

    handlers.tabClicked = (params) => {
        self.tabSelectedTemplate = "app/main/modules/customer/_property/_overview/includes/" + params + ".html";
    };

    handlers.goBackClick = () => {
        $state.go('main.customer.dashboard.index');
    };

    handlers.reminderClick = (reminder) => {
        console.log('reminder clicked');
        // $state.go('main.customer.property.services.index', {billingGroupId: self.billingGroupId, propertyId: self.propertyId, utilityTypeIds: [reminder.utility_type.id]});
    };

    handlers.addServiceClick = (service) => {
        $state.go('main.customer.property.services.index', {
            billingGroupId: self.billingGroupId,
            propertyId: self.propertyId,
            utilityTypeId: service.utilityType.id
        });
    };

    handlers.reminderProgress = (reminder) => {
        let percentage = reminder.percentage;
        if (!percentage) {
            let start = moment().startOf('year').utc().valueOf(),
                end = moment(reminder.remind_at).utc().valueOf(),
                today = moment(new Date()).utc().valueOf();
            let total = end - start;
            let current = today - start;
            percentage = (current / total) * 100;

            reminder.percentage = percentage;
        }
        return percentage;
    };

    handlers.resubmitConnectionRequest = (contractId) => {
        ConfirmationDialog.show(
            'Retry Confirmation',
            "Please ensure any objections have been lifted with your existing supplier. " +
            "If the objections have been lifted please retry to begin your switch.",
            'Cancel',
            {
                label: 'Retry My Switch',
                actionType: 'mango',
            })
            .then(function () {
                self.resubmitted = true;
                self.loading = true;
                UtilityContractSvc.resubmitConnectionRequest({id: contractId})
                    .then((response) => {
                        $timeout(() => {
                            self.methods.endpointIndex();
                            self.resubmitted = true;
                        }, 1000);
                    }, (response) => {
                        self.resubmitted = false;
                    }).finally(() => {
                    self.loading = false;
                });
            });
    };

    handlers.propertyLabelSubmit = (label) => {
        let property = angular.copy(self.property);
        angular.extend(property, {
            id: self.propertyId,
            label: label
        });
        PropertySvc.save(property)
            .then((response) => {
                self.property.label = response.data.property.label;
            })
    };
}
