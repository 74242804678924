angular.module('app.main.customer')
	.factory('BudgetSvc', function (RequestSvc) {

		function rates(params) {
			return RequestSvc.get('api/tariff/rates', params);
		}

		return {
			rates: rates
		};

	});
