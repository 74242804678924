angular.module('app.main.shared')
	.factory('UtilityBillChargeTypeTypeSvc', [
		'RequestSvc',
		function (RequestSvc) {

			return {
				options: function (params) {
					return RequestSvc.get('api/utility/bill-charge-type/options', params);
				}
			};

		}
	]);
