angular.module('app.main')
	.factory('RequestInterceptor', [
		'$q',
		'$rootScope',
		'ResponseSvc',
		function ($q,
				  $rootScope,
				  ResponseSvc) {

			function request(config) {
				var deferred = $q.defer();
				deferred.resolve(config);
				return deferred.promise;
			}

			function response(response) {
				var deferred = $q.defer();
				deferred.resolve(response);
				return deferred.promise;
			}

			function requestError(config) {
				var deferred = $q.defer();
				deferred.resolve(config);
				return deferred.promise;
			}

			function responseError(response) {
				if (response.status === 401) {
					console.log('UNAUTHORIZED');
					$rootScope.$emit('logout');
				}
				else {
					ResponseSvc.setLastErrorResponse(response);
				}
				return response;
			}

			return {
				request: request,
				response: response,
				requestError: requestError,
				responseError: responseError
			};

		}
	]);
