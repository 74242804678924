angular.module('app.main.public.auth')
	.controller('ActivateAccountCtrl', [
		'$log',
		'$state',
		'$scope',
		'AuthSvc',
		function ($log,
				  $state,
				  $scope,
				  AuthSvc) {

			function init() {
				// Validate that the key is valid
				if (angular.isString($state.params.key)) {
					AuthSvc.validateActivateToken({
						key: $state.params.key
					}).then(function(response) {
						self.validated = true;
						self.form = response.data.user;
					}, function(errors) {
						self.errors = errors;
					});
				}

			}

			function submit(isValid) {
				if (isValid) {
					angular.extend(self.form, {
						key: $state.params.key
					});
					return AuthSvc.activateAccount(self.form)
						.then(function (response) {
							AuthSvc.login({
								email: self.form.contact_email,
								password: self.form.password,
							}).then(() => {
								AuthSvc.redirect();
							})
						}, function (errors) {
							self.errors = errors;
						});
				}
				else {
					self.form.submitted = true;
				}
			}

			var self = $scope.ActivateAccountCtrl = {
				validated: false,
				form: {},
				methods: {
					init: init,
					submit: submit
				}
			};

		}
	]);