angular.module('app.main.customer')
	.factory('PropertyTypeSvc', function (RequestSvc, $mdDialog) {

		function options(params) {
			return RequestSvc.get('api/customer/account/property-type/options', params);
		}

		return {
			options
		};

	});
