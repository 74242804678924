angular.module('app.main.customer')
	.controller('AccountOverviewCtrl', AccountOverviewCtrl);

AccountOverviewCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'AuthSvc',
	'CustomerAccountSvc',
];

function AccountOverviewCtrl($scope, $state, $q, AuthSvc, CustomerAccountSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		account: AuthSvc.getRawAccount(),
	});

	this.$onInit = () => {
		if (!angular.isObject(self.account)) {
			self.loading = false;
			return;
		}

		CustomerAccountSvc.read({id: self.account.id}).then((response) => {
			self.account = response.data.account;
		}).finally(() => {
			self.loading = false;
		});
	};

	// Handlers --------------------------------------------------------------------------------------------------------

	handlers.submitClick = function () {

	};
}
