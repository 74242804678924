angular.module('app.main.customer')
	.factory('PropertySvc', function (RequestSvc, $mdMedia, $mdDialog, $mdBottomSheet) {

		function index(params) {
			return RequestSvc.get('api/customer/property/index', params);
		}

		function options(params) {
			return RequestSvc.get('api/customer/property/options', params);
		}

		function propertyUtilityTypeOptions(params) {
			return RequestSvc.get('api/customer/property/property-utility-type/options', params);
		}

		function read(id) {
			return RequestSvc.get('api/customer/property/read/' + id);
		}

		function save(params) {
			return RequestSvc.post('api/customer/property/save', params);
		}

		function addressLookup(params) {
			return RequestSvc.get('api/customer/property/lookup', params);
		}

		function saveMeterReadings(params) {
			return RequestSvc.post('api/customer/property/save-meter-readings', params);
		}

		function saveServices(params) {
			return RequestSvc.post('api/customer/property/services/save', params);
		}

		function deleteQuoteItem(params) {
			return RequestSvc.post('api/customer/property/services/delete-quote', params);
		}

		function openTariffModal(postcode, room_count, utilityType, broadbandProductType, rates) {
			if (!$mdMedia('xs')) {
				return $mdDialog.show({
					templateUrl: 'app/main/modules/customer/shared/_tariff/TariffModal.html',
					controller: 'TariffOverviewCtrl',
					controllerAs: 'Tariff',
					locals: {
						params: {
							postcode: postcode,
							room_count: room_count,
							utilityType: utilityType,
							broadbandProductType: broadbandProductType,
							rates: rates,
							type: 'modal',
						}
					},
					preserveScope: true,
					clickOutsideToClose: true,
					escapeToClose: true
				});
			}
			else {
				return $mdBottomSheet.show({
					templateUrl: 'app/main/modules/customer/shared/_tariff/TariffSheet.html',
					controller: 'TariffOverviewCtrl',
					controllerAs: 'Tariff',
					locals: {
						params: {
							postcode: postcode,
							room_count: room_count,
							utilityType: utilityType,
							broadbandProductType: broadbandProductType,
							rates: rates,
							type: 'sheet',
						}
					},
					preserveScope: true,
					clickOutsideToClose: true,
					escapeToClose: true
				});
			}
		}

		// function openActivityModal(property, service) {
		// 	return $mdDialog.show({
		// 		templateUrl: 'app/main/modules/admin/modules/propertyActivity/PropertyActivityModal.html',
		// 		controller: 'PropertyActivityModalCtrl',
		// 		controllerAs: 'Activity',
		// 		locals: {
		// 			params: {
		// 				property: property,
		// 				service: service,
		// 			}
		// 		},
		// 		preserveScope: true,
		// 		clickOutsideToClose: true,
		// 		escapeToClose: true
		// 	});
		// }

		return {
			index: index,
			options: options,
			save: save,
			read: read,
			addressLookup: addressLookup,
			saveMeterReadings: saveMeterReadings,
			propertyUtilityTypeOptions: propertyUtilityTypeOptions,
			saveServices: saveServices,
			openTariffModal: openTariffModal,
			deleteQuoteItem: deleteQuoteItem,
		};

	});
