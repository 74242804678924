angular.module('app.main')
	.directive("serverSideErrors", function () {

		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			scope: {errors: '='},
			template: '<div id="errors" class="alert alert-danger errors-alert" ng-show="errors && errors.length"><ul><li ng-repeat="err in errors track by $index">{{ err }}</li></ul></div>',
			link: linkFn
		};

		function linkFn(scope, element, iAttr, ngModel) {
			scope.$watch('errors', function (value) {
				if (angular.isDefined(value)) {
					angular.element('.content-scroll').animate({scrollTop: angular.element("#errors").offset().top}, "slow");

					angular.element(element).removeClass('ng-hide-remove').addClass('ng-hide-remove');
					setTimeout(function () {
						angular.element(element).removeClass('ng-hide-remove');
					}, 1000);
				}
			});
		}


	});