angular.module('app.main.customer')
	.factory('EndpointSvc', function (RequestSvc) {

		function index(params) {
			return RequestSvc.get('api/customer/property/endpoint/index', params);
		}

		return {
			index: index
		};

	});
