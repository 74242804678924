angular.module('app.main.shared.utility')
	.directive('utilityBill', [
		'$global',
		'$filter',
		function ($global,
				  $filter) {
			return {
				restrict: 'E',
				require: [
					'^form'
				],
				scope: {
					bill: '=',
					contract: '='
				},
				templateUrl: 'app/main/modules/shared/modules/utility/directives/utilityBill/UtilityBill.html',
				link: function link(scope, element, attrs, controllers) {
					scope.config = $global.config;
					var bill = scope.bill;
					var contract = scope.contract;
					var supplierUtilityTypes = contract.supplier_utility_types;
					// Get grouped accounts or place single account in array for iteration
					var accounts = scope.accounts = contract.account.accounts || [contract.account];
					// Generate bill items
					if (!angular.isArray(bill.items)) {
						angular.extend(bill, {
							items: []
						});
					}
					angular.forEach(accounts, function (account) {
						angular.forEach(supplierUtilityTypes, function (ut) {
							// add to bill items if not already present
							var result = $filter('filter')(bill.items, {
								account: {
									id: account.id
								},
								contract_utility_type: {
									id: ut.id
								}
							}, true);
							if (!result.length) {
								bill.items.push({
									account: account,
									contract_utility_type: ut
								})
							}
						});
					});
				}
			};
		}
	]);
