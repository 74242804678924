angular.module('app.main.public.auth', [])
	.config(['$stateProvider', function ($stateProvider) {

		$stateProvider

			.state('main.public.auth', {
				abstract: true,
				data: {
					checkLogin: false,
				},
			})

			.state('main.public.auth.login', {
				url: '/login',
				views: {
					'content@main.public': {
						controller: 'LoginCtrl',
						controllerAs: 'Login',
						templateUrl: 'app/main/modules/public/modules/auth/templates/Login.html'
					}
				},
				ncyBreadcrumb: {
					label: 'Login'
				}
			})

			.state('main.public.auth.signup', {
				url: '/signup',
				views: {
					'content@main.public': {
						controller: 'SignupCtrl',
						controllerAs: 'Signup',
						templateUrl: 'app/main/modules/public/modules/auth/templates/Signup.html'
					}
				},
				ncyBreadcrumb: {
					label: 'Signup'
				}
			})

			.state('main.public.auth.forgot-password', {
				url: '/forgot-password',
				views: {
					'content@main.public': {
						controller: 'ForgotPasswordCtrl',
						controllerAs: 'ForgotPassword',
						templateUrl: 'app/main/modules/public/modules/auth/templates/ForgotPassword.html'
					}
				},
				ncyBreadcrumb: {
					label: 'Forgot Password'
				}
			})

			.state('main.public.auth.reset-password', {
				url: '/reset-password/{key}',
				views: {
					'content@main.public': {
						controller: 'ResetPasswordCtrl',
						controllerAs: 'ResetPassword',
						templateUrl: 'app/main/modules/public/modules/auth/templates/ResetPassword.html'
					}
				},
				ncyBreadcrumb: {
					label: 'Forgot Password'
				}
			})


		;

	}]);
