angular.module('app.main.shared.utility')
	.factory('UtilityReadingTypeSvc', [
		'RequestSvc',
		function (RequestSvc) {

			return {
				options: function (params) {
					return RequestSvc.get('api/utility/reading-type/options', params);
				}
			};

		}
	]);
