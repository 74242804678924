angular.module('app.main.customer')
	.controller('AccountPaymentCtrl', AccountPaymentCtrl);

AccountPaymentCtrl.$inject = [
	'$scope',
	'$window',
	'$state',
	'$q',
	'$filter',
	'focus',
	'$timeout',
	'WizardHandler',
	'StripeElements',
	'ConfirmationDialog',
	'PropertySvc',
	'AuthSvc',
	'CustomerAccountSvc',
	'PaymentCardSvc',
	'AccountSvc',
];

function AccountPaymentCtrl(
	$scope,
	$window,
	$state,
	$q,
	$filter,
	focus,
	$timeout,
	WizardHandler,
	StripeElements,
	ConfirmationDialog,
	PropertySvc,
	AuthSvc,
	CustomerAccountSvc,
	PaymentCardSvc,
	AccountSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		activeStepIndex: 0,
		activeStepName: '',
		isLastStep: false,
		enableSubmit: true,
		submitPromise: null,
		isManualAddress: false,
		showPaymentPage: false,
		allowAddressSelection: true,
		submitButtonText: 'Proceed to payment',
		addressOption: null,
		rates: null,
		options: {},
		form: {
			address: {},
			card: {},
			directDebit: {
				paymentDay: 1,
				confirmedName: null,
				confirmedDebit: null,
				sortCode: {
					firstSet: null,
					secondSet: null,
					thirdSet: null
				},
				accountName: null,
				accountNumber: null,
			},
		},
		paymentDays: [],
		card: {},
		popoverConfig: {
			xPosition: 'right',
			openOnHover: false,
			minWidth: 250,
		},
	});

	self.steps = [
		{
			index: 0,
			title: 'Summary',
			shortTitle: 'Summary',
			description: "Below is a list of services which are currently paused and awaiting a payment method. Please review all these services before proceeding as these will be processed as soon as you add your payment details",
			value: 'summary',
			template: 'app/main/modules/customer/_accountPayment/Partials/ServiceSummary.html',
			submitted: false,
			completed: false,
			disabled: false,
		},
		{
			index: 1,
			title: "Before we ask for payment details..",
			shortTitle: 'Details',
			description: 'We require a few personal pieces of information in order to secure your account. We shall use these only if you need to contact us',
			value: 'details',
			template: 'app/main/modules/customer/_accountPayment/Partials/Details.html',
			submitted: false,
			completed: false,
			disabled: false,
		},
		{
			index: 2,
			title: "Direct Debit Details",
			shortTitle: 'Payment',
			description: 'Setup a Direct Debit with Mango',
			value: 'dd_payment',
			template: 'app/main/modules/customer/_accountPayment/Partials/DirectDebitPayment.html',
			submitted: false,
			completed: false,
			disabled: true,
		},
		{
			index: 3,
			title: "Payment details",
			shortTitle: 'Payment',
			description: 'Please provide your payment details below',
			value: 'stripe_payment',
			template: 'app/main/modules/customer/_accountPayment/Partials/StripePayment.html',
			submitted: false,
			completed: false,
			disabled: true,
		},
		{
			index: 4,
			title: "Confirmation",
			shortTitle: 'Confirmation',
			description: "",
			value: 'confirmation',
			template: 'app/main/modules/customer/_accountPayment/Partials/Confirmation.html',
			submitted: false,
			completed: false,
			disabled: false,
		},
	];

	$scope.$watch(function () {
		return WizardHandler.wizard();
	}, function (wizard) {
		if (wizard) {
			// go directly to details
			if ($state.params.skipSummary === true) {
				WizardHandler.wizard()
					.goTo(1);
			}
		}
	});

	this.$onInit = () => {
		self.CustomerCtrl = $scope.Customer;
		self.CustomerCtrl.backButtonConfig.hide = true; // initially hide header back button
		self.activeAccount = AuthSvc.getRawAccount();
		self.user = AuthSvc.getRawUser();

		if (self.activeAccount.dd_available) {
			setDirectDebitDays(28);
		}
		// Handle Steps
		angular.forEach(self.steps, (step) => {
			if (step.value === 'dd_payment') {
				step.disabled = !self.activeAccount.dd_available;
			} else if (step.value === 'stripe_payment') {
				step.disabled = self.activeAccount.dd_available;
			}
		});

		// If we've been redirect from property.add and the new property was added to a different account
		self.showDifferentAccountNotice = $state.params.showDifferentAccountNotice;

		if (self.activeAccount.setup_payment_methods_count > 0) {
			$state.go('main.customer.dashboard.index');
		}

		// If it's a home property and only one property on the account, we can allow them to select same_as_supply
		// Server side we'll pick their only property and use that address.
		if ('landlord' === 'home' && self.activeAccount.property_count === 1) {
			self.addressOption = 'same_as_supply';
		}
		else {
			self.allowAddressSelection = false;
			self.addressOption = 'specified';
		}

		self.user = AuthSvc.getRawUser();
		self.form.mobile = self.user.phone;
		if (self.user.dob) {
			self.form.dob_day = moment(self.user.dob)
				.format('DD');
			self.form.dob_month = moment(self.user.dob)
				.format('MM');
			self.form.dob_year = moment(self.user.dob)
				.format('YYYY');
		}

		let params = {
			filter: {
				account: {
					id: self.activeAccount.id,
				}
			}
		};
		CustomerAccountSvc.propertiesAwaitingPayment(params)
			.then((response) => {
				self.properties = response.data.property_collection;
				self.totalBudget = response.data.total_budget;
				self.nextPaymentDate = moment()
					.add(1, 'month');

				// Copy the sales text back onto each endpoint (bit of a faff)
				angular.forEach(self.properties, (property) => {
					if (property.endpoints) {
						// Pick a tariff group from the first contract (they're all the same)
						let contract = property.endpoints[0].contracts.length ? property.endpoints[0].contracts[0] : null;
						let tariff = contract ? contract.tariff : null;
						let tariffGroups = tariff ? tariff.groups : null;
						if (tariffGroups && tariffGroups.length) {
							angular.forEach(self.properties, (property) => {
								// for each endpoint, find the corresponding utility type matching that in tariffGroups
								angular.forEach(property.endpoints, (endpoint) => {
									let matchedGroup = $filter('filter')(tariffGroups, (group) => {
										return endpoint.property_utility_type.utility_type.id === group.utility_type.id;
									});
									if (matchedGroup.length) {
										let group = matchedGroup[0];
										angular.extend(endpoint, {
											sales_text: group.sales_text,
											sales_annual_saving: group.sales_annual_saving,
										});
									}
								});
							});
						}
					}
				});

				if (self.properties.length <= 2) {
					angular.forEach(self.properties, (property) => {
						property.collapsed = true;
					});
				}
				self.loading = false;
			});
	};

	$scope.$on('navBackButtonClicked', () => {
		handlers.backClick();
	});

	$scope.$on('wizard:stepChanged', function (event, args) {
		let lastEnabledStep = getLastEnabledStep();
		self.isLastStep = args.step.wzData.index === lastEnabledStep.index;
		self.activeStepIndex = args.index;
		self.activeStepValue = args.step.wzTitle.toString();
		setFocus();
		self.CustomerCtrl.backButtonConfig.hide = args.index === 0; // show/hide header back button

		switch (self.activeStepValue) {
			case 'summary':
			case 'details':
				self.submitButtonText = 'Proceed to payment';
				break;
			case 'stripe_payment':
			case 'dd_payment':
				self.submitButtonText = 'Confirm';
				break;
		}

		if (self.activeStepValue === 'stripe_payment' || self.activeStepValue === 'dd_payment') {
			self.showPaymentPage = true;
		}
	});

	function getLastEnabledStep() {
		let enabledSteps = $filter('filter')(self.steps, (step) => {
			return !step.disabled;
		});
		if (enabledSteps.length) {
			return enabledSteps[enabledSteps.length - 2];
		}
	}

	function setFocus() {
		if (self.activeStepValue === 'details') {
			focus('mobile');
		}
		else if (self.activeStepValue === 'stripe_payment') {
			focus('name_on_card');
		}
	}

	function validateStep(step) {
		let validated = true;
		if (step.value === 'details') {

			let dob = self.form.dob_year + '-' + self.form.dob_month + '-' + self.form.dob_day;
			let dobDate = moment.utc(dob, 'YYYY-MM-DD');
			if (!dobDate.isValid()) {
				step.errors = ["You must input a correct date for your DOB"];
				validated = false;
			}
			else {
				self.form.dob = dobDate;
			}
			if (self.addressOption !== 'same_as_supply' && !self.form.address.line_1) {
				step.errors = ["You must supply your address"];
				validated = false;
			}
		}
		return validated;
	}

	function calculateTotalPrice() {
		let totalBudget = 0;
		angular.forEach(self.properties, (property) => {
			if (angular.isObject(property.endpoints)) {
				angular.forEach(property.endpoints, (endpoint) => {
					totalBudget += endpoint.contracts[0].initial_monthly_budget;
				});
			}
		});

		self.totalBudget = totalBudget;
	}

	function setDirectDebitDays(days = 28) {
		for (let i = 1; i <= days; i++) {
			self.paymentDays.push(i);
		}
	}

	handlers.confirmationClick = (value) => {
		if (value === 0) {
			self.acceptTerms = false;
		}
	};

	// Handlers --------------------------------------------------------------------------------------------------------

	handlers.goToStep = (step) => {
		WizardHandler.wizard()
			.goTo(step.value);
	};

	handlers.continueClick = (isValid, step, form) => {
		step.errors = null;
		if (!isValid) {
			step.submitted = true;
			step.errors = [];

			for (let key in form.$error) {
				for (let index = 0; index < form.$error[key].length; index++) {
					let errorField = form.$error[key][index].$name;
					errorField = errorField.replace(/^\w/, c => c.toUpperCase());
					step.errors.push(errorField + ' is required.');
				}
			}
			return;
		}

		if (validateStep(step)) {
			step.completed = true;

			let lastEnabledStep = getLastEnabledStep();
			if (step.index === lastEnabledStep.index) {
				return handlers.submitDirectDebitClick(step);
			}
			else {
				WizardHandler.wizard()
					.next();
			}
		}

	};

	handlers.backClick = () => {
		WizardHandler.wizard()
			.previous();
	};

	handlers.postcodeSearchClick = () => {
		self.searchingAddress = true;
		return PropertySvc.addressLookup({postcode: self.postcode_entered})
			.then((response) => {
				self.options.addresses = response.data.addresses;
				self.searchingAddress = false;
			});
	};

	handlers.selectAddressClick = (address) => {
		self.form.address = address;
	};

	handlers.manualEntryClick = () => {
		self.isManualAddress = true;
		focus('line_1');
	};

	handlers.removeManualAddress = () => {
		self.isManualAddress = false;
		self.form.address = undefined;
		focus('postcode1');
	};

	handlers.initDirectDebitPaymentSetup = () => {
		// Left in case I need it during development. Can be removed if empty.
	};

	handlers.initStripePaymentSetup = () => {
		self.elements = StripeElements.elements();

		PaymentCardSvc.createStripeSetupIntent()
			.then((response) => {
				$timeout(() => {
					self.setupIntent = response.data.setup_intent;
					self.paymentInitComplete = true;
				});
			});

		let elementStyles = {
			base: {
				color: '#36495F',
				fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':focus': {
					color: '#424770',
				},
				'::placeholder': {
					color: '#C0CCDA',
				},
				':focus::placeholder': {
					color: '#C0CCDA',
				},
			},
			invalid: {
				color: '#FFF',
				':focus': {
					color: '#FA755A',
				},
				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		};

		self.cardNumberElement = self.elements.create('cardNumber', {style: elementStyles});
		self.cardExpElement = self.elements.create('cardExpiry', {style: elementStyles});
		self.cardCvcElement = self.elements.create('cardCvc', {style: elementStyles});

		self.cardNumberElement.mount('#card-number-element');
		self.cardExpElement.mount('#card-expiry-element');
		self.cardCvcElement.mount('#card-cvc-element');

	};

	handlers.showTariffClick = (property, endpoint) => {
		let broadbandProductType = null
		if (endpoint.property_utility_type.utility_type.value === 'broadband') {
			broadbandProductType = endpoint.contracts[0].broadband_product_type;
		}
		PropertySvc.openTariffModal(property.address.postcode,property.room_count, endpoint.property_utility_type.utility_type, broadbandProductType, self.rates).then((result) => {
			self.rates = result.rates;
		});
	};

	handlers.removeContractClick = (propertyCollection, endpoint) => {
		ConfirmationDialog.show(
			'Are you sure?',
			"Are you sure you want to remove this service from your quote?",
			'Cancel',
			{
				label: 'Remove',
				actionType: 'negative',
			})
			.then(function () {
				PropertySvc.deleteQuoteItem({id: endpoint.contracts[0].id}).then((response) => {
					propertyCollection.endpoints.splice(propertyCollection.endpoints.indexOf(endpoint), 1);
					calculateTotalPrice();
				});
			});
	};

	handlers.submitDirectDebitClick = (step) => {
		let params = {
			account: {
				id: self.activeAccount.id,
			},
			mobile: self.form.mobile,
			dob: self.form.dob,
			address: self.form.address,
			addressOption: self.addressOption,
		};
		self.submitPromise = AccountSvc.secureAccountSave(params).then((response) => {
			self.submitButtonText = 'Nearly done..';
			const sortCode = `${self.form.directDebit.sortCode.firstSet}${self.form.directDebit.sortCode.secondSet}${self.form.directDebit.sortCode.thirdSet}`;
			let params = {
				account: {
					id: self.activeAccount.id,
				},
				account_name: self.form.directDebit.accountName,
				account_number: self.form.directDebit.accountNumber,
				sort_code: sortCode,
				dd_date: self.form.directDebit.paymentDay,
			};
			self.submitPromise = PaymentCardSvc.submitDirectDebit(params)
				.then(response => {
					angular.extend(self.activeAccount, {
						setup_payment_methods_count: 1
					});
					AuthSvc.setAccount(self.activeAccount);
					angular.extend(self.user, {
						seen_quick_start_guide: true,
					});
					AuthSvc.setUser(self.user);
					WizardHandler.wizard()
						.next();
				}, error => {
					self.submitButtonText = 'Confirm';
					$window.scrollTo(0, 0);
					$timeout(() => {
						step.errors = error;
					});
				});
		}, error => {
			$timeout(() => {
				step.errors = error;
			});
		});
	}

	handlers.submitStripeClick = (step) => {
		// To clarify what we're doing here...
		// 1. We use the setupIntent from above to confirm the card entered  (handleCardSetup)
		// 2. We then generate a customer payment method and subscription
		// 3. We then add the card to the customers stripe subscription (using setupIntent from step 1)
		// 4. Finally, we update their profile/account information

		self.submitButtonText = 'Processing..';
		self.submitPromise = StripeElements.handleCardSetup(
			self.setupIntent.client_secret, self.cardNumberElement, {
				payment_method_data: {
					billing_details: {
						name: self.form.card.name_on_card
					}
				}
			}
		)
			.then(function (result) {
				if (result.error) {
					$timeout(() => {
						step.errors = [result.error.message];
					});
				}
				else {
					let setupIntent = result.setupIntent;

					let params = {
						account: {
							id: self.activeAccount.id,
						},
						name_on_card: self.form.card.name_on_card,  // we add this because handleCardSetup doesn't seem to work anymore
					};
					self.submitButtonText = 'Adding card..';
					self.submitPromise = PaymentCardSvc.createSubscriptionPaymentMethod(params).then((response) => {
						angular.extend(params, {
							setupIntent: setupIntent,
						});
						self.submitPromise = PaymentCardSvc.addStripeCard(params).then((response) => {
							self.submitButtonText = 'Nearly done..';
							let params = {
								account: {
									id: self.activeAccount.id,
								},
								mobile: self.form.mobile,
								dob: self.form.dob,
								address: self.form.address,
								addressOption: self.addressOption,
							};
							self.submitPromise = AccountSvc.secureAccountSave(params).then((response) => {
								angular.extend(self.activeAccount, {
									setup_payment_methods_count: 1
								});
								AuthSvc.setAccount(self.activeAccount);
								angular.extend(self.user, {
									seen_quick_start_guide: true,
								});
								AuthSvc.setUser(self.user);
								WizardHandler.wizard()
									.next();
							});
						});
					});
				}
			});
	};
}
