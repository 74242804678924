angular.module('app.main.customer.property.reads', [

])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.customer.property.reads', {
				abstract: true,
			})

			.state('main.customer.property.reads.add', {
				url: '/reads/add',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_property/_reads/MeterReadSave.html',
						controller: 'MeterReadSaveCtrl',
						controllerAs: 'MeterRead',
					}
				},
				ncyBreadcrumb: {
					label: 'Meter Reads'
				},
			})

		;
	}]);
