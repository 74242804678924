angular.module('app.main')
	.controller('CustomerCtrl', CustomerCtrl);

CustomerCtrl.$inject = [
	'$scope',
	'$rootScope',
	'$state',
	'$q',
	'$timeout',
	'$filter',
	'$localStorage',
	'$mdSidenav',
	'$intercom',
	'AuthSvc',
	'AccountSvc',
];

function CustomerCtrl(
	$scope,
	$rootScope,
	$state,
	$q,
	$timeout,
	$filter,
	$localStorage,
	$mdSidenav,
	$intercom,
	AuthSvc,
	AccountSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
		//
		backButtonConfig: {
			hide: false,
			disabled: false,
		},
		accounts: [],
		options: {
			filteredProperties: [],
		},
		// showMatches: false,
		profilePopoverConfig: {
			xPosition: 'right',
			openOnHover: false,
			minWidth: 250,
		},
		popoverAddConfig: {
			xPosition: 'right',
			openOnHover: false,
			minWidth: 250,
		},
		groupsPopoverConfig: {
			xPosition: 'left',
			openOnHover: true,
			minWidth: 500,
		},
	});

	this.$onInit = () => {
		self.isAuthenticated = AuthSvc.isAuthenticated();
		self.hideAuthenticatedNav = $state.current.data.hideAuthenticatedNav;
		self.stateName = $state.current.name;
		self.impersonating = angular.isObject(AuthSvc.getRawUser()) && AuthSvc.getRawUser().impersonating;
		self.user = AuthSvc.getRawUser();

		setAccounts();
	};

	function setAccounts() {
		self.accounts = AuthSvc.getRawAccounts();
		self.activeAccount = AuthSvc.getRawAccount();
		console.log(self.accounts, self.activeAccount);
	}

	function switchAccount(account) {
		let deferred = $q.defer();

		AuthSvc.setAccount(account);
		self.activeAccount = account;
		$localStorage.defaultAccount = self.activeAccount;

		deferred.resolve();
		return deferred.promise;
	}

	$rootScope.$on('onAccountUpdated', (event, account) => {
		self.activeAccount = account;
		$localStorage.defaultAccount = account;

		// update the billing group in the billingGroups list
		angular.forEach(self.accounts, (_account) => {
			if (_account.id === self.activeAccount.id) {
				angular.extend(_account, angular.copy(self.activeAccount));
			}
		});
	});

	$rootScope.$on('onLogin', () => {
		self.isAuthenticated = AuthSvc.isAuthenticated();
		self.user = AuthSvc.getRawUser();
	});

	$rootScope.$on('updateBar', () => {
		self.account = $localStorage.account;
		if (angular.isObject(self.account)) {
			if (angular.isObject(self.account.voucher)) {
				if (self.account.voucher.referrer === 'OpenRent') {
					self.referralLogo = true;
				}
			}
		}
	});

	$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
		self.stateName = toState.name;
		self.isAuthenticated = AuthSvc.isAuthenticated();
		self.hideAuthenticatedNav = toState.data.hideAuthenticatedNav;
	});

	$scope.$on('accountChanged', (event, account) => {
		switchAccount(account);
	});

	$scope.$on('propertyAdded', (event, property) => {
		let account = angular.copy(property.account);
		console.log(property.account);
		if (angular.isUndefined(self.activeAccount)) {
			// First property we've added
			AuthSvc.pushAccount(account);
			AuthSvc.setAccount(account);
		} else {
			// Check if we already have an account we can push this property onto
			// otherwise, we'll go ahead and push another account
			let existingAccount = $filter('filter')(self.accounts, (account) => {
				return account.id === property.account.id;
			});

			if (!existingAccount.length) {
				AuthSvc.pushAccount(account);
				AuthSvc.setAccount(account);
			}
			else {
				angular.extend(existingAccount[0], property.account); // update the existing account with our new returned account
			}
		}

		setAccounts();

	});

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.logoutClick =  () => {
		AuthSvc.logout().then(() => {
			$state.go('main.public.index');
		});
	};

	handlers.stopImpersonating = () => {
		AuthSvc.stopImpersonating();
	};

	handlers.showIntercom = () => {
		// $intercom.show();
	};

	handlers.selectAccountClick = (account) => {
		switchAccount(account).then(() => {
			$state.go('main.customer.dashboard.index');
		});
	};

	handlers.filterProperties = () => {
		let term = self.searchTerm;
		if (term !== '') {
			let params = {
				filter: {
					search: term,
				}
			};
			if (angular.isDefined(self.promise)) {
				self.promise.cancel();
				self.searching = false;
			}
			self.searching = true;
			self.noMatches = false;
			self.promise = PropertySvc.options(params);
			self.promise.then(function (response) {
				self.options.filteredProperties = response.data.property_options;
				if (self.options.filteredProperties.length === 0) {
					self.noMatches = true;
				}
				else {
					self.options.filteredProperties = _.groupBy(self.options.filteredProperties, function (item) {
						return item.billing_group.label
					});

				}
				self.searching = false;
			});
		}
	};

	handlers.addAccountClick = () => {
		AccountSvc.openAccountModal()
			.then((result) => {
				self.accounts.push(result);
			});
	};

	handlers.toggleBurgerMenu = (component) => {
		self.burgerMenuOpen = !self.burgerMenuOpen;
		$mdSidenav(component).toggle();
	};

	// Wizards back button handlers

	handlers.backButtonClick = () => {
		// universal back button,
		// raise global event which is to be picked up by whatever controller should respond too
		$rootScope.$broadcast('navBackButtonClicked');
	};
}
