
angular.module('app.main')
	.directive('logo', function () {
		return {
			restrict: 'E',
			scope: {
				company: '=',
				size: '='
			},
			replace: true,
			templateUrl: 'app/main/modules/shared/modules/company/logo/Logo.html',
			link: function (scope) {
				if (!isNaN(parseInt(scope.size))) {
					scope.customSize = true;
				}
			}
		};
	});

