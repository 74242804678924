angular.module('app.main.customer')
	.controller('QuickStartCtrl', QuickStartCtrl);

QuickStartCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'AuthSvc',
	'QuickStartSvc',
];

function QuickStartCtrl(
	$scope,
	$state,
	$q,
	AuthSvc,
	QuickStartSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		stats: {},
	});

	self.steps = [
		{
			index: 0,
			value: "add_property",
			title: "Add a property",
			completedTitle: "You've added your property",
			completedDesc: "",
			description: "Get started by adding your first property. You can add any number of properties and still receive just one bill and make one payment per month.",
			icon: "check-solid",
			completed: false,
			active: true,
			visible: true,
			disabled: false,
		},
		{
			index: 1,
			value: "add_service",
			title: "Add one or more Mango services",
			completedTitle: "",
			completedDesc: "",
			description: "Simplify your bills and switch your services to us in less than 2 minutes.",
			icon: "check-solid",
			completed: false,
			active: true,
			visible: true,
			disabled: false,
		},
		{
			index: 2,
			value: "payment_method",
			title: "Add a payment method",
			completedTitle: "Payment method added",
			completedDesc: "",
			description: "We'll ask for your direct debit details when you switch any service to us.\n" +
				"We're always free to use for managing reminders and documents. ",
			icon: "check-solid",
			completed: false,
			active: true,
			visible: true,
			disabled: false,
		},
		{
			index: 3,
			value: "add_reminder",
			title: "Add a reminder",
			completedTitle: "You've added a reminder",
			completedDesc: "",
			description: "In a fixed contract or not ready to switch? No problem. Create a reminder for those services and we'll make it super-easy to switch " +
						"when it's time to do so.",
			icon: "check-solid",
			completed: false,
			active: true,
			visible: true,
			disabled: false,
		},
		{
			index: 4,
			value: "add_document",
			title: "Add a key document",
			description: "Everything in one place. Keep all your important and relevant documents in Mango so you'll always have them to hand.",
			icon: "file-alt-light",
			completed: false,
			active: false,
			visible: true,
			disabled: false,
		},
		{
			index: 5,
			value: "set_budget",
			title: "Set a budget",
			description: "To help keep on top of costs, set a budget and we'll alert you when you're forecast to exceed it.",
			icon: "chart-bar-regular",
			completed: false,
			active: false,
			visible: true,
			disabled: false,
		},
	];

	this.$onInit = () => {
		self.user = AuthSvc.getRawUser();

		QuickStartSvc.index().then((response) => {
			self.stats = response.data.stats;
			self.endpointCollection = self.stats.endpoint_collection;
			self.property = self.stats.property;

			angular.forEach(self.steps, (step) => {
				switch (step.value) {
					case 'add_property':
						 step.completed = self.stats.property_count > 0;
						 if (angular.isObject(self.property) && angular.isObject(self.property.address)) {
						 	step.completedDesc = self.property.address.single_line_address;
						 }
						break;
					case 'add_service':
						step.completed = self.stats.account_count > 0;
						if (step.completed) {
							step.completedTitle = self.stats.account_count === 1 ? "You've added a Mango service" : "You've added some Mango services";
						}
						if (self.stats.property_count === 0) {
							step.disabled = true;
						}
						break;
					case 'add_reminder':
						step.completed = self.stats.reminder_count > 0;
						if (self.stats.property_count === 0) {
							step.disabled = true;
						}
						break;
					case 'payment_method':
						step.completed = self.stats.payment_method;
						if (self.stats.account_count === 0) {
							step.disabled = true;
						}
				}
			});

			if (self.endpointCollection.length > 0 && !self.stats.payment_method) {
				enableFirstTimeQuote();
			}

			self.loading = false;
		});
	};

	function enableFirstTimeQuote() {
		angular.forEach(self.steps, (step) => {
			if (step.value === 'payment_method') {
				step.title = 'Confirm your quote and continue to payment';
				step.description = "Click 'Continue' below to see a full summary of your quote. You can add / update any services and when you're happy you can proceed to payment."
				step.showQuote = true;
			}
			
		});
	}

	handlers.stepClick = (step) => {
		if (!step.completed && !step.disabled && step.active) {
			if (step.value === 'add_property') {
				$state.go('main.customer.property.add', {redirectTo: 'quick-start'});
			}
			else if (step.value === 'add_service' || step.value === 'add_reminder') {
				$state.go('main.customer.property.services.index', {propertyId: self.property.id});
			}
			else if (step.value === 'payment_method') {
				$state.go('main.customer.account-payment');
			}
		}
	};

	handlers.accountPaymentClick = () => {
		$state.go('main.customer.account-payment');
	};

	handlers.closeQuickStartGuide = () => {
		AuthSvc.markQuickStartGuideAsRead().then((response) => {
			self.quickStartClosed = true;
			angular.extend(self.user, {
				seen_quick_start_guide: true,
			});
			AuthSvc.setUser(self.user);
		});
	};


}
