angular.module('app.main.customer.property')
    .controller('ServicesIndexCtrl', ServicesIndexCtrl);

ServicesIndexCtrl.$inject = [
    '$scope',
    '$state',
    '$q',
    '$filter',
    '$timeout',
    'moment',
    'ConfirmationDialog',
    'PropertySvc',
    'UtilityContractSvc',
    'BudgetSvc',
    'AccountServiceRemindersSvc',
    'UtilityTypeSvc',
    'WizardHandler',
    'AuthSvc',
    'CustomerAccountSvc',
    'BroadbandProductTypeSvc',
    //
    '$localStorage'
];

function ServicesIndexCtrl(
    $scope,
    $state,
    $q,
    $filter,
    $timeout,
    moment,
    ConfirmationDialog,
    PropertySvc,
    UtilityContractSvc,
    BudgetSvc,
    AccountServiceRemindersSvc,
    UtilityTypeSvc,
    WizardHandler,
    AuthSvc,
    CustomerAccountSvc,
    BroadbandProductTypeSvc,
    //
    $localStorage
) {
    if (!$state.params.propertyId) {
        console.log('404');
        $state.go('404');
        return;
    }

    let self = this;
    let handlers = {};
    let methods = {};

    angular.extend(self, {
        handlers: handlers,
        methods: methods,
        propertyId: $state.params.propertyId,
        loading: true,
        isLastStep: false,
        submitPromise: null,
        submitDisabled: false,
        totalMonthlyCost: 0,
        existingTotalMonthlyCost: 0,
        property: {},
        popoverConfig: {
            xPosition: 'right',
            openOnHover: false,
            minWidth: 250,
        },
        options: {
            services: [], // combined services for selection
        },
        utilityTypes: { // uncombined utilities
            available: [],
            filtered: [],
        },
        contracts: [],
        reminders: [],
        selectedServices: [], // combined selected services
        selectedServicesDisplay: false,
        form: [], // form
        activeWizard: {
            value: '',
            label: '',
            steps: [],
        },
        rates: {},
        now: moment().utc().startOf('day'),
        allowWizardEditing: false,
    });

    this.$onInit = () => {
        self.CustomerCtrl = $scope.Customer;
        self.CustomerCtrl.backButtonConfig.hide = true; // initially hide header back button

        let promises = {
            property: PropertySvc.read(self.propertyId),
            contracts: UtilityContractSvc.index({limit: 200, page:1, filter: {property: self.propertyId}}), // replace.
            reminders: AccountServiceRemindersSvc.index({filter: {property: self.propertyId}}),
            availableUtilityTypes: UtilityTypeSvc.available({filter: {property: self.propertyId, enabled: true}}),
        };

        $q.all(promises)
            .then((responses) => {
                $scope.property = responses.property.data.property.address.line_1; // for browser breadcrumb
                self.property = responses.property.data.property;
                self.contracts = responses.contracts.data.contract_collection;
                self.reminders = responses.reminders.data.service_reminder_collection;
                self.utilityTypes.available = responses.availableUtilityTypes.data.utility_type_options; // returned data is a little iffy, review.
            }).finally(() => {
                BudgetSvc.rates({
                    postcode: self.property.address.postcode,
                    bed_size: self.property.room_count ? self.property.room_count : 4
                })
                .then((response) => {
                    self.rates = response.data.rates;
                }).finally(() => {
                    setOptions(() => {
                            //
                        }, (error) => {
                            console.log(error)}
                    ).finally(() => {
                        self.loading = false;
                    });
                });
        });
    };

    $scope.$on('wizard:stepChanged', function (event, args) {
        let lastEnabledStep = getLastEnabledStep();
        self.isLastStep = args.step.wzData.value === lastEnabledStep.value;
        self.CustomerCtrl.backButtonConfig.hide = args.index === 0; // show/hide header back button
        updatePrices();
    });

    $scope.$on('navBackButtonClicked', () => {
        self.handlers.previousClick();
    });

    // $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
    //     alert('$stateChangeSuccess');
    // });

    // Service Listing / Selection / Index

    function setOptions() {
        return setOptionsServices()
            .then(() => {
                if (self.options.services.length) {
                    return setOptionsBroadband();
                } else {
                    $state.go('404');
                }
            });
    }

    function filterUtilityTypes() {
        let currentUtilityTypeIds = [],
            filteredUtilityTypes = [];

        if (self.contracts.length) {
            angular.forEach(self.contracts, (contract) => { // remove existing utility types if relation exists
                currentUtilityTypeIds.push(contract.endpoint.property_utility_type.utility_type.id);
            });
        }

        // if (self.reminders.length) {
        //     angular.forEach(self.reminders, (reminder) => { // remove reminders? handle separately?
        //         currentUtilityTypeIds.push(reminder.utility_type.id);
        //     });
        // }

        angular.forEach(self.utilityTypes.available, (utilityType) => {
            if (_.indexOf(currentUtilityTypeIds, utilityType.utility_type.id) === -1) { // @todo fix
                utilityType.disabled = false;
                filteredUtilityTypes.push(utilityType);
            } else {
                utilityType.disabled = true;
                filteredUtilityTypes.push(utilityType);
            }
        });

        return filteredUtilityTypes;
    }

    function setOptionsServices() {
        let deferred = $q.defer(),
            utilityTypes,
            services = [];

        self.utilityTypes.filtered = filterUtilityTypes(); // Ehh

        if (self.utilityTypes.filtered.length > 0) {
            utilityTypes = self.utilityTypes.filtered
        } else {
            deferred.resolve();
            return deferred.promise;
        }

        angular.forEach(utilityTypes, (utilityType, key) => {
            let notRequired = true;

            // Energy Handling
            if (utilityType.utility_type.is_energy) {
                notRequired = false;
                let energyService = $filter('filter')(services, (service) => {
                    return service.value === 'energy';
                })[0];

                if (!energyService) {
                    services.push({
                        index: services.length +1,
                        label: 'Energy',
                        value: "energy",
                        type: 'quote',
                        selected: false,
                        skipped: false,
                        disabled: utilityType.disabled,
                        completed: false,
                        navSelected: false,
                        icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                        not_required: notRequired,
                        can_purchase: utilityType.can_purchase,
                        primary_utility_type_id: utilityType.utility_type.id,
                        utility_types: [utilityType.utility_type],
                    });
                } else {
                    energyService.utility_types.push(utilityType.utility_type);
                }
            }
            // Everything else
            else {
                services.push({
                    index: (!utilityType.disabled) ? services.length +1 : services.length +999,
                    label: utilityType.utility_type.label,
                    value: utilityType.utility_type.value,
                    type: 'quote',
                    selected: false,
                    skipped: false,
                    disabled: utilityType.disabled,
                    completed: false,
                    navSelected: false,
                    icon_path: utilityType.utility_type.icon_path,
                    not_required: notRequired,
                    can_purchase: utilityType.can_purchase,
                    primary_utility_type_id: utilityType.utility_type.id,
                    utility_types: [utilityType.utility_type],
                });
            }
        });

        let optionsServices = services.sort(function(a,b) {
            return (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0);
        });

        self.options.services = optionsServices;

        deferred.resolve();
        return deferred.promise;
    }

    function setOptionsBroadband() {
        let deferred = $q.defer();

        BroadbandProductTypeSvc.options({postcode: self.property.address.postcode})
            .then((response) => {
                self.options.broadbandProducts = response.data.options;
                self.options.broadbandVirginAvailable = response.data.virgin_available;
            }, (error) => {
                console.log(error);
            })
            .finally(() => {
                deferred.resolve();
                return deferred;
            });
    }

    // Service Configuration / Wizard

    function whenCreatingSteps() {
        let deferred = $q.defer();

        let hasBroadband = $filter('filter')(self.utilityTypes.available, {utility_type: {value: 'broadband'}}, true);
        if (hasBroadband) {
            let rates = $filter('filter')(self.rates, {utility: 'broadband'}, true)[0];
            console.log(rates);
            angular.forEach(self.options.broadbandProducts, (product) => {
                let rate = $filter('filter')(rates.rates, (rate) => {
                    return rate.broadband_product_type.id === product.id;
                })[0];
                if (rate) {
                    angular.extend(product, {
                        spend: rate.rates,
                    });
                }
            });
        }
        loadDefaults();

        // reorder by index
        self.selectedServices.sort(function(a,b) {
            return (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0);
        });

        // Service Steps
        angular.forEach(self.selectedServices, (service, key) => {
            let steps = [];

            if (service.can_purchase && !service.completed && !service.skipped) {
                if (service.value === 'energy') {
                    steps = getEnergySteps(service);
                }
                if (service.value === 'water') {
                    steps = getWaterSteps(service);
                }
                if (service.value === 'broadband') {
                    steps = getBroadbandSteps(service);
                }
                if (service.value === 'tv_licence') {
                    steps = getTvLicenceSteps(service);
                }

                service.steps = steps;

                for (let index in service.steps) {
                    service.steps[index].index = index;
                }
            }
        });

        deferred.resolve();
        return deferred.promise;
    }

    function getLastEnabledStep() {
        let enabledSteps = $filter('filter')(self.activeWizard.steps, (step) => {
            return !step.disabled;
        });

        if (enabledSteps.length) {
            return enabledSteps[enabledSteps.length - 1];
        }
    }

    function validateStep(step, form) {
        let validated = true,
            service = step.service_value;

        // Service Steps
        if (step.value === 'new_or_reminder') {
            if (!self.form[service].type || self.form[service].type === undefined) {
                step.errors = ["Please select an option"];
                validated = false;
            }
        }

        if (step.value === 'check_meter_type') {
            let prePayMeterTypeStep = $filter('filter')(self.activeWizard.steps, (step) => {
                return step.value === 'prepay_type';
            })[0];
            let meterTypeStep = $filter('filter')(self.activeWizard.steps, (step) => {
                return step.value === 'meter_type';
            })[0];

            if (!self.form[service].hasPrePayMeter) {
                step.errors = ["Please select your current payment method"];
                validated = false;
            }
            if (self.form[service].hasPrePayMeter === 'prepay') {
                prePayMeterTypeStep.disabled = false;
                meterTypeStep.disabled = true;
            } else {
                prePayMeterTypeStep.disabled = true;
                meterTypeStep.disabled = false;
            }
        }

        if (step.value === 'prepay_type') {
            if (!self.form[service].prePayMeterType) {
                step.errors = ["Please select the type of supplies you top-up"];
                validated = false;
            }
        }

        if (step.value === 'prepay_notice') {
            validated = false; // stop notice templates from progressing entirely
            // self.submitDisabled = true;
        }

        if (step.value === 'meter_type') {
            if (!self.form[service].meterType || self.form[service].meterType === 'unknown') {
                step.errors = ["Please select the supply type"];
                validated = false;
            }
        }

        if (step.value === 'usage') {
            if (self.form[service].usageType === 'spend') {
                if (!self.form[service].usageSpend || self.form[service].usageSpend === 0) {
                    step.errors = ["Please specify an amount spent per month"];
                    validated = false;
                }
            }
        }

        if (step.value === 'select_additional') {
            let quantityStep = $filter('filter')(self.activeWizard.steps, (step) => {
                return step.value === 'quantity';
            })[0];
            quantityStep.disabled = !self.form[service].increaseQuantity;
        }

        if (step.value === 'current_supplier_end_at') {
            if (self.form[service].contractEndAt.error) {
                step.errors = ["Please enter a valid date"];
                validated = false;
            }
        }

        if (step.value === 'reminder_date') {
            if (self.form[service].reminderDate.error) {
                step.errors = ["Please enter a valid date"];
                validated = false;
            }
        }

        return validated;
    }

    // Defaults / Rates

    function loadDefaults() {
        let selectedServices = [],
            createEnergy = false,
            energyUsageSpend = 0;

        if (self.selectedServices.length) {
            angular.forEach(self.selectedServices, (selectedService) => {
                angular.forEach(selectedService.utility_types, (utilityType) => {
                    let tmp = $filter('filter')(self.utilityTypes.available, {utility_type: {value: utilityType.value}})[0];
                    selectedServices.push(tmp)
                });
            });
        }

        angular.forEach(selectedServices, (selectedService) => {
            if (!angular.equals(selectedService.utility_type, {})) {
                let utilityType = selectedService.utility_type;

                let usageType = null;
                let usageKwh = 0;
                let productType = null;
                let usageSpend = 0;
                let quantity = 1;
                let productTypeObject = {};
                let notRequired = true;
                let rates = $filter('filter')(self.rates, {utility: utilityType.value}, true)[0];

                if (utilityType.value === 'electric') {
                    usageType = 'unknown';
                    usageKwh = rates.eac.eac;
                    notRequired = false;
                } else if (utilityType.value === 'gas') {
                    usageType = 'unknown';
                    usageKwh = rates.eac.eac;
                } else if (utilityType.value === 'water') {
                    usageType = 'unknown';
                    usageSpend = Math.round(parseFloat(rates.eac.water) / 12);
                } else if (utilityType.value === 'broadband') {
                    if (self.options.broadbandVirginAvailable) {
                        productType = self.options.broadbandProducts[0].id;
                        productTypeObject = self.options.broadbandProducts[0];
                    } else {
                        if (self.options.broadbandProducts) {
                            productType = self.options.broadbandProducts[1].id;
                            productTypeObject = self.options.broadbandProducts[1];
                        }
                    }
                } else if (utilityType.value === 'tv_licence') {
                    usageType = 'spend';
                }

                utilityType.quantity = quantity; // ?!
                if (!self.form[utilityType.value]) {
                    self.form[utilityType.value] = {};
                    angular.extend(self.form[utilityType.value], {
                        type: 'quote',
                        value: utilityType.value,
                        label: utilityType.label,
                        quantity: quantity,
                        defaultUsageKwh: usageKwh,
                        not_required: notRequired,
                        productType: productType,
                        productTypeObject: productTypeObject,
                        usageSpend: usageSpend,
                        usageType: usageType,
                        usageKwh: usageKwh,
                        rates: rates,
                    });
                    // create 'combined' energy
                    if (utilityType.value === 'gas' || utilityType.value === 'electric') {
                        if (!angular.isDefined(self.form.energy)) {
                            createEnergy = true;
                        }
                        if (utilityType.value === 'gas') {
                            energyUsageSpend += usageSpend;
                        }
                        if (utilityType.value === 'electric') {
                            energyUsageSpend += usageSpend;
                        }
                    }
                }
            }
        });

        if (createEnergy) {
            self.form.energy = {};
            let rates = $filter('filter')(self.rates, {utility: 'electric'}, true); // Er only electric
            angular.extend(self.form.energy, {
                type: 'quote',
                value: 'energy',
                label: 'Energy',
                quantity: 1,
                defaultUsageKwh: null,
                not_required: null,
                productType: null,
                productTypeObject: null,
                usageSpend: energyUsageSpend,
                usageType: 'unknown',
                usageKwh: null,
                rates: rates,
            });
        }

        $timeout(() => {
            updatePrices();
        }, 300);
    }

    function updatePrices() {
        let activeServices = 0;
        let totalSpend = 0;
        let electricSpend = 0;
        let gasSpend = 0;
        let selectedServices = self.selectedServices.length ? self.selectedServices : self.utilityTypes.available; // review

        angular.forEach(selectedServices, (selectedService) => {
            // if (!selectedService.skipped && selectedService.completed) {}
            angular.forEach(selectedService.utility_types, (utilityType) => {
                if (self.form[utilityType.value] && !self.form[utilityType.value].disabled && self.form[utilityType.value].type !== 'reminder') {
                    let serviceQty = (self.form[utilityType.value].quantity) ? self.form[utilityType.value].quantity : utilityType.quantity;
                    let spend = 0;
                    let rates = $filter('filter')(self.rates, {utility: utilityType.value}, true)[0];
                    let form = self.form[utilityType.value];

                    if (form.value === 'electric' || form.value === 'gas') {
                        if (form.usageType === 'spend') {
                            spend = Math.round(form.usageSpend);
                        } else if (form.usageType === 'kwh') {
                            let unitCost = rates.rates.unit_rate * form.usageKwh;
                            let standingChargeCost = rates.rates.standing_charge * 365;
                            let annualCharge = unitCost + standingChargeCost;
                            spend = Math.round(annualCharge / 12);
                        } else {
                            let unitCost = rates.rates.unit_rate * form.defaultUsageKwh;
                            let standingChargeCost = rates.rates.standing_charge * 365;
                            let annualCharge = unitCost + standingChargeCost;
                            spend = Math.round(annualCharge / 12);
                        }
                        if (utilityType.value === 'gas') {
                            gasSpend = spend;
                        } else if (utilityType.value === 'electric') {
                            electricSpend = spend;
                        }
                    }
                    else if (utilityType.value === 'water') {
                        if (form.usageType === 'spend') {
                            spend = Math.round(form.usageSpend);
                        } else {
                            spend = Math.round(rates.eac.water / 12);
                        }
                    }
                    else if (utilityType.value === 'tv_licence') {
                        spend = Math.round(rates.rates.rate * serviceQty);
                    }
                    else if (utilityType.value === 'broadband') {
                        if (!angular.equals(self.options.broadbandProducts, {})) {
                            form.productTypeObject = $filter('filter')(self.options.broadbandProducts, (broadbandProduct) => {
                                return broadbandProduct.id === form.productType;
                            })[0];
                            let rate = $filter('filter')(rates.rates, (rate) => {
                                return rate.broadband_product_type.id === form.productType;
                            });
                            if (rate.length) {
                                spend = Math.round(rate[0].rates * serviceQty);
                            }
                        }
                    }

                    if (form.type === 'quote') {
                        if (!form.default_spend) {
                            form.default_spend = spend;
                        }
                        form.monthly_spend = spend;
                    }
                    if (!utilityType.default_spend) {
                        utilityType.default_spend = spend;
                    }
                    utilityType.monthly_spend = spend;

                    if (!selectedService.skipped && selectedService.completed && serviceQty >= 1 && form.type !== 'reminder') {
                        activeServices++;
                        totalSpend += spend;
                    }
                }
            });
            if (selectedService.value === 'energy') {
                if (!self.form.energy.default_spend) {
                    self.form.energy.default_spend = Math.round(gasSpend + electricSpend);
                }
                self.form.energy.monthly_spend = Math.round(gasSpend + electricSpend);
            }
        });

        self.totalMonthlyCost = totalSpend;
        $timeout(() => {
            self.existingTotalMonthlyCost = totalSpend;
        }, 1000);
    }

    // Steps @todo Template need cleaning up. A lot of replicated markup.

    function getEnergySteps(service) {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/' + service.value;
        return [
            // Quote or Reminder selection
            {
                order: 1,
                title: 'Energy',
                service_value: service.value,
                value: 'new_or_reminder',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/new_or_reminder.html',
                template_for_reminder: true,
                disabled: false,
                completed: false,
                errors: []
            },
            // Normal Steps
            {
                order: 2,
                title: 'Meter',
                service_value: service.value,
                value: 'check_meter_type',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/check_meter_type.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            {
                order: 3,
                title: 'Top-Up Meters',
                service_value: service.value,
                value: 'prepay_type',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/prepay_type.html',
                template_for_reminder: false,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 4,
                title: 'Notice',
                service_value: service.value,
                value: 'prepay_notice',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/prepay_notice.html',
                template_for_reminder: false,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 5,
                title: 'Supply',
                service_value: service.value,
                value: 'meter_type',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/meter_type.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            {
                order: 6,
                title: 'Electric Usage',
                service_value: service.value,
                value: 'usage_electric',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/usage_electric.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            {
                order: 7,
                title: 'Gas Usage',
                service_value: service.value,
                value: 'usage_gas',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/usage_gas.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            // Reminder Steps Start
            {
                order: 8,
                title: 'Current',
                service_value: service.value,
                value: 'check_current_supplier',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/check_current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 9,
                title: 'Supplier',
                service_value: service.value,
                value: 'current_supplier',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 10,
                title: 'Contract End',
                service_value: service.value,
                value: 'current_supplier_end_at',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier_end_at.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 11,
                title: 'Reminder',
                service_value: service.value,
                value: 'reminder_date',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/reminder_date.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            // Summary
            {
                order: 12,
                title: 'Summary',
                service_value: service.value,
                value: 'summary',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/summary.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
        ]
    }

    function getWaterSteps(service) {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/' + service.value;
        return [
            {
                order: 1,
                title: 'Water',
                service_value: service.value,
                value: 'new_or_reminder',
                icon_path: service.icon_path,
                template: templatePath + '/new_or_reminder.html',
                template_for_reminder: true,
                disabled: false,
                completed: false,
                errors: []
            },
            // Steps
            {
                order: 2,
                title: 'Usage',
                service_value: service.value,
                value: 'usage',
                icon_path: service.icon_path,
                template: templatePath + '/usage.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            // Reminders
            {
                order: 3,
                title: 'Current',
                service_value: service.value,
                value: 'check_current_supplier',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/check_current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 4,
                title: 'Supplier',
                service_value: service.value,
                value: 'current_supplier',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 5,
                title: 'Contract End',
                service_value: service.value,
                value: 'current_supplier_end_at',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier_end_at.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 6,
                title: 'Reminder',
                service_value: service.value,
                value: 'reminder_date',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/reminder_date.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            // Summary
            {
                order: 7,
                title: 'Summary',
                service_value: service.value,
                value: 'summary',
                icon_path: service.icon_path,
                template: templatePath + '/summary.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
        ]
    }

    function getBroadbandSteps(service) {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/' + service.value;
        return [
            {
                order: 1,
                title: 'Broadband',
                service_value: service.value,
                value: 'new_or_reminder',
                icon_path: service.icon_path,
                template: templatePath + '/new_or_reminder.html',
                template_for_reminder: true,
                disabled: false,
                completed: false,
                errors: []
            },
            {
                order: 2,
                title: 'Provider',
                service_value: service.value,
                value: 'provider_selection',
                icon_path: service.icon_path,
                template: templatePath + '/provider_selection.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            // {
            //     order: 3,
            //     title: 'Quantity',
            //     service_value: service.value,
            //     value: 'quantity',
            //     icon_path: service.icon_path,
            //     template: templatePath + '/quantity.html',
            //     template_for_reminder: false,
            //     disabled: false,
            //     completed: false,
            //     errors: []
            // },
            // Reminders
            {
                order: 4,
                title: 'Current',
                service_value: service.value,
                value: 'check_current_supplier',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/check_current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 5,
                title: 'Supplier',
                service_value: service.value,
                value: 'current_supplier',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 6,
                title: 'Contract End',
                service_value: service.value,
                value: 'current_supplier_end_at',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/current_supplier_end_at.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            {
                order: 7,
                title: 'Reminder',
                service_value: service.value,
                value: 'reminder_date',
                icon_path: service.icon_path,
                template: templatePath + '/reminder/reminder_date.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            // Summary
            {
                order: 8,
                title: 'Summary',
                service_value: service.value,
                value: 'summary',
                icon_path: service.icon_path,
                template: templatePath + '/summary.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
        ];
    }

    function getTvLicenceSteps(service) {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/' + service.value;
        return [
            {
                order: 1,
                title: 'TV Licence',
                service_value: service.value,
                value: 'new_or_reminder',
                icon_path: service.icon_path,
                template: templatePath + '/new_or_reminder.html',
                template_for_reminder: true,
                disabled: false,
                completed: false,
                errors: []
            },
            {
                order: 2,
                title: 'Quantity',
                service_value: service.value,
                value: 'quantity',
                icon_path: service.icon_path,
                template: templatePath + '/quantity.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
            // Reminders
            // {
            //     order: 3,
            //     title: 'Current',
            //     service_value: service.value,
            //     value: 'check_current_supplier',
            //     icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
            //     template: templatePath + '/reminder/check_current_supplier.html',
            //     template_for_reminder: true,
            //     disabled: true,
            //     completed: false,
            //     errors: []
            // },
            // {
            //     order: 4,
            //     title: 'Supplier',
            //     service_value: service.value,
            //     value: 'current_supplier',
            //     icon_path: service.icon_path,
            //     template: templatePath + '/reminder/current_supplier.html',
            //     template_for_reminder: true,
            //     disabled: true,
            //     completed: false,
            //     errors: []
            // },
            // {
            //     order: 5,
            //     title: 'Contract End',
            //     service_value: service.value,
            //     value: 'current_supplier_end_at',
            //     icon_path: service.icon_path,
            //     template: templatePath + '/reminder/current_supplier_end_at.html',
            //     template_for_reminder: true,
            //     disabled: true,
            //     completed: false,
            //     errors: []
            // },
            {
                order: 6,
                title: 'Reminder',
                service_value: service.value,
                value: 'reminder_date',
                icon_path: 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
                template: templatePath + '/reminder/reminder_date.html',
                template_for_reminder: true,
                disabled: true,
                completed: false,
                errors: []
            },
            // Summary
            {
                order: 7,
                title: 'Summary',
                service_value: service.value,
                value: 'summary',
                icon_path: service.icon_path,
                template: templatePath + '/summary.html',
                template_for_reminder: false,
                disabled: false,
                completed: false,
                errors: []
            },
        ];
    }

    function getSummarySteps() {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/summary';
        return [
            {
                order: 999,
                title: 'Summary',
                service_value: undefined,
                value: 'final_summary',
                icon_path: null,
                template: templatePath + '/summary.html',
                template_for_reminder: true,
                disabled: false,
                completed: false,
                errors: []
            },
        ];
    }

    function getFallbackReminderSteps() {
        let templatePath = 'app/main/modules/customer/_property/_services/includes/wizard/steps/fallback_reminder';
        return [
            {
                title: 'Reminder',
                breadcrumb_label: 'Reminder',
                service_value: 'reminder',
                value: 'new_or_reminder',
                icon_path: '',
                template: templatePath + '/new_or_reminder.html',
                template_for_reminder: true,
                errors: [],
                completed: false
            },
            {
                title: 'Existing Supplier',
                service_value: 'reminder',
                value: 'supplier_type',
                icon_path: '',
                template: templatePath + '/supplier_type.html',
                template_for_reminder: false,
                errors: [],
                completed: false
            },
            {
                title: 'Reminder',
                service_value: 'reminder',
                value: 'reminder_date',
                icon_path: '',
                template: templatePath + '/reminder_date.html',
                template_for_reminder: false,
                errors: [],
                completed: false
            },
        ];
    }

    // Handlers --------------------------------------------------------------------------------------------------------

    //
    handlers.quoteOrReminderClick = (type) => {
        let isReminder = false;

        if (type === 'reminder') {
            isReminder = true;
        }

        let selectedService = $filter('filter')(self.selectedServices, function (service) {
            return self.activeWizard.value === service.value;
        })[0];
        selectedService.type = type;

        angular.forEach(self.activeWizard.steps, (step) => {
            if (step.template_for_reminder === true && (step.value !== 'new_or_reminder' && step.value !== 'final_summary' &&
            step.value !== 'current_supplier' && step.value !== 'current_supplier_end_at')) {
                step.disabled = !isReminder;
            } else if (step.template_for_reminder === false && (step.value !== 'new_or_reminder'&& step.value !== 'final_summary')) {
                step.disabled = isReminder;
                if ((step.value === 'prepay_type' || step.value === 'prepay_notice')) {
                    step.disabled = true;
                }
                if ((step.value === 'usage_electric' || step.value === 'usage_gas') && !self.form[step.service_value].meterType) {
                    step.disabled = true;
                }
            }
        });
    };
    handlers.updatePrices = () => {
        updatePrices(); // Uhh..
    };
    handlers.goToPaymentClick = () => { // ?
        self.goToPayment = true;
    };
    handlers.dateValidation = (dateObj) => {
        dateObj.validated = false; // validated
        if (dateObj.year && dateObj.month && dateObj.day) {
            let _date = dateObj.year + '-' + dateObj.month + '-' + dateObj.day;
            _date = moment.utc(_date, 'YYYY-MM-DD');
            if (!_date.isValid()) {
                dateObj.date = null;
                dateObj.error = "Not a valid date";
            }
            else if (_date.isValid() && _date.isBefore(moment.utc())) {
                dateObj.date = null;
                dateObj.error = "Must be greater than today";
            }
            else {
                dateObj.date = _date;
                dateObj.error = null;
                dateObj.validated = true;
            }
        }
    };
    handlers.skipService = (service) => {
        service.skipped = !service.skipped;
        updatePrices();
    }

    // Reminder
    handlers.reminderSupplierClick = (value, form) => {
        let disabled = true;
        if (value === 'yes') {
            disabled = false;
        }
        form.hasSupplier = value;
        angular.forEach(self.activeWizard.steps, (step) => {
            if (step.value === 'current_supplier' || step.value === 'current_supplier_end_at') {
                step.disabled = disabled;
            }
        });
    };

    // Energy Clicks
    handlers.energySupplyTypeClick = (type) => {
        self.isEnergyDualFuel = type.value === 'dual_fuel';

        if (self.isEnergyDualFuel) {
            angular.forEach(self.selectedServices, (selectedService) => {
                angular.forEach(selectedService.utility_types, (utilityType) => {
                    // enable all services really lazily
                    self.form[utilityType.value].disabled = false;
                });
            });
            // find Gas/Electric Usage steps
            angular.forEach(self.activeWizard.steps, (step) => {
                if (step.value === 'usage_gas' || step.value === 'usage_electric') {
                    step.disabled = false;
                }
            });
        } if (type.value === 'gas') {
            // Find Gas Usage step
            angular.forEach(self.activeWizard.steps, (step) => {
                if (step.value === 'usage_electric') {
                    step.disabled = true;
                } else if (step.value === 'usage_gas') {
                    step.disabled = false;
                }
            });
            self.form.electric.disabled = true;
            self.form.gas.disabled = false;
        } else if (type.value === 'electric') {
            // Find Electric Usage step
            angular.forEach(self.activeWizard.steps, (step) => {
                if (step.value === 'usage_electric') {
                    step.disabled = false;
                } else if (step.value === 'usage_gas') {
                    step.disabled = true;
                }
            });
            self.form.electric.disabled = false;
            self.form.gas.disabled = true;
        }

        self.form.energy.meterType = type.value;

        // Validate
        let step = {
            service_value: 'energy',
            value: 'meter_type',
        }
        validateStep(step);
    };
    handlers.energyMeterTypeClick = (type) => {
        self.form.energy.hasPrePayMeter = type.value;
        if (type.value !== 'prepay') {
            self.form.energy.prePayMeterType = undefined; // ?
            self.form.energy.meterType = undefined; // ?
        }
        // Validate
        let step = {
            service_value: 'energy',
            value: 'check_meter_type',
        }
        validateStep(step);
    }
    handlers.energyPrePayMeterTypeClick = (type) => {
        self.form.energy.prePayMeterType = type.value;

        if (type.value === 'dual_fuel') {
            angular.forEach(self.activeWizard.steps, (step) => {
                step.disabled = !(step.value === 'new_or_reminder' || step.value === 'check_meter_type'
                    || step.value === 'prepay_type' || step.value === 'prepay_notice'
                );
            });
        } else {
            if (type.value === 'gas') {
                self.form.electric.disabled = false;
                self.form.gas.disabled = true;
                self.form.energy.meterType = 'electric';
            } else if (type.value === 'electric') {
                self.form.electric.disabled = true;
                self.form.gas.disabled = false;
                self.form.energy.meterType = 'gas';
            }
            angular.forEach(self.activeWizard.steps, (step) => {
                if (step.value === 'prepay_notice') {
                    step.disabled = true;
                }
                if (step.value === 'usage_electric') {
                    step.disabled = self.form.electric.disabled;
                } else if (step.value === 'usage_gas') {
                    step.disabled = self.form.gas.disabled;
                }
                if (step.value === 'summary') {
                    step.disabled = false;
                }
            });
        }

        // Validate
        let step = {
            service_value: 'energy',
            value: 'prepay_type',
        }
        validateStep(step);
    }

    // Service Selection
    handlers.selectServices = () => {
        $timeout(() => {
            self.selectedServicesDisplay = false; // @todo fix
        });
    };
    handlers.selectServiceClick = (service) => {
        if (service) {
            let index = _.indexOf(self.selectedServices, service); // @todo fix
            service.selected = !service.selected;
            if (index === -1) {
                self.selectedServices.push(service);
            } else {
                self.selectedServices.splice(index, 1);
            }
        } else {
            let selected = false;
            if (!self.selectedServices.length) {
                selected = true;
            } else {
                self.selectedServices = [];
            }
            angular.forEach(self.options.services, (service) => {
                if (selected) {
                    self.selectedServices.push(service);
                }
                service.selected = selected;
            });
        }
    };
    handlers.selectServiceSubmit = () => {
        self.loading = true;
        self.loadingWizard = true;
        whenCreatingSteps()
            .then(() => {
                self.selectedServicesDisplay = true;
            }, (error) => {
                console.log(error)
            }).finally(() => {
                let nextService;
                for (let i = 0; i < self.selectedServices.length; ++i) {
                    nextService = $filter('filter')(self.selectedServices, function (service, key) {
                        if (!service.completed && !service.skipped) {
                            return service;
                        }
                    })[0];
                }
                $timeout(() => {
                    self.loading = false;
                    self.handlers.goToWizard(nextService);
                });
        });
    };
    handlers.summaryClick = () => {
        for (let index = 0; index < self.selectedServices.length; index++) {
            self.selectedServices[index].navSelected = false;
        }
        self.activeWizard.steps = [];
        self.activeWizard = {
            index: 0,
            value: 'summary',
            label: 'Summary',
            steps: getSummarySteps()
        };
        WizardHandler.wizard().reset();

        $timeout(() => {
            WizardHandler.wizard().reset();
            WizardHandler.wizard().goTo(0);
        })
    }

    // Wizard General
    handlers.previousClick = () => {
        WizardHandler.wizard()
            .previous();
    };
    handlers.continueClick = (isValid, step, form) => {
        step.errors = null;
        if (!isValid) {
            step.submitted = true;
            step.errors = [];

            for (let key in form.$error) {
                for (let index = 0; index < form.$error[key].length; index++) {
                    let errorField = form.$error[key][index].$name;
                    errorField = errorField.replace(/^\w/, c => c.toUpperCase());
                    step.errors.push(errorField + ' is required.');
                }
            }
            return;
        }

        if (validateStep(step, form)) {
            step.completed = true;
            let lastEnabledStep = getLastEnabledStep();
            if (step.value === lastEnabledStep.value) {
                let selectedService = $filter('filter')(self.selectedServices, function (service) {
                    return service.value === step.service_value;
                })[0];
                if (angular.isObject(selectedService)) {
                    selectedService.completed = true;
                    selectedService.skipped = false;
                }
                return handlers.submitClick();
            } else {
                $timeout(() => {
                    WizardHandler.wizard()
                        .next();
                });
            }
        }
    };
    handlers.skipClick = (completed = false) => {
        let selectedService = $filter('filter')(self.selectedServices, function (service) {
            return service.value === self.activeWizard.value;
        })[0];
        selectedService.completed = completed;
        selectedService.skipped = true;
        self.handlers.submitClick();
    };
    handlers.goToWizard = (service, skipCompleted = false, skipReset = false) => {
        // if (service.completed || skipCompleted) {} // stop skips
        self.allowWizardEditing = false;

        for (let index = 0; index < self.selectedServices.length; index++) {
            self.selectedServices[index].navSelected = false;
        }
        service.navSelected = true;
        self.loadingWizard = true;

        self.activeWizard.steps = [];
        self.activeWizard = {
            index: service.index,
            value: service.value,
            label: service.label,
            steps: service.steps
        };

        if (!skipReset) {
            if (angular.isDefined(WizardHandler.wizard())) {
                if (service.completed) {
                    self.allowWizardEditing = true;
                    WizardHandler.wizard().reset(); // reset after updating edit flag
                    let lastEnabledStep = getLastEnabledStep();
                    self.handlers.goToStep(lastEnabledStep.value);
                } else {
                    self.handlers.goToStep(0);
                }
            }
        }

        $timeout(() => { // ?
            self.loadingWizard = false;
        }, 600);
    };
    handlers.goToStep = (step) => {
        self.loadingWizard = false; // required
        WizardHandler.wizard().goTo(step);
    };
    handlers.wizardFinished = () => {
        let currentStep = WizardHandler.wizard().currentStep(),
            nextService,
            activeService;

        // self.loadingWizard = true;

        activeService = $filter('filter')(self.selectedServices, function (service) {
            return service.index === self.activeWizard.index;
        })[0];

        for (let i = 0; i < self.selectedServices.length; ++i) {
            if (self.selectedServices[i].index === activeService.index) {
                nextService = $filter('filter')(self.selectedServices, function (service, key) {
                    if (service.index === self.activeWizard.index) {
                        activeService.navSelected = false;
                    }
                    if (!service.completed && !service.skipped) {
                        return service;
                    }
                })[0];
            }
        }

        if (!nextService) {
            let incompleteServices = $filter('filter')(self.selectedServices, function (service, key) {
                return !service.completed && !service.skipped;
            });
            if (incompleteServices.length) {
                nextService = incompleteServices[0];
            }
        }

        updatePrices();

        if (nextService) {
            nextService.navSelected = true;
            self.activeWizard = {};
            self.activeWizard = {
                index: nextService.index,
                value: nextService.value,
                label: nextService.label,
                steps: nextService.steps
            };
        } else {
            currentStep.wzData.completed = true; // @todo
            for (let index = 0; index < self.selectedServices.length; index++) {
                self.selectedServices[index].navSelected = false;
            }
            self.activeWizard.steps = [];
            self.activeWizard = {
                index: 0,
                value: 'summary',
                label: 'Summary',
                steps: getSummarySteps()
            };
            WizardHandler.wizard().reset();
        }
        // $timeout(() => {
        //     self.loadingWizard = false;
        // }, 300);
    };
    handlers.showTariffClick = (value) => {
        let broadbandProductType = null;
        let utilityType = $filter('filter')(self.utilityTypes.available, (type) => {
            return type.utility_type.value === value;
        })[0];
        if (value === 'broadband') {
            broadbandProductType = $filter('filter')(self.options.broadbandProducts, (broadbandProduct) => {
                return broadbandProduct.id === self.form.broadband.productType;
            })[0];
        }
        PropertySvc.openTariffModal(self.property.address.postcode, self.property.room_count, utilityType.utility_type, broadbandProductType, self.rates);
    };

    // Submission
    handlers.submitClick = () => {
        let currentStep = WizardHandler.wizard().currentStep(),
            submission;

        if (currentStep.title !== 'final_summary') {
            WizardHandler.wizard().finish();
        } else {
            self.submitDisabled = true;
            submission = {
                property: {
                    id: self.propertyId
                },
                services: [],
                reminders: [],
            };

            angular.forEach(self.selectedServices, (selectedService) => {
                if (selectedService.completed) {
                    let form = self.form[selectedService.value];
                    angular.forEach(selectedService.utility_types, (utilityType) => {
                        if (!selectedService.skipped) {
                            if (form.type === 'quote') {
                                if (utilityType.value === 'electric' && (form.meterType === 'electric' || form.meterType === 'dual_fuel')) {
                                    submission.services.push({
                                        utility_type: utilityType,
                                        meta: {
                                            usage_type: self.form.electric.usageType,
                                            usage_kwh: self.form.electric.usageKwh,
                                            default_usage_kwh: self.form.electric.defaultUsageKwh,
                                        },
                                        quantity: 1,
                                        monthly_spend: self.form.electric.monthly_spend,
                                    });
                                }
                                if (utilityType.value === 'gas' && (form.meterType === 'gas' || form.meterType === 'dual_fuel')) {
                                    submission.services.push({
                                        utility_type: utilityType,
                                        meta: {
                                            usage_type: self.form.gas.usageType,
                                            usage_kwh: self.form.gas.usageKwh,
                                            default_usage_kwh: self.form.gas.defaultUsageKwh,
                                        },
                                        quantity: 1,
                                        monthly_spend: self.form.gas.monthly_spend,
                                    });
                                }
                                if (utilityType.value === 'water') {
                                    submission.services.push({
                                        utility_type: utilityType,
                                        meta: {
                                            usage_type: form.usageType,
                                        },
                                        quantity: 1,
                                        monthly_spend: form.monthly_spend,
                                    });
                                }
                                if (utilityType.value === 'tv_licence') {
                                    submission.services.push({
                                        utility_type: utilityType,
                                        quantity: form.quantity,
                                        monthly_spend: form.monthly_spend,
                                    });
                                }
                                if (utilityType.value === 'broadband') {
                                    submission.services.push({
                                        utility_type: utilityType,
                                        meta: {
                                            product_type: {
                                                id: form.productType
                                            },
                                        },
                                        quantity: form.quantity,
                                        monthly_spend: form.monthly_spend,
                                    });
                                }
                            }
                            if (form.type === 'reminder') {
                                // Simple Reminder
                                submission.reminders.push({
                                    utility_type: utilityType,
                                    remind_at: (form.reminderDate.date ? form.reminderDate.date.toISOString() : null),
                                    has_supplier: (form.hasSupplier ? 'yes' : 'no'),
                                    current_supplier: (form.currentSupplier ? form.currentSupplier : null),
                                    submitted_eac: '',
                                    contract_end_at: (_.isEmpty(form.contractEndAt) ? null : form.contractEndAt.date.toISOString()),
                                });
                            }
                        }
                    });
                }
            });

            PropertySvc.saveServices(submission)
                .then((response) => {
                    if (self.goToPayment) {
                        $state.go('main.customer.account-payment', {skipSummary: true});
                    } else {
                        $state.go('main.customer.property.overview', {
                            propertyId: $state.params.propertyId
                        });
                    }
                }, (errors) => {
                    console.log(errors);
                    self.submitDisabled = false;
                });
        }
    };
}
