angular.module('app.main.shared')
	.factory('StatementSvc', function (RequestSvc) {

		function index(params) {
			return RequestSvc.get('api/admin/statement/index', params);
		}

		return {
			index: index,
		};

	});
