angular.module('app.main.public.auth')
	.controller('SignupCtrl', SignupCtrl);

SignupCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$localStorage',
	'AuthSvc',
	'RegisterSvc',
	'vcRecaptchaService',
];

function SignupCtrl($scope, $state, $q, $localStorage, AuthSvc, RegisterSvc, vcRecaptchaService) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
		form: {},
	});

	this.$onInit = () => {
		self.loading = true;
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function (isValid) {
		if (isValid) {
			if(vcRecaptchaService.getResponse() === ""){ //if string is empty
				alert("Please resolve the captcha and submit");
			}else {
				const param = self.form;
				angular.extend(param, {
					'g-recaptcha-response' : vcRecaptchaService.getResponse(),
				});

				return RegisterSvc.register(param).then((response) => {
					AuthSvc.loginWithCredentials(param).then(() => {
						$state.go('main.customer.dashboard.index'); // v2
					});
					// $state.go('main.customer.property.index'); // v1
				},(errors) => {
					self.formErrors = errors;
				});
			}
		}
		else {
			self.form.submitted = true;
		}
	};

	handlers.loginWithGoogle = () => {
		window.location.href = 'http://mango-api.localhost/api/login-with-google';
	}
}
