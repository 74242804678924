angular.module('app.main.shared')
	.service('TaxHelperSvc', [
		function () {

			var vatFromNet = function (net, taxRate) {
				taxRate = parseFloat(taxRate);
				var vat = parseFloat(net) * taxRate / 100;
				return Math.round(vat * 10000) / 10000;
			};

			var grossFromNet = function (net, taxRate) {
				var gross = parseFloat(net) + parseFloat(vatFromNet(net, taxRate));
				return Math.round(gross * 10000) / 10000;
			};

			var vatFromGross = function (gross, taxRate) {
				taxRate = parseFloat(taxRate);
				var vat = parseFloat(gross) / (100 + taxRate) * taxRate;
				return Math.round(vat * 10000) / 10000;
			};

			var netFromGross = function (gross, taxRate) {
				taxRate = parseFloat(taxRate);
				var net = (parseFloat(gross) / (100 + taxRate)) * 100;
				return Math.round(net * 10000) / 10000;
			};

			return {
				vatFromNet: vatFromNet,
				grossFromNet: grossFromNet,
				vatFromGross: vatFromGross,
				netFromGross: netFromGross
			}

		}
	]);
