angular.module('app.main.customer')
	.controller('AccountAddModalCtrl', AccountAddModalCtrl);

AccountAddModalCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'focus',
	'$timeout',
	'notify',
	'$mdDialog',
	'AccountSvc',
	'PropertyTypeSvc',
];

function AccountAddModalCtrl(
	$scope,
	$state,
	$q,
	focus,
	$timeout,
	notify,
	$mdDialog,
	AccountSvc,
	PropertyTypeSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		options: {},
		form: {
			account: {
				address: {},
			},
		},
	});

	self.init = () => {
		PropertyTypeSvc.options().then((response) => {
			self.options.propertyTypes = response.data.options;
			self.loading = false;
		});
		$timeout(() => {
			focus('label');
		}, 750);
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.propertyTypeSelected = (propertyType) => {
		if (propertyType.enabled) {
			self.form.property_type = propertyType;
		}
	};

	handlers.submitClick = (isValid) => {
		if (isValid) {
			return AccountSvc.save(self.form).then((response) => {
				notify({
					message: 'Saved!',
				});
				$mdDialog.hide(response.data.group);
			});
		}
		else {
			self.formSubmitted = true;
		}
	};

	handlers.cancelClick = () => {
		$mdDialog.cancel();
	}
}
