angular.module('app.main.shared')
	.factory('UtilityEacAverageSvc', function (RequestSvc) {

		function index(params) {
			return RequestSvc.get('api/admin/utility/eac/index', params);
		}

		function save(params) {
			return RequestSvc.post('api/admin/utility/eac/save', params);
		}

		return {
			index: index,
			save: save,
		};

	});
