angular.module('app.main.customer')
	.controller('ProfilePicModalCtrl', [
		'$log',
		'$state',
		'$scope',
		'$timeout',
		'$mdDialog',
		'params',
		function ($log,
				  $state,
				  $scope,
				  $timeout,
				  $mdDialog,
				  params) {

			var self = this;

			self.methods = {
				init: init,
				cancel: cancel,
				confirm: confirm
			};

			function init() {
				self.type = params.type;
				$timeout(function(){
					$scope.myImage = '';
					$scope.profileImage = '';
					var handleFileSelect = function (evt) {
						var file = evt.currentTarget.files[0];
						var reader = new FileReader();
						reader.onload = function (evt) {
							$scope.$apply(function ($scope) {
								$scope.myImage = evt.target.result;
							});
						};
						reader.readAsDataURL(file);
						self.imageSelected = true;
					};
					angular.element(document.querySelector('#fileInput'))
						.on('change', handleFileSelect);

				},500);
			}

			function cancel() {
				$mdDialog.cancel();
			}

			function confirm() {
				$mdDialog.hide($scope.profileImage);
			}



		}
	]);
