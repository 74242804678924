angular.module('app.main')
	.controller('NavBarCtrl', NavBarCtrl);

NavBarCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$localStorage',
	'AuthSvc',
];

function NavBarCtrl($scope, $state, $q, $localStorage, AuthSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	this.$onInit = () => {
		if (AuthSvc.isAuthenticated()) {
			self.authenticated = true;
		}
		else {
			self.authenticated = false;
		}

	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.loginClick = () => {
		if (self.authenticated) {
			AuthSvc.redirect();
		}
		else {
			$state.go('main.public.auth.signup');
		}
	};

	handlers.signupClick = () => {
		delete $localStorage.account;
		$state.go('main.customer.property.build');
	};

	handlers.submitClick = function () {

	};
}