angular.module('app.main.shared.utility')
	.factory('UtilityAccountGroupSvc', [
		'RequestSvc',
		function (RequestSvc) {

			function index(params) {
				return RequestSvc.get('api/admin/account-group/index', params);
			}

			function options(params) {
				return RequestSvc.get('api/admin/account-group/options', params);
			}

			function save(account) {
				return RequestSvc.post('api/admin/account-group/save', account);
			}

			function read(id) {
				return RequestSvc.get('api/admin/account-group/read/' + id);
			}

			return {
				index: index,
				options: options,
				save: save,
				read: read
			};

		}
	]);
