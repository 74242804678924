angular.module('app.main.shared')
	.factory('UtilityJobTypeSvc', [
		'RequestSvc',
		function (RequestSvc) {

			function options(params) {
				return RequestSvc.get('api/utility/jobType/options', params);
			}

			return {
				options: options
			};

		}
	]);
