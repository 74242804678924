angular.module('app.main.shared')
	.factory('UtilityBillSvc', [
		'$filter',
		'RequestSvc',
		'UtilityBillItemSvc',
		'UtilityReadingSvc',
		'$mdDialog',
		function ($filter,
				  RequestSvc,
				  UtilityBillItemSvc,
				  UtilityReadingSvc,
				  $mdDialog) {

			function uploadBills(bills) {
				return RequestSvc.post('api/admin/utility/bill/upload-bills', bills);
			}

			function transformBill(bill) {
				console.info('transform bill');
				if (angular.isObject(bill)) {
					if (angular.isString(bill.date)) {
						bill.date = bill.date ? moment(bill.date) : null;
					}
					if (angular.isString(bill.billed_from)) {
						bill.billed_from = bill.billed_from ? moment(bill.billed_from) : null;
					}
					if (angular.isString(bill.billed_to)) {
						bill.billed_to = bill.billed_to ? moment(bill.billed_to) : null;
					}
					if (angular.isArray(bill.bill_contracts)) {
						angular.forEach(bill.bill_contracts, function (billContract) {
							angular.forEach(billContract.charges, function (charge, key) {
								billContract.charges[key] = UtilityBillItemSvc.transformBillItem(charge);
							});
							angular.forEach(billContract.readings, function (reading, key) {
								billContract.readings[key] = UtilityReadingSvc.transformReading(reading);
							});
						});
					}
					// // transform bill items
					// angular.forEach(bill.items, function (billItem) {
					// 	UtilityBillItemSvc.transformBillItem(billItem);
					// });
					// // transform readings
					// angular.forEach(bill.readings, function (reading) {
					// 	UtilityReadingSvc.transformReading(reading);
					// });
					// attach contracts, bill items, meter readings to relevant supplier utilities
					// angular.forEach(bill.supplier_utility_types, function (supplierUtilityType) {
					// 	supplierUtilityType.contracts = supplierUtilityType.contracts || [];
					// 	angular.forEach(bill.accounts, function (account) {
					// 		angular.forEach(account.contracts, function (contract) {
					// 			// attach bill items to contract
					// 			contract.bill_items = $filter('filter')(bill.items, {contract: {id: contract.id}}, true);
					// 			// attach meter readings to contract meter
					// 			contract.meter.readings = $filter('filter')(bill.readings, {meter: {id: contract.meter.id}}, true);
					// 			// attach contract to utility type
					// 			if (contract.account_utility_types[0].supplier_utility_type.id == supplierUtilityType.id) {
					// 				supplierUtilityType.contracts.push(contract);
					// 			}
					// 		});
					// 	});
					// });
				}
				console.info(bill);
				return bill;
			}

			return {
				uploadBills: uploadBills,
				index: function (params) {
					return RequestSvc.get('api/admin/bill/index', params);
				},
				save: function (bill) { // NOT SURE IF USED
					return RequestSvc.post('api/admin/utility/bill/save', bill, function (value) {
						if (angular.isObject(value.bill)) {
							value.bill = transformBill(value.bill);
						}
						return value;
					});
				},
				saveMany: function (bills) {
					return RequestSvc.post('api/admin/utility/bill/save-many', bills, function (value) {
						if (angular.isArray(value.bill_collection)) {
							angular.forEach(value.bill_collection, function (bill, key) {
								value.bill_collection[key] = transformBill(bill);
							});
						}
						return value;
					})
				},
				read: function (id, params) {
					return RequestSvc.get('api/admin/bill/read/' + id, params, function (value) {
						if (angular.isObject(value.bill)) {
							value.bill = transformBill(value.bill);
						}
						return value;
					});
				},
				remove: function(params) {
					return RequestSvc.post('api/admin/bill/remove', params);
				},
				transformBill: transformBill,
				verifyBills: (params) => {
					return RequestSvc.post('api/admin/utility/bill/verify-bills', params);
				},
				import: {
					index: (params) => {
						return RequestSvc.get('api/admin/bill/import/index', params);
					},
					processBills: (params) => {
						return RequestSvc.post('api/admin/bill/import/process-bills', params);
					},
					revertBillImport: (params) => {
						return RequestSvc.post('api/admin/bill/import/revert', params);
					},
					read: (params) => {
						return RequestSvc.get('api/admin/bill/import/read', params);
					},
					editImportRowModal: (row, utilityType) => {
						return $mdDialog.show({
							templateUrl: 'app/main/modules/admin/modules/utility/templates/BillImportEditModal.html',
							controller: 'BillImportEditModalCtrl',
							controllerAs: 'BillEdit',
							locals: {
								bill: row,
								utilityType: utilityType,
							},
							preserveScope: true,
							clickOutsideToClose: false,
							escapeToClose: true
						});
					},
					saveImportRow: (row) => {
						return RequestSvc.post('api/admin/bill/import/save-row', row);
					},
					rejectBill: (params) => {
						return RequestSvc.post('api/admin/bill/import/reject-bill-import-row', params);
					},
					updateRowQueryStatus: (params) => {
						return RequestSvc.post('api/admin/bill/import/update-query-status', params);
					},
				},
			};

		}
	]);
