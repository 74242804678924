angular.module('app')
	.factory('$global', [
		function () {
			return {
				config: {
					format: {
						date: {
							// Simple formats

							// = 1969-12-31 23:59:59
							amDbDateTime: 'YYYY-MM-DD HH:mm:ss',

							// = 1969-12-31
							amDbDate: 'YYYY-MM-DD',

							// = 23:59:59
							amDbTime: 'HH:mm:ss',

							// Short formats

							// = Thu 12 Jul 2018 5:06 PM
							amShortDateTime: 'ddd D MMM YYYY h:mm A',

							// = 31/12/1969
							amShortDate: 'DD/MM/YYYY',

							amShortDate2: 'D MMM YY',

							amShortDay: 'ddd',

							// = 11:59 PM
							amShortTime: 'H:mm A',

							// = 23:59
							amShortTime24: 'HH:mm',

							// Medium formats

							// = Wed 31 Dec 1969
							amMediumDate: 'ddd Do MMM YYYY',

							// = 31st Dec, 1969
							amMediumDate2: 'Do MMM YYYY',

							// = Wed 31 Dec 1969 11:59
							amMediumDateTime: 'ddd D MMM YYYY HH:mm',

							// Full formats

							// = Wednesday, 31 December, 1969 11:59:59 PM
							amFullDateTime: 'dddd, Do MMMM, YYYY HH:mm:ss A',

							// = Wednesday, 31 December, 1969
							amFullDate: 'dddd, Do MMMM, YYYY',

							// = Wednesday, 31 December
							amFullDateNoYear: 'dddd, Do MMMM',

							// = December 31st 1969
							amNaturalDate: 'MMMM Do YYYY'
						}
					},
					datePicker: {
						placeholder: 'Enter date',
						basic: {
							storageFormat: 'YYYY-MM-DD',
							'options': {
								format: 'Do MMMM YYYY',
								singleDatePicker: true,
								showDropdowns: true,
								showWeekNumbers: true,
								opens: 'right',
								drops: 'down',
								parentEl: '#content-wrapper'
							},
							'eventHandlers': {}
						},
						range: {
							//storageFormat: 'YYYY-MM-DD',
							//'options': {
							//	format: 'Do MMMM YYYY',
							//	showDropdowns: false,
							//	showWeekNumbers: true,
							//	ranges: {
							//		'Today': [moment(), moment()],
							//		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
							//		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
							//		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
							//		'This Month': [moment().startOf('month'), moment().endOf('month')],
							//		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
							//		'Last 3 Months': [moment().subtract(3, 'month').endOf('month'), moment()],
							//		'Last Year': [moment().subtract(12, 'month').endOf('month'), moment()]
							//	},
							//	opens: 'right',
							//	drops: 'down',
							//	separator: ' - ',
							//	parentEl: '#work-container .content-scroll'
							//},
							'eventHandlers': {}
						}
					},
					dateTimePicker: {
						format: 'DD/MM/YYYY hh:mm'
					},
					mdTable: {
						limits: [20, 50, 100, 500],
						pageSize: 20
					}
				}
			};
		}
	])
;
