angular.module('app.main.customer.property', [
	'app.main.customer.property.overview',
	'app.main.customer.property.reads',
	// 'app.main.customer.property.services',
])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.customer.property', {
				url: '^/property',
				abstract: true,
				data: {
					checkLogin: true,
				}
			})

			.state('main.customer.property.add', {
				url: '/add',
				params: {
					redirectTo: undefined,
				},
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_property/_add/PropertyAdd.html',
						controller: 'PropertyAddCtrl',
						controllerAs: 'PropertyAdd',
					}
				},
				ncyBreadcrumb: {
					label: 'Add a property'
				},
				data: {
					isWizardPage: true,
				}
			})

			.state('main.customer.property.index', {
				url: '/list',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_property/_list/PropertyIndex.html',
						controller: 'PropertyIndexCtrl',
						controllerAs: 'Property',
					}
				},
				ncyBreadcrumb: {
					label: 'Properties'
				},

			})

			.state('main.customer.property.refer', {
				url: '/refer?pc&no&size&code',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_property/_add/referral/Referral.html',
						controller: 'ReferralCtrl',
						controllerAs: 'Referral',
					}
				},
				ncyBreadcrumb: {
					label: 'Referral..'
				},
				data: {
					checkLogin: false,
				}
			})

		;

	}]);
