angular.module('app.main').factory('ConfirmationDialog', function ($mdDialog) {

	function show(title, subTitle, cancelText, submitText) {
		let submitLabel = submitText;
		if (submitText.label !== undefined) {
			submitLabel = submitText.label;
		}
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		return $mdDialog.show({
			controller: 'ConfirmationDialogCtrl',
			templateUrl: 'app/main/directives/confirmationDialog/Dialog.html',
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			locals: {
				data: {
					title: title,
					subTitle: subTitle,
					cancelText: cancelText,
					submitText: submitLabel,
					submitActionType: submitText.actionType || 'positive',
				},
				submitCallback: submitText.callback || null,
			}
		});
	}

	return {
		show: show
	};

});
