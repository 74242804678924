angular.module('app.main.public')
	.controller('ComplaintsPolicyCtrl', [
		'$log',
		'$scope',
		'$state',
		'$window',
		'$document',
		'$timeout',
		function ($log,
				  $scope,
				  $state,
				  $window,
				  $document,
				  $timeout) {


			let self = this;
			let handlers = {};

			angular.extend(self, {
				handlers: handlers,
				body: [
					{
						label: 'Policy Statement',
						body: "The purpose of this policy is to provide a clear statement of intent with regards to the assessment, handling and investigation of customer complaints at Mango. Our complaint handling policy and procedure has been created to meet general standards and requirements and complies with standard compliant handling procedures.",
						sub: [
							{
								body: "The aim of this policy is to ensure that all customer complaints, written and verbal, are handled in a consistent and regulated manner and that further complaints are mitigated and, where possible, prevented. We shall always do everything we can to ensure customers are happy, but should a customer have cause to complain then the company’s complaints handling procedure will be followed in every instance and a record made of the complaint and its details to help (1) the customer, and (2) ensure that our high standards are maintained and, where necessary, improved upon."
							}
						]
					},
					{
						label: 'Purpose',
						body: "",
						sub: [
							{
								body: "Mango is committed to delivering a fair, open and clear process for complaints, to ensure a satisfactory outcome for all customers raising a complaint. We provide thorough staff training in our internal complaint handling procedures and we support our staff in how best to handle complaint situations, whether in writing or by telephone.",

							},
							{
								body: "This policy sets out our intentions and objectives for how we handle complaints, from offering a clear and approachable system for customers, through to conducting root cause analysis on all complaints received to enable the identification of causes and the provision of solutions.",
							}
						]
					},
					{
						label: 'Scope',
						body: "",
						sub: [
							{
								body: "The policy relates to all staff (permanent, fixed term, and temporary),as well as third- party representatives, sub-contractors, agency workers and agents engaged with Mango, and within (or on behalf of) the organisation and has been created to ensure that staff deal with the area that this policy relates to in accordance with legal, regulatory, contractual and business expectations and requirements.",

							},
						]
					},
					{
						label: 'Objectives',
						body: "",
						sub: [
							{
								body: "Mango’s objectives are laid out below regarding customer complaint handling. For the purposes of this policy, a complaint is defined as any customer contact whereby a negative communication or outcome has occurred. The customer does not have to address their communication as an official complaint or to request a response for Mango to treat the incident as a complaint and to follow the related procedures.",
								sub: [
									{
										body: 'Mango’s objectives for internal complaint handling are',
										sub: [
											{
												body: "to provide a fair complaints procedure which is clear and easy to use for anyone wishing to make a complaint;",
											},
											{
												body: "to ensure that our complaints procedure is fully accessible so that people know how to contact us to make a complaint;",
											},
											{
												body: "to ensure everyone at Mango knows what to do if a complaint is received;",
											},
											{
												body: "to ensure all complaints are investigated fairly and in a timely way;",
											},
											{
												body: "to gather information which helps us to improve what we do and how we do it;",
											},
											{
												body: "to ensure that the Data Protection Officer (or appointed person) is involved in any complaints relating to personal data.",
											},
										]
									},
									{
										body: "Mango’s objectives for the complaint handling process are that:",
										sub: [
											{
												body: "complaints will be investigated and responded to within 8 weeks from the initial customer contact;",
											},
											{
												body: "customers will be sent a copy of the formal complaints procedure along with any relevant materials;",
											},
											{
												body: "complaint responses will always be provided in writing;",
											},
											{
												body: "complaint procedures and forms will be available via the company website as well as upon written and / or verbal request;",
											},
											{
												body: "all complaints will be investigated by a trained member of staff and a full outcome summary provided to Senior Management;",
											},
											{
												body: "complaint records will be used to revise company procedures and to improve communication and business practices where applicable;",
											},
											{
												body: "complainants are advised of their rights and provided with any relevant right to refer / lodge the complaint and the applicable contact details;",
											},
										]
									}
								]
							},

						]
					},
					{
						label: 'Raising a Complaint',
						sub: [
							{
								body: "Customers who request Mango’s complaint handling procedure will be provided a copy of the procedure and form either by email, in a .pdf format or in the post, and will be asked to raise their complaint in writing as soon as possible after the incident.",
							},
							{
								body: "Complaints are to be raised in writing; however, verbal complaints will be accepted and dealt with as per the same procedures.",
							},
							{
								body: "If a customer telephones Mango and wishes to raise a complaint then they should be passed through to a senior member of staff who will try to resolve the complaint there and then.",
							},
							{
								body: "Even if the complaint is resolved at the time, the customer must still be offered the option of receiving the complaints handling procedure and form prior to ending the call and the call recording must be retained and logged in the complaints record.",
							},
						]
					},
					{
						label: 'Data Protection Related Complaints',
						sub: [
							{
								body: "Where a complaint is related to the processing of personal data, this policy ensures that Mango complies with the data protection laws and notification requirements.",
							},
							{
								body: "Every individual has the right to lodge a complaint with the supervisory authority (ICO) where they consider that the processing of personal data relating to them has infringed the General Data Protection Regulation (GDPR), or we have breached data protection law. Any individual using our products or services and those employed by us are notified of this right via our Privacy Notice, in our complaint handling procedures and in our information disclosures.",
							},
							{
								body: "The supervisory authority with which the complaint has been lodged, is responsible for informing the complainant on the progress and the outcome of the complaint, including the possibility of a judicial remedy where the supervisory authority does not handle a complaint or does not inform the data subject within three months on the progress or outcome of the complaint lodged.",
							},
						]
					},
					{
						label: 'Informal Complaint Resolution',
						sub: [
							{
								body: "Mango considers and responds to all complaints and issues, no matter how they are raised or what they refer to. Some issues and complaints we can resolve immediately or within a 5-working day time-frame and are referred to as “informal complaints”. Such instances are where an investigation is not required because the nature of the complaint is clear, and a resolution can be obtained without further review of the facts. Where we resolve a complaint within the timeframe, the details are still logged on our complaint register, and the complainant is still informed of their rights.",
							},
							{
								body: "Mango takes every opportunity to resolve complaints at the first initial point of contact where feasible and possible. Informal resolution is always attempted where the issues raised are straightforward and potentially easily resolved, requiring little or no investigation. Most face to face and telephone issues can be resolved in this manner; however, the complainant is always offered the option of making the complaint formal if the resolution is not to their satisfaction.",
							},
							{
								body: "Where an informal complaint is received, it is acceptable for the point of contact or addressed employee to attempt to resolve the issue without involving the Complaints Officer. However, any issue relating to data protection infringes or breaches, no matter how small or informal, are always brought to the attention of the Company’s Data Protection Officer or appointed person.",
							},
							{
								body: "Frontline staff are trained to deal with basic issues and informal complaint resolution and are aware of their obligations and the subsequent reporting lines. Such employees are equipped to attempt to resolve a complaint relevant to their area of service or expertise, wherever possible.",
							},
						]
					},
					{
						label: 'Timeframe for Informal Resolution',
						sub: [
							{
								body: "It is the aim of Mango to resolve informal complaints immediately, or at least within the first 24-hours. Such complaints and issues will have a quick, but informative response and do not need to have an investigation or enter the formal complaint process.",
							},
							{
								body: "No matter how small or informal the complaint, if a satisfactory resolution has not been achieved within 5 working days of the complaint being raised or identified, the issue will be passed to the Complaints Officer to enter the formal complaint process.",
							},
						]
					},
					{
						label: 'Responding to a Complaint',
						sub: [
							{
								body: "Where an official complaint has been received or the informal complaint was unable to be resolved at the frontline point of contact, a written acknowledgement is sent to the customer within 3 working days. The response should detail the complaint handling procedure and provide approximate timelines and expectations for the investigation and future responses.",
							},
							{
								body: "A trained manager, the Data Protection Officer or the Complaints Officer are the only staff members who should respond to customers regarding their complaints.",
							},
						]
					},
					{
						label: 'Investigating the Complaint',
						sub: [
							{
								body: "The designated employee will be assigned the role of investigating complaints and will gather all necessary documents, recordings and information to make an independent review of the incident.",
							},
							{
								body: "If internal interviews are to be conducted, a note taker will be present alongside the investigator and interviewee and a copy of the interview notes will be written up and signed by the interviewer and interviewee prior to them being added to the complaint history.",
							},
							{
								body: "All investigations must take place with 6-weeks of the initial complaint being received so that a final response (decision letter) can be sent to the customer within our designated 8-week period.",
							},
							{
								body: "Investigations must utilise all the facts and any previous, related information to produce an unbiased outcome and an expected course of action. A complaint reference should be assigned and all documents relevant to the complaint should have the reference written on them for continuity.",
							},
							{
								body: "The reference will also be added to the Complaints Register so that complaint and documents can be audited and traced back in the future.",
							},
							{
								body: "All employees are provided with clear guidelines of when a complaint is formal and requires an appropriate investigation.",
							},
							{
								body: "Complaints must be referred to the Data Protection Officer where:",
								sub: [
									{
										body: "the complainant has requested such a referral or investigation;",
									},
									{
										body: "the complaint involves any type of personal data issue;",
									},
									{
										body: "the informal complaint resolution stage failed or was inappropriate;",
									},
									{
										body: "there is a conflict of interest between the complainant and an employee;",
									},
									{
										body: "the issues are complex and require an investigation;",
									},
									{
										body: "the complaint represents a high or serious risk to the company;",
									},
									{
										body: "the facts are unclear, or the complaint will require additional time to resolve;",
									},
									{
										body: "the complainant is identified as being vulnerable;",
									},
									{
										body: "there has been any media contact or attention;",
									},
									{
										body: "the issues do or may affect more customers (whether identified or not).",
									}
								]
							},
						]
					},
					{
						body: "Decision Letter (Final Response)",
						sub: [
							{
								body: "After the complaint has been investigated in full and an outcome and action decision has been reached, the investigator or Complaints Officer will draft a final response letter to the customer with their findings and decision regarding any action(s) to be taken or compensation awarded.",
							},
							{
								body: "The final response must be sent within 8 weeks of the initial response being raised and will also specify the complainant’s right to refer or lodge the complaint with the appropriate body (where applicable) should the customer be unhappy with the decision received.",
							},
							{
								body: "For complaints related to personal data and / or breaches of the data protection laws and regulations, the final response will reiterate the complainant’s right to lodge a complaint with the supervisory authority (the Information Commissioners Office) and will detail the ICO’s telephone number and address, along with the possibility of seeking a judicial remedy.",
							},
						]
					},
					{
						body: "Complaint Recording",
						sub: [
							{
								body: "All complaints, whether formal or informal, are recorded on a Customer Complaint Register. The register should consist of the following information, and should be audited on a frequent basis to ensure that incidents are not being repeated and improvements are being made:",
								sub: [
									{
										body: "nature of complaint;",
									},
									{
										body: "department(s) Involved;",
									},
									{
										body: "complaint reference;",
									},
									{
										body: "lead Investigator;",
									},
									{
										body: "decision letter sent (Y / N?);",
									},
									{
										body: "date complaint closed.",
									},
								]
							},
							{
								body: "The log is made available to any relevant authority, ombudsman or body who relates or oversee the firms’ complaints, as well as being made available with the local Trading Standards should a representative work alongside the organisation.",
							}
						]
					},
					{
						body: "Patterns and Analysis",
						sub: [
							{
								body: "The complaint log and issues forms are reviewed monthly by the appointed person to identify any patterns or reoccurring issues. Mango is dedicated to improving our performance, services and functions through the auditing of our complaint records and our investigation process. Where gaps or patterns are identified, we put corrective actions and mitigating solutions into place as soon as possible and keep the function, process or person under a weekly review until a satisfactory improvement is noted.",
							},
						]
					},
					{
						body: "Responsibilities",
						sub: [
							{
								body: "Mango will ensure that all staff are provided with the time, resources and support to learn, understand and deal with customer complaints and that full training will be provided for new and existing employees on the complaint handling policy, procedures and expectations. The Compliance Officer or a dedicated Complaints Officer will be appointed the role of overseeing, investigating and recording all customer complaints and is responsible for regular auditing of the complaints log to ensure mitigating actions and improvements are put into place where possible.",
							},
						]
					},
					{
						body: "The Complaints Officer",
						sub: [
							{
								body: "Mango has an appointed Complaints Officer who is provided with the training and support to understand, investigate and respond to complaints of all types. Where the complaint involves personal data, the Complaints Officer is assisted by the Data Protection Officer / Appointed Person to ensure that the Regulation and laws are followed, and the individuals’ rights are exercised and complied with.",
							},
							{
								body: "The Complaints Officer can complete their complaint handling duties independently and without bias and each complaint is reviewed to ensure that there is no conflict of interest with the appointed officer. If a conflict is identified (i.e., the complaint involves the investigating person) a deputy has been trained to handle the complaint. The Complaints Officer has full authority and internal credibility to ask questions, carry out investigations and interviews, obtain and analyse evidence, recommend policy and process changes and be involved in department functions for the purposes of complaint handling.",
							}
						]
					},
				]
			});

			handlers.init = () => {
				if (angular.isDefined($state.params.cookies)) {
					$timeout(() => {
						handlers.goToCookies();
					},800);
				}
			};

			handlers.goToCookies = () => {
				let someElement = angular.element(document.getElementById('cookies'));
				let $element = angular.element('html');
				$element.scrollToElement(someElement, 100, 750);
			};

			setIndex(self.body);

			function setIndex(items, prefix) {
				let index = 1;
				angular.forEach(items, (item) => {
					item.index = (angular.isDefined(prefix) ? prefix : '') + index++;
					if (angular.isArray(item.sub)) {
						setIndex(item.sub, item.index + '.');
					}
				});
			}

		}




	]);

