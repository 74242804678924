angular.module('app.main.customer')
	.factory('MeterReadSvc', function (RequestSvc, $mdDialog, PanelSvc) {

		function meterOptions(params) {
			return RequestSvc.get('api/customer/reading/options', params);
		}

		function save(params) {
			return RequestSvc.post('api/customer/reading/save', params);
		}

		function check(params) {
			return RequestSvc.post('api/customer/reading/check', params);
		}

		return {
			save: save,
			meterOptions: meterOptions,
			check: check,
		};

	});
