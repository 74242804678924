angular.module('app.main.public.auth')
	.controller('LoginModalCtrl', [
		'$log',
		'$scope',
		'mdPanelRef',
		'panelState',
		'$timeout',
		function ($log,
				  $scope,
				  mdPanelRef,
				  panelState,
				  $timeout) {

			function signup() {
				panelState.closeMethod = 'signup';
				mdPanelRef.close();
			}

			function close() {
				mdPanelRef.close();
			}

			var self = {
				methods: {
					signup: signup,
					close: close
				}
			};

			$scope.loginModalCtrl = self;


		}
	]);