angular.module('app.main.customer')
	.factory('MeterReadTypeSvc', function (RequestSvc) {

		function options(params) {
			return RequestSvc.get('api/customer/reading/type/options', params);
		}

		return {
			options: options,
		};

	});
