angular.module('app.main')
	.controller('MainCtrl', [
		'$window',
		'$log',
		'$scope',
		'$mdPanel',
		'$rootScope',
		'$state',
		'$stateParams',
		'$location',
		'$timeout',
		'AuthSvc',
		'notify',
		'$mdDialog',
		'$interval',
		'Angularytics',
		'$mdSidenav',
		'$localStorage',
		function ($window,
				  $log,
				  $scope,
				  $mdPanel,
				  $rootScope,
				  $state,
				  $stateParams,
				  $location,
				  $timeout,
				  AuthSvc,
				  notify,
				  $mdDialog,
				  $interval,
				  Angularytics,
				  $mdSidenav,
				  $localStorage) {

			const idleTimeoutInSeconds = 14000; // constant (just under the laravel session)
			let idleTimer = null;
			let timeoutDialogOpen = false;
			let lastRequestDateTime = null;

			$timeout(() => {
				const trustbox = document.getElementById('trustbox');
				if (trustbox) {
					window.Trustpilot.loadFromElement(trustbox);
				}
			}, 500);

			let self = $scope.MainCtrl = {
				currentStep: 1,
				heroImages: {
					image1: 'images/v3/hero-image-bad.png',
					image2: 'images/v3/hero-image-good.png',
				},
				nav: {
					visible: false,
					toggle: function () {
						self.nav.visible = !self.nav.visible;
					}
				},
				methods: {
					logout: logout,
					isAuthenticated: isAuthenticated,
					openProfileMenu: openProfileMenu,
					loginClick: loginClick,
					signupClick: signupClick,
					buildPackageClick: buildPackageClick,
					compareClick: compareClick,
				}
			};

			self.showMainLogo = $state.current.data.showLogo !== false;
			if ($state.current.data.navThemeColour === 'dark') {
				self.navDarkTheme = true;
			}

			$rootScope.$on('$stateChangeStart',
				function (event, toState, toParams, fromState, fromParams) {
					if (angular.isObject(toState.data) && toState.data.navBarTheme === 'dark') {
						$scope.lightNavBar = false;
					}
					else {
						$scope.lightNavBar = true;
					}

					self.showMainLogo = toState.data.showLogo !== false;
					if (toState.data.navThemeColour === 'dark') {
						self.navDarkTheme = true;
					}

					document.body.scrollTop = document.documentElement.scrollTop = 0;

					$timeout(() => {
						const trustbox = document.getElementById('trustbox');
						if (trustbox) {
							window.Trustpilot.loadFromElement(trustbox);
						}
					}, 500);

				});

			$scope.toggleNavBarSideNav = buildToggler('navBarSideNav');

			function buildToggler(componentId) {
				return function() {
					$mdSidenav(componentId).toggle();
				};
			}

			$scope.menuOpen = false;

			$scope.toggleMenu = function () {
				$scope.menuOpen = true;
				$mdSidenav('left')
					.open();
			};

			$scope.closeMenu = function () {
				$mdSidenav('left')
					.close();
			};

			$mdSidenav('left', true)
				.then(function (instance) {
					instance.onClose(function () {
						$timeout(() => {
							// timeout to deal with the fact that there is no close complete callback...
							$scope.menuOpen = false;
						}, 500);
					});
				});

			$scope.$on('onResetIdleTimeout', () => {
				lastRequestDateTime = moment();
			});


			function openProfileMenu($mdMenu, ev) {
				$mdMenu.open(ev);
				$scope.profileMenuOpen = true;
			}

			$scope.$on("$mdMenuClose", function (obj, obj2) {
				if (angular.element(obj2[0])
					.hasClass('profile-menu')) {
					$scope.profileMenuOpen = false;
				}
			});

			function isAuthenticated() {
				return AuthSvc.isAuthenticated();
			}

			function logout() {
				AuthSvc.logout()
					.then(function () {
						$state.go('main.public.index');
					});
			}

			$scope.$on('onLogin', (event, param) => {
				lastRequestDateTime = moment();
				$interval.cancel(idleTimer);
				timeoutDialogOpen = false;
				idleTimer = $interval(function () {

					let diffInSeconds = moment()
						.diff(lastRequestDateTime, 'seconds');
					let secondsUntilTimeout = idleTimeoutInSeconds - diffInSeconds;
					if (diffInSeconds > idleTimeoutInSeconds) {
						$interval.cancel(idleTimer);
						$rootScope.$broadcast('onTimeoutExpired');
						timeoutDialogOpen = false;
						AuthSvc.logout()
							.then(function () {
							})
							.finally(() => {
								$state.go('main.public.index');
							});
					}
					else if (secondsUntilTimeout === 30) {
						timeoutDialogOpen = true;
						// $mdDialog.show({
						// 	controller: 'IdleLogoutCtrl',
						// 	templateUrl: 'app/main/modules/public/modules/auth/templates/IdleLogoutModal.html',
						// 	parent: angular.element(document.body),
						// 	clickOutsideToClose: false
						// });
					}

				}, 1000);

				// Start listening for socket events
				if (window.config.app.notifications_enabled) {
					// Initiate Echo library
					let echo = null;
					if (window.config.app.notifications_enabled) {
						let laravelEchoServerUrl = window.config.app.worker_url + ':' + window.config.app.notifications_echo_port;
						echo = new Echo({
							broadcaster: 'socket.io',
							host: laravelEchoServerUrl,
							auth: {headers: {AccessToken: $localStorage.authToken.token}}
						});
					}
					let user = param.user;
					echo.private('App.User.' + user.id)
						.listen('AuthTokenExpiring', function (data) {
							console.log('Auth token expiring');
							AuthSvc.logout();
						})
						.listen('ImportPendingContractStatus', function (data) {
							$rootScope.$broadcast('PendingContractStatusUpdated', {
								status: data.status
							});
						})
						.listen('EnergyStatus', function (data) {
							$rootScope.$broadcast('EnergyStatusUpdated', {
								status: data.status
							});
						});
				}
			});

			function loginClick () {
				if (self.authenticated) {
					AuthSvc.redirect();
				}
				else {
					$state.go('main.public.auth.login');
				}
			}

			function signupClick()  {
				delete $localStorage.account;
				$state.go('main.public.auth.signup');
			}


			function buildPackageClick() {
				$state.go('main.customer.property.build');
			}

			function compareClick() {
				$state.go('main.public.compare');
			}



		}
	]);
