angular.module('app.main')
	.directive("back", function () {

		return {
			restrict: 'E',
			transclude: true,
			replace: true,
			scope: {
				backClick: '&',
				backSref: '@',
				backLabel: '@'
			},
			template: templateFn,
			link: linkFn
		};

		function templateFn(tElement, tAttrs) {
			var uiSref = '';
			if (tAttrs.backSref) {
				uiSref = 'ui-sref="' + tAttrs.backSref + '"';
			}
			return '<button class="back button" type="button" ng-click="backClick()" ' + uiSref + '><div ng-include="\'/images/icons/icon-chevron-left.svg\'" class="svg"></div><span>{{ backLabel }}</span></button>';
		}

		function linkFn(scope, element, iAttr) {

			scope.backLabel = scope.backLabel || 'back';
		}

	});
