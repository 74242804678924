angular.module('app.main')
    .directive('autoSizeInput', function() {
        return {
            replace: true,
            scope: {
                value: '=inputValue'
            },
            templateUrl: 'app/main/directives/autoSizeInput/autoSizeInput.html',
            link: function(scope, element, attrs) {
                let elInput = element.find('input');
                let elSpan = element.find('span');
                elSpan.html(elInput.val());

                scope.$watch('value', function(value) {
                    if(value) {
                        elSpan.html(elInput.val());
                        elInput.css('width', (elSpan[0].offsetWidth + 10) + 'px');
                    }
                });
            }
        };
    });