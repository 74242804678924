angular.module('app.main.public')
	.controller('TariffCtrl', TariffCtrl);

TariffCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$filter',
	'BudgetSvc',
];

function TariffCtrl($scope, $state, $q, $filter, BudgetSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loaded: false,
		handlers: handlers,
	});

	this.$onInit = () => {
		BudgetSvc.rates({
			postcode: null,
			bed_size: null,
		}).then((response) => {
			let rates = response.data.rates;
			self.broadband = $filter('filter')(rates, {utility: 'broadband'}, true)[0];
			self.water = $filter('filter')(rates, {utility: 'water'}, true)[0];
			self.tvLicence = $filter('filter')(rates, {utility: 'tv_licence'}, true)[0];
			self.loaded = true;
		});
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.getEnergyPricesClick = function () {
		return BudgetSvc.rates({
			postcode: self.postcode,
			bed_size: null,
		}).then((response) => {
			let rates = response.data.rates;
			self.electric = $filter('filter')(rates, {utility: 'electric'}, true)[0];
			self.gas = $filter('filter')(rates, {utility: 'gas'}, true)[0];
		});
	};
}