angular.module('app.main.customer.menu')
    .factory('CustomerMenuSvc', function (RequestSvc) {

        return {
            // read: read,
        };

        // function read(params) {
        //     return RequestSvc.get('api/customer/account/read', params);
        // }
    });
