angular.module('app.main.shared.utility')
	.factory('UtilityTypeSvc', [
		'RequestSvc',
		function (RequestSvc) {

			return {
				options: function (params) {
					return RequestSvc.get('api/utility/utility-type/options', params);
				},
				optionsWithRestrictions: function (params) {
					return RequestSvc.get('api/utility/utility-type/options-with-restrictions', params);
				},
				serviceChart: function(params) {
					return RequestSvc.get('api/utility/utility-type/chart', params);
				},
				available: function(params) {
					return RequestSvc.post('api/utility/utility-type/available', params);
				}
			};

		}
	]);
