angular.module('app.main.shared')
	.factory('UtilityReadingSvc', [
		function () {

			function transformReading(reading) {
				if (angular.isObject(reading)) {
					if (angular.isString(reading.starts_at)) {
						reading.starts_at = reading.starts_at ? moment(reading.starts_at) : null;
					}
					if (angular.isString(reading.ends_at)) {
						reading.ends_at = reading.ends_at ? moment(reading.ends_at) : null;
					}
				}
				return reading;
			}

			return {
				transformReading: transformReading
			};

		}
	]);
