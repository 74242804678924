angular.module('app.main')
	.directive("quantitySelector", function () {

		return {
			restrict: 'E',
			replace: true,
			require: '?ngModel',
			scope: {
				ngModel: '=',
				defaultValue: '=',
				incrementLabel: '<',
				onChange: '&',
				minValue: '=',
			},
			templateUrl: 'app/main/directives/quanity-selector/QuantitySelector.html',
			link: linkFn
		};

		function linkFn(scope, element, iAttr, ngModel) {

			let minValue = 0; // default
			if (angular.isDefined(iAttr.minValue)) {
				minValue = parseInt(iAttr.minValue);
			}

			scope.minimumValue = minValue;

			if (angular.isDefined(iAttr.defaultValue)) {
				ngModel.$setViewValue(parseInt(iAttr.defaultValue));
			}
			else {
				if (!angular.isNumber(ngModel.$viewValue)) {
					ngModel.$setViewValue(1);
				}
			}

			scope.increment = function () {
				let currentVal = parseInt(ngModel.$viewValue);
				ngModel.$setViewValue(++currentVal);
				scope.onChange();
			};

			scope.decrement = function () {
				let currentVal = parseInt(ngModel.$viewValue);
				if (currentVal > minValue) {
					ngModel.$setViewValue(--currentVal);
					scope.onChange();
				}
			};

			scope.onKeyPress = function(e, ngModel) {
				let keyCode = (e.keyCode ? e.keyCode : e.which);
				if (keyCode < 48 || keyCode > 57) {
					e.preventDefault();
				}

				let enteredNumber = String.fromCharCode(keyCode);
				let currentValue = parseInt(ngModel + enteredNumber);
				if (currentValue < minValue) {
					e.preventDefault();
				}
				else if (currentValue > 99) {
					e.preventDefault();
				}
				else {
					scope.onChange();
				}


			}
		}


	});