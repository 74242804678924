angular.module('app.main.customer.property.services')
    .controller('PropertyServicesEnergyCtrl', PropertyServicesEnergyCtrl);

PropertyServicesEnergyCtrl.$inject = [
    '$scope',
];

function PropertyServicesEnergyCtrl(
    $scope,
) {
    let self = this;
    let methods = {};
    let handlers = {};

    angular.extend(self, {
        methods: methods,
        handlers: handlers,
        disableContinue: false,
        supplyTypes: [],
        meterTypes: [],
        prePayMeterTypes: [],
    });

    this.$onInit = () => {
        // console.log('-- loaded PropertyServicesEnergy');
    };

    // -----------------------------------------------------------------------------------------------------------------
    // Functions
    // -----------------------------------------------------------------------------------------------------------------

    function setMeterTypes() {
        let meterTypes = [];

        meterTypes = [
            {
                'enabled': true,
                'label': 'Direct Debit',
                'value': 'monthly',
                'description': 'You pay monthly, quarterly or on receipt of bill',
                'icon': 'file-alt-light.svg',
            },
            // {
            //     'enabled': true,
            //     'label': 'Receipt of Bill',
            //     'value': 'receipt',
            //     'description': 'You make payments once a bill has been generated',
            //     'icon': 'chart-bar-regular.svg',
            // },
            {
                'enabled': true,
                'label': 'Pre-pay Meter',
                'value': 'prepay',
                'description': 'You top up using an electricity key or gas card',
                'icon': 'credit-card-duotone.svg',
            },
        ];

        self.meterTypes = meterTypes;
    }

    function setPrePayMeterTypes() {
        let PrePayMeterTypes = [];

        PrePayMeterTypes = [
            {
                'enabled': true,
                'label': 'Gas & Electric',
                'value': 'dual_fuel',
                'description': 'Both gas and electric are paid by top-up',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
            },
            {
                'enabled': true,
                'label': 'Electric',
                'value': 'electric',
                'description': 'Only electric is paid by top-up',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/electric.png',
            },
            {
                'enabled': true,
                'label': 'Gas',
                'value': 'gas',
                'description': 'Only gas is paid by top-up',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/gas.png',
            },
        ];

        self.prePayMeterTypes = PrePayMeterTypes;
    }

    function setSupplyTypes() {
        let types = [];

        types = [
            {
                'enabled': true,
                'label': 'Dual Fuel',
                'value': 'dual_fuel',
                'description': 'Electric & Gas',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/energy.png',
            },
            {
                'enabled': true,
                'label': 'Electric',
                'value': 'electric',
                'description': 'Electric Only',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/electric.png',
            },
            {
                'enabled': true,
                'label': 'Gas',
                'value': 'gas',
                'description': 'Gas only',
                'icon_path': 'https://mango-resources-bucket.s3-eu-west-1.amazonaws.com/icons/gas.png',
            },
        ];

        self.supplyTypes = types;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Methods
    // -----------------------------------------------------------------------------------------------------------------

    methods.getSupplyTypes = () => {
        setSupplyTypes();
    };

    methods.getMeterTypes = () => {
        setMeterTypes();
    };

    methods.getPrePayMeterTypes = () => {
        setPrePayMeterTypes();
    };

    // -----------------------------------------------------------------------------------------------------------------
    // Handlers
    // -----------------------------------------------------------------------------------------------------------------

    handlers.testHandler = (params) => {
        console.log(params);
    };
}
