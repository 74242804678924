angular.module('app.main.public')
	.controller('AncillaryCtrl', [
		'$log',
		'$scope',
		'$window',
		'$document',
		'$intercom',
		function ($log,
				  $scope,
				  $window,
				  $document,
				  $intercom) {

			let self = this;
			let handlers = {};

			angular.extend(self, {
				handlers: handlers,
			});

			$scope.lightNavBar = false;
			document.body.scrollTop = document.documentElement.scrollTop = 0;


			handlers.startChatClick = () => {
				// $intercom.show();
			};

		}
	]);
