angular.module('app.main')
	.factory('ResponseSvc', [
		'$log',
		function ($log) {

			var lastErrorResponse;

			return {
				getLastErrorResponse: function () {
					return lastErrorResponse;
				},
				setLastErrorResponse: function (value) {
					lastErrorResponse = value;
				}
			}

		}
	]);
