angular.module('app.main.shared.property')
	.component('propertyCoverageBreakdown', {
		templateUrl: 'app/main/modules/shared/modules/property/components/coverageBreakdown/coverageBreakdown.html',
		controller: PropertyCoverageBreakdownController,
		bindings: {
			propertyId: '<',
			dateFrom: '<',
			dateTo: '<',
			control: '=',
			contractClick: '&',
			billChargeClick: '&',
			endpointClick: '&',
		},
	});

PropertyCoverageBreakdownController.$inject = [
	'$scope',
	'RequestSvc',
];

function PropertyCoverageBreakdownController(
	$scope,
	RequestSvc
) {
	let self = this;

	this.$onInit = () => {
		// TODO: Possibly based the date range on just a single date so that it's easier to jump to another period
		// Initialise watches
		$scope.$watchGroup([
			() => {
				return self.propertyId;
			},
			() => {
				return moment.isMoment(self.dateFrom) ? self.dateFrom.format() : undefined;
			},
			() => {
				return moment.isMoment(self.dateTo) ? self.dateTo.format() : undefined;
			}
		], () => {
			onParamsChanged();
		});
	};

	angular.extend(self, {
		methods: {
			moveViewLeft: moveViewLeft,
			moveViewRight: moveViewRight,
			periodClick: onPeriodClick,
			endpointClick: onEndpointClick,
		},
	});

	if (angular.isObject(self.control)) {
		angular.extend(self.control, {
			refresh: onParamsChanged,
		});
	}

	function onParamsChanged() {
		if (self.propertyId && moment.isMoment(self.dateFrom) && moment.isMoment(self.dateTo)) {
			loadBreakdown();
		}
		else {
			// TODO: clear breakdown
		}
	}

	function moveViewLeft(months) {
		months = months > 1 ? months : 1;
		self.dateFrom.subtract(months, 'month');
		self.dateTo.startOf('month')
			.subtract(months, 'month')
			.endOf('month');
	}

	function moveViewRight(months) {
		months = months > 1 ? months : 1;
		self.dateFrom.add(months, 'month');
		self.dateTo.startOf('month')
			.add(months, 'month')
			.endOf('month');
	}

	function loadBreakdown() {
		self.loading = true;
		RequestSvc.get('api/customer/property/coverage-breakdown', {
			id: self.propertyId,
			from: self.dateFrom.format(),
			to: self.dateTo.format(),
		})
			.then((response) => {
				self.rows = [];
				self.warnings = [];
				// Generate months
				updateMonths();

				// Create row per endpoint
				angular.forEach(response.data.property.property_utility_types, (propertyUtilityType) => {
					angular.forEach(propertyUtilityType.endpoints, (endpoint) => {
						// Make sure property utility type is accessible from endpoint
						endpoint.property_utility_type = angular.copy(propertyUtilityType);

						let row = {
							type: 'endpoint',
							label: endpoint.property_utility_type.utility_type.label,
							periods: [],
							originalObject: endpoint,
						};
						angular.forEach(endpoint.contracts, (contract) => {
							contract.endpoint = angular.copy(endpoint);
							let period = {
								type: 'contract',
								startsAt: moment.utc(contract.starts_at),
								endsAt: moment.utc(contract.ends_at),
								originalObject: contract,
							};
							row.periods.push(period);
							angular.forEach(contract.bill_contracts, (billContract) => {
								angular.forEach(billContract.charges, (billCharge) => {
									billCharge.bill_contract = angular.copy(billContract);
									let period = {
										type: 'bill-charge',
										startsAt: moment.utc(billCharge.starts_at),
										endsAt: moment.utc(billCharge.ends_at),
										originalObject: billCharge,
									};
									row.periods.push(period);
								});
							});
						});

						self.rows.push(row);
					});
				});

				// Calculate period offsets and widths per row
				// TODO: Possibly deal with overlapping periods specifically
				angular.forEach(self.rows, (row) => {
					angular.forEach(row.periods, calculatePeriodWidthAndOffset);
				});

				self.loading = false;
			});
	}

	function updateMonths() {
		if (!angular.isArray(self.months)) {
			self.months = [];
		}
		self.months.length = 0;
		let dateIterator = self.dateFrom.clone();
		while (dateIterator.isSameOrBefore(self.dateTo)) {
			let month = {
				startsAt: dateIterator.startOf('month')
					.clone(),
				endsAt: dateIterator.endOf('month')
					.clone(),
			};
			calculatePeriodWidthAndOffset(month);
			self.months.push(month);
			dateIterator.add(1, 'seconds');
		}
	}

	function calculatePeriodWidthAndOffset(period) {
		// Take copies of the dates so that the watch isn't triggered
		let dateFrom = self.dateFrom.clone().startOf('month');
		let dateTo = self.dateTo.clone().endOf('month');
		let displayDays = dateTo.diff(dateFrom, 'days') + 1;
		let latestDateFrom = moment.max(dateFrom, period.startsAt);
		let earliestDateTo = moment.min(dateTo, period.endsAt);
		let daysDisplayed = earliestDateTo.diff(latestDateFrom, 'days') + 1;
		let widthDisplayedPc = daysDisplayed / displayDays * 100;
		let offsetDays = latestDateFrom.diff(dateFrom, 'days');
		let offsetPc = offsetDays / displayDays * 100;
		let extendsLeft = period.startsAt.isBefore(dateFrom);
		let extendsRight = period.endsAt.isAfter(dateTo);
		angular.extend(period, {
			daysDisplayed: daysDisplayed,
			widthDisplayedPc: widthDisplayedPc,
			offsetDays: offsetDays,
			offsetPc: offsetPc,
			extendsLeft: extendsLeft,
			extendsRight: extendsRight,
		});
	}

	function onPeriodClick(period) {
		if (angular.isFunction(self.contractClick) && period.type === 'contract') {
			self.contractClick({contract: period.originalObject});
		}
		if (angular.isFunction(self.billChargeClick) && period.type === 'bill-charge') {
			self.billChargeClick({billCharge: period.originalObject});
		}
	}

	function onEndpointClick(row) {
		if (angular.isFunction(self.endpointClick)) {
			self.endpointClick({endpoint: row.originalObject});
		}
	}
}
