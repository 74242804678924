angular.module('app.main.shared')
	.factory('PaymentFrequencySvc', function (RequestSvc) {

		function options(params) {
			return RequestSvc.get('api/payment-frequency/options');
		}

		return {
			options: options
		};

	});
