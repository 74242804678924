angular.module('app.main.customer')
	.factory('ProfileSvc', function (RequestSvc, $mdDialog) {

		function read() {
			return RequestSvc.get('api/profile/read');
		}

		function save(params) {
			return RequestSvc.post('api/profile/save', params);
		}

		function updatePassword(params) {
			return RequestSvc.post('api/profile/updatePassword', params);
		}

		function profilePicModal(type) {
			if (angular.isUndefined(type)) {
				type = 'circle';
			}
			return $mdDialog.show({
				templateUrl: 'app/main/modules/customer/_myAccount/_profile/ProfilePicModal.html',
				controller: 'ProfilePicModalCtrl',
				controllerAs: 'ProfilePic',
				locals: {
					params: {
						type: type
					}
				},
				preserveScope: true,
				clickOutsideToClose: true,
				escapeToClose: true
			});
		}

		function saveProfilePic(params) {
			return RequestSvc.post('api/profile/save-profile-pic', params);
		}

		function removeProfilePic() {
			return RequestSvc.post('api/profile/remove-profile-pic');
		}

		return {
			read: read,
			save: save,
			updatePassword: updatePassword,
			profilePicModal: profilePicModal,
			saveProfilePic: saveProfilePic,
			removeProfilePic: removeProfilePic,
		};

	});
