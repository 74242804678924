angular.module('app.main.customer')
	.factory('CustomerAccountSvc', function (RequestSvc) {

		function read(params) {
			return RequestSvc.get('api/customer/account/read', params);
		}

		function listHistory(params) {
			return RequestSvc.get('api/customer/account/list-history', params);
		}

		function propertiesAwaitingPayment(params) {
			return RequestSvc.get('api/customer/account/properties-awaiting-payment', params);
		}

		return {
			read: read,
			listHistory: listHistory,
			propertiesAwaitingPayment: propertiesAwaitingPayment,
		};

	});
