angular.module('app.main.customer.property')
	.controller('PackageBuilderCtrl', PackageBuilderCtrl);

PackageBuilderCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$document',
	'focus',
	'$timeout',
	'notify',
	'$localStorage',
	'$filter',
	'Angularytics',
	'StripeElements',
	'$intercom',
	'PropertySvc',
	'UtilityTypeSvc',
	'BudgetSvc',
	'AuthSvc',
	'BroadbandProductTypeSvc',
	//
	'PaymentCardSvc',
	'ReferralSvc',
];

function PackageBuilderCtrl(
	$scope,
	$state,
	$q,
	$document,
	focus,
	$timeout,
	notify,
	$localStorage,
	$filter,
	Angularytics,
	StripeElements,
	$intercom,
	PropertySvc,
	UtilityTypeSvc,
	BudgetSvc,
	AuthSvc,
	BroadbandProductTypeSvc,
	//
	PaymentCardSvc,
	ReferralSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
		options: {},
		form: null,
		submitPromise: null,
		formData: {},
		tmpFields: {
			activeService: null,
			services: [],
			address: {},
			details: {},
			payment: {},
		},
		activeStep: 0,
		//
		errors: [],
		setupIntent: {},
		setupLoading: false,
		showReassurance: false,
		//
		addVoucher: false,
		voucherAdded: false,
		voucherCode: undefined,
		//
		properties: [],
		vouchers: [{}],
	});

	self.steps = [
		{
			index: 0,
			icon: 'home-duotone',
			label: 'Your property',
			value: 'property',
			element: 'address-step',
			template: 'app/main/modules/customer/_property/_package-builder/includes/address.html',
			complete: false,
			visible: true,
		},
		{
			index: 1,
			icon: 'bed-duotone',
			label: 'Bedrooms',
			value: 'beds',
			element: 'bedrooms-step',
			template: 'app/main/modules/customer/_property/_package-builder/includes/bedrooms.html',
			complete: false,
			visible: true,
		},
		{
			index: 2,
			icon: 'box-full-duotone',
			label: 'Your services',
			value: 'package',
			element: 'package-step',
			template: 'app/main/modules/customer/_property/_package-builder/includes/package.html',
			complete: false,
			visible: true,
		},
		{
			index: 3,
			icon: 'user-duotone',
			label: 'Create your free account',
			value: 'details',
			element: 'details-step',
			template: 'app/main/modules/customer/_property/_package-builder/includes/details.html',
			complete: false,
			visible: false,
		},
	];

	this.$onInit = () => {
		self.loading = true;
		self.isAuthenticated = AuthSvc.isAuthenticated();
		if (self.isAuthenticated) {
			self.steps.pop();
			self.steps.pop();
			PropertySvc.index().then((response) => {
				self.properties = response.data.property_collection;
				self.vouchers = $filter('filter')(self.properties, function (item) {
					if (item.voucher !== null) {
						let expiresAt = new Date(item.voucher.ends_at);
						let now = new Date();
						if (expiresAt > now) {
							return item.voucher;
						}
					}
				});
			}, (error) => {
				console.log(error);
			});
		}
		self.form = angular.copy($localStorage.account) || {};
		self.referral = $localStorage.referral;
		self.form.voucher = $localStorage.voucher;
		if (angular.isObject(self.form)) {
			self.postcode = self.form.postcode_entered;
			if (angular.isNumber(self.form.beds_entered)) {
				self.tmpFields.bedSize = self.form.beds_entered;
			}
			getAddresses();
		}
		calculateStepPosition();
	};

	function calculateStepPosition() {
		let activeStep = 0;

		angular.forEach(self.steps, (step) => {
			let completed = false;
			if (step.value === 'property' && angular.isObject(self.form.address)) {
				if (self.form.isManualAddress) {
					self.manualAddressEntry = true;
					angular.extend(self.tmpFields.address, {
						line_1: self.form.address.line_1,
						line_2: self.form.address.line_2,
						line_3: self.form.address.line_3,
						city: self.form.address.city,
						postcode: self.form.address.postcode,
					});
				}
				completed = true;
				activeStep = step.index + 1;
			}
			if (step.value === 'beds' && self.form.bedSize) {
				self.tmpFields.bedSize = self.form.bedSize;
				completed = true;
				activeStep = step.index + 1;
			}
			else if (step.value === 'package' && angular.isObject(self.form.services)) {
				completed = true;
				activeStep = step.index + 1;
			}
			else if (step.value === 'details' && angular.isObject(self.form.details)) {
				angular.extend(self.tmpFields.details, self.form.details);
				completed = true;
				activeStep = step.index + 1;

			}
			angular.extend(step, {
				completed: completed,
			});
		});
		self.activeStep = activeStep;
	}

	function getAddresses(postcode) {
		let _postcode = '';
		if (angular.isDefined(postcode)) {
			_postcode = postcode;
		}
		else {
			_postcode = self.form.postcode_entered;
		}
		return PropertySvc.addressLookup({postcode: _postcode})
			.then((response) => {
				self.options.addresses = response.data.addresses;
				self.form.postcode_entered = _postcode;

				// preselect address if we have one
				if (!self.form.isManualAddress) {
					if (angular.isObject(self.form.address)) {
						angular.forEach(self.options.addresses, (address) => {
							if (address.single_line_address === self.form.address.single_line_address) {
								address.selected = true;
								self.tmpFields.address = address;
							}
						});
					}
					else {
						// self.options.addresses.unshift({
						// 	id: -1,
						// 	single_line_address: '- please select -',
						// });
						// self.tmpFields.address = self.options.addresses[0];
					}
				}

				self.loading = false;
			});
	}

	$scope.$watch(() => {
		return self.activeStep;
	}, (value) => {
		if (value === 2) {
			loadServices();
		}
	});

	function loadServices() {
		if (self.tmpFields.services.length === 0) {
			self.loadingServices = true;
			let promises = {
				utilityTypes: UtilityTypeSvc.options({filter: { hmo_enabled: true}}),
				rates: BudgetSvc.rates({
					postcode: self.tmpFields.address.postcode,
					bed_size: self.tmpFields.bedSize,
				}),
				broadbandProductTypes: BroadbandProductTypeSvc.options({postcode: self.form.address.postcode}),
			};
			$q.all(promises).then((responses) => {
				self.options.utilityTypes = responses.utilityTypes.data.utility_type_options;
				self.rates = responses.rates.data.rates;
				self.options.broadbandProducts = responses.broadbandProductTypes.data.options;
				self.options.broadbandVirginAvailable = responses.broadbandProductTypes.data.virgin_available;

				if (angular.isArray(self.form.services) && self.form.services.length > 0) {
					self.tmpFields.services = self.form.services;
				}
				else {
					loadDefaults();
				}

				// update all prices on the broadband products
				let rates = $filter('filter')(self.rates, {utility: 'broadband'}, true)[0];

				angular.forEach(self.options.broadbandProducts, (product) => {
					let rate = $filter('filter')(rates.rates, (rate) => {
						return rate.broadband_product_type.id === product.id;
					})[0];

					if (rate) {
						angular.extend(product, {
							spend: rate.rates,
						});
					}
				});

				updatePrices();

				self.loading = false;
				if (self.form.referredByLink && self.form.shownOpenRentDialog !== true) {
					$timeout(() => {
						self.showOpenRentDialog = true;
						self.form.shownOpenRentDialog = true;
					},1500);
				}

				self.loadingServices = false;

			});
		}

	}

	function loadDefaults() {
		let simplePackageSelectorUtilities = self.form.selected_utilities || [];
		angular.forEach(self.options.utilityTypes, (utilityType) => {
			let usageType = null;
			let usageKwh = 0;
			let productType = null;
			let usageSpend = 0;
			let quantity = 0;
			let meta = {};
			let productTypeObject = {};
			let notRequired = true;
			let rates = $filter('filter')(self.rates, {utility: utilityType.value}, true)[0];
			let autoEnabled = $filter('filter')(simplePackageSelectorUtilities, {value: utilityType.value}).length;
			if (utilityType.value === 'electric') {
				usageType = 'unknown';
				quantity = autoEnabled ? 1 : 0;
				usageKwh = rates.eac.eac;
				notRequired = false;
				meta = {
					rates: rates,
					eac: rates.eac.eac,
				}
			}
			else if (utilityType.value === 'gas') {
				quantity = autoEnabled ? 1 : 0;
				usageType = 'unknown';
				usageKwh = rates.eac.eac;
				meta = {
					rates: rates,
					eac: rates.eac.eac,
				}
			}
			else if (utilityType.value === 'water') {
				quantity = autoEnabled ? 1 : 0;
				usageType = 'spend';
				usageSpend = Math.round(parseFloat(rates.eac.water) / 12);
			}
			else if (utilityType.value === 'broadband') {
				quantity = autoEnabled ? 1 : 0;
				if (self.options.broadbandVirginAvailable) {
					productType = self.options.broadbandProducts[0].id;
					productTypeObject = self.options.broadbandProducts[0];
				}
				else {
					productType = self.options.broadbandProducts[1].id;
					productTypeObject = self.options.broadbandProducts[1];
				}
			}
			else if (utilityType.value === 'tv_licence') {
				quantity = autoEnabled ? 1 : 0;
				usageType = 'spend';
			}
			self.tmpFields.services.push({
				utilityType: utilityType,
				productType: productType,
				productTypeObject: productTypeObject,
				usageType: usageType,
				usageKwh: usageKwh,
				defaultUsageKwh: usageKwh,
				quantity: quantity,
				not_required: notRequired,
				usageSpend: usageSpend,
				requiredWhen: 'asap',
				meta: meta,
			});
		});

	}

	function updatePrices() {
		angular.forEach(self.tmpFields.services, (service) => {
			let spend = 0;
			let rates = $filter('filter')(self.rates, {utility: service.utilityType.value}, true)[0];
			if (service.utilityType.value === 'electric' || service.utilityType.value === 'gas') {
				if (service.usageType === 'spend') {
					spend = Math.round(service.usageSpend);
				}
				else if (service.usageType === 'kwh') {
					let unitCost = rates.rates.unit_rate * service.usageKwh;
					let standingChargeCost = rates.rates.standing_charge * 365;
					let annaulCharge = unitCost + standingChargeCost;
					spend = Math.round(annaulCharge / 12);
				}
				else {
					let unitCost = rates.rates.unit_rate * service.defaultUsageKwh;
					let standingChargeCost = rates.rates.standing_charge * 365;
					let annaulCharge = unitCost + standingChargeCost;
					spend = Math.round(annaulCharge / 12);
				}
			}
			else if (service.utilityType.value === 'water') {
				if (service.usageType === 'spend') {
					spend = Math.round(service.usageSpend);
				}
				else {
					spend = Math.round(rates.eac.water / 12);
				}
			}
			else if (service.utilityType.value === 'tv_licence') {
				if (service.not_required) {
					spend = Math.round(rates.rates.rate); // show indicative price anyway
				}
				else {
					spend = Math.round(rates.rates.rate * service.quantity);
				}
			}
			else if (service.utilityType.value === 'broadband') {
				let rate = $filter('filter')(rates.rates, (rate) => {
					return rate.broadband_product_type.id === service.productType;
				});
				if (rate.length) {
					if (service.not_required) {
						spend = Math.round(rate[0].rates);
					}
					else {
						spend = Math.round(rate[0].rates * service.quantity);
					}
				}
			}


			if (service.requiredWhen === 'later') {
				spend = 0;
			}

			angular.extend(service, {
				monthly_spend: spend,
			});
		});

		let activeServices = 0;
		let totalSpend = 0;
		angular.forEach(self.tmpFields.services, (service) => {
			if (service.quantity >= 1 && service.requiredWhen !== 'later') {
				activeServices++;
				totalSpend += service.monthly_spend;
			}
		});
		self.costPerService = 0;// removed: 1.5;
		self.mangoFee = activeServices * self.costPerService;

		if (angular.isObject(self.referral)) {
			let voucher = self.referral.voucher;
			if (parseFloat(voucher.discount_value) === 0) {
				self.referralOnly = true;
			}
			else {
				self.originalMangoFee = self.mangoFee;
				self.originalCostPerService = self.costPerService;
				let discount = parseFloat(voucher.discount_value);
				if (voucher.discount_type === 'percent') {
					self.mangoFee = self.mangoFee * discount / 100;
					self.costPerService = self.costPerService * discount / 100;
				}
				else if (voucher.discount_type === 'fixed') {
					self.mangoFee = self.mangoFee - discount;
					self.costPerService = self.mangoFee / activeServices;
				}
			}
		}

		$timeout(() => {
			self.totalMonthlyCost = totalSpend + self.mangoFee;
		},500);

		$timeout(() => {
			self.existingTotalMonthlyCost = self.totalMonthlyCost;
		},1000);
	}

	function scrollTo(elementClass) {
		let someElement = angular.element(document.getElementById(elementClass));
		let $element = angular.element(document.querySelector("html")); //angular.element(document.getElementsByClassName('build__content'));
		// $element.scrollToElement(someElement, 0, 750);
	}

	function resetForm() {
		if (angular.isObject(self.tmpFields.address) && angular.isObject(self.form.address)) {
			if ((self.tmpFields.address.line_1 !== self.form.address.line_1) || self.tmpFields.address.postcode !== self.form.address.postcode) {
				self.tmpFields.services = [];
				angular.extend(self.form, {
					services: null,
					payment: null,
				});
				angular.forEach(self.steps, (_step) => {
					if (_step.index > 0) {
						_step.completed = false;
					}
				});
			}
		}
	}

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.openChatClick = () => {
		// $intercom.show();
	};

	handlers.manualAddressClick = () => {
		self.manualAddressEntry = true;
		$timeout(() => {
			focus('line_1');
		}, 500);
	};

	handlers.lookupAddress = () => {
		if (self.tmpFields.postcode.length > 0) {
			return getAddresses(self.tmpFields.postcode);
		}
	};

	handlers.decreaseCountClick = () => {
		if (self.tmpFields.bedSize >= 2) {
			self.tmpFields.bedSize--;
		}
	};

	handlers.increaseCountClick = () => {
		self.tmpFields.bedSize++;
	};

	handlers.decreaseServiceCountClick = () => {
		if (self.tmpFields.activeService.tmpQuantity >= 2) {
			self.tmpFields.activeService.tmpQuantity--;
		}
	};

	handlers.increaseServiceCountClick = () => {
		self.tmpFields.activeService.tmpQuantity++;
	};

	handlers.requiredWhenClick = (when) => {
		self.tmpFields.activeService.requiredWhen = when;
		focus('day');
	};

	handlers.addServiceClick = (service) => {
		service.is_expanded = true;
		self.tmpFields.activeService = angular.copy(service);
		self.tmpFields.activeService.tmpQuantity = 1;

		updatePrices();
		scrollTo(service.utilityType.value);
		Angularytics.trackEvent("Package Builder", "Add service click");
	};

	handlers.customiseClick = (service) => {
		angular.forEach(self.tmpFields.services, (_service) => {
			angular.extend(_service, {is_expanded: false});
		});

		service.is_expanded = true;
		self.tmpFields.activeService = angular.copy(service);
		if (service.utilityType.value === 'broadband') {
			self.tmpFields.activeService.tmpQuantity = service.quantity;
		}
		scrollTo(service.utilityType.value);
		Angularytics.trackEvent("Package Builder", "Customise click");
	};

	handlers.customerCancelClick = (service) => {
		self.tmpFields.activeService = null;
		service.is_expanded = false;
	};

	handlers.addUpdateService = (service) => {
		if (service.utilityType.value === 'gas' || service.utilityType.value === 'electric' || service.utilityType.value === 'water') {
			self.tmpFields.activeService.tmpQuantity = 1;
		}

		self.tmpFields.activeService.quantity++;
		self.tmpFields.activeService.not_required = false;
		self.tmpFields.activeService.is_expanded = false;

		if (self.tmpFields.activeService.utilityType.value === 'broadband') {
			angular.forEach(self.options.broadbandProducts, (product) => {
				if (product.id === self.tmpFields.activeService.productType) {
					self.tmpFields.activeService.productTypeObject = angular.copy(product);
				}
			});
		}

		angular.extend(service, angular.copy(self.tmpFields.activeService));
		service.quantity = self.tmpFields.activeService.tmpQuantity;
		self.tmpFields.activeService = null;
		updatePrices();
	};

	handlers.onRemoveFromPackage = (service) => {
		self.tmpFields.activeService.quantity = 0;
		self.tmpFields.activeService.requiredWhen = 'asap';
		self.tmpFields.activeService.requiredOn = null;
		self.tmpFields.activeService.not_required = true;
		self.tmpFields.activeService.is_expanded = false;
		angular.extend(service, angular.copy(self.tmpFields.activeService));
		self.tmpFields.activeService = null;
		updatePrices();
	};

	handlers.filterActiveServices = (service) => {
		return service.quantity > 0 && service.requiredWhen !== 'later';
	};
	handlers.filterFutureServices = (service) => {
		if (service.requiredWhen === 'later') {
			return true;
		}
		else {
			return false;
		}
	};

	handlers.validateServiceDate = () => {
		// Validate date
		let validated = true;
		let _date = self.tmpFields.activeService.year + '-' + self.tmpFields.activeService.month + '-' + self.tmpFields.activeService.day;
		_date = moment(_date, 'YYYY-MM-DD');
		if (!_date.isValid()) {
			validated = false;
			self.dateValidationError = "Not a valid date";
		}
		else if (_date.isValid() && !_date.isAfter(moment())) {
			validated = false;
			self.dateValidationError = "Must be greater than today";
		}

		if (_date.isValid() && self.tmpFields.activeService.requiredWhen === 'custom' && _date.isAfter(moment().add(3, 'month'))) {
			validated = false;
			self.dateValidationError = "You can't order services more than 3 months in the future";
		}

		if (validated) {
			self.dateValidationError = null;
			self.tmpFields.activeService.remindOn = _date;
		}

		self.tmpFields.activeService.dateValid = validated;
	};

	handlers.editStepClick = (step) => {
		self.activeStep = step.index;
		scrollTo(step.element);
	};

	// Details related
	// handlers.correspondenceAddressLookupClick = () => {
	// 	return PropertySvc.addressLookup({postcode: self.tmpFields.details.tmpPostcode, house_no: self.tmpFields.details.tmpHouseNo}).then((response) => {
	// 		self.options.correspondenceAddresses = response.data.addresses;
	//
	// 		if (self.options.correspondenceAddresses.length === 1) {
	// 			angular.extend(self.tmpFields.details, {
	// 				correspondence: self.options.correspondenceAddresses[0],
	// 			});
	// 		}
	// 		else if (self.options.correspondenceAddresses.length > 1) {
	// 			self.options.correspondenceAddresses.unshift({
	// 				id: -1,
	// 				single_line_address: '- please select -',
	// 			});
	// 			self.tmpFields.details.address = self.options.correspondenceAddresses[0];
	// 		}
	// 		else if (self.options.correspondenceAddresses.length === 0) {
	// 			// todo: show manual
	// 		}
	// 	});
	// };

	// handlers.showCompanyClick = () => {
	// 	if (self.showCompany) {
	// 		self.showCompany = false;
	// 		self.form.company_name = null;
	// 	}
	// 	else {
	// 		self.showCompany = true;
	// 		focus('company_name');
	// 	}
	// };

	handlers.onValidateEmail = () => {
		AuthSvc.validateEmail(self.tmpFields.details.email).then((response) => {
			self.emailExists = response.data.exists;
		});
	};

	handlers.onPasswordChange = () => {
		self.showPasswordConfirm = true;
	};

	handlers.triggerAddressLookupClick = () => {
		$timeout(function() {
			angular.element('.lookup-btn').triggerHandler('click');
		});
	};

	handlers.correspondenceAddressSelected = () => {
		angular.extend(self.tmpFields.details, {
			correspondence: self.tmpFields.details.address,
		});
	};

	handlers.lookupAgainClick = () => {
		self.tmpFields.details.correspondence = null;
		// focus('correspondence_postcode');
	};

	handlers.addressSelected = (address) => {
		angular.extend(self.tmpFields, {
			address: address,
		});
		self.addressErrors = null;
	};

	handlers.addressManualClick = () => {
		self.tmpFields.details.isManualAddress = true;
		focus('line_1');
	};

	handlers.showLookupAddressClick = () => {
		self.tmpFields.details.isManualAddress = false;
		self.tmpFields.details.correspondence = null;
		// focus('correspondence_postcode');
	};

	function formatManualAddress() {
		let addressLines = [
			'line_1',
			'line_2',
			'line_3',
			'city',
			'county',
			'postcode',
		];
		let filledLinesNoKey = [];
		angular.forEach(addressLines, (k, v) => {
			let trimmed = self.tmpFields.details.correspondence[k]; // .trim();
			if (trimmed) {
				filledLinesNoKey.push(trimmed);
			}
		});

		angular.extend(self.tmpFields.details.correspondence, {
			single_line_address: filledLinesNoKey.join(', '),
			multi_line_address: filledLinesNoKey.join('\n\r'),
		});
	}


	// payment
	handlers.initPaymentSetup = () => {
		self.elements = StripeElements.elements();

		/** New */
		PaymentCardSvc.createSetupIntent().then((response) => {
			self.setupIntent = response.data.setup_intent;
		});

		let elementStyles = {
			base: {
				color: '#36495F',
				fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',

				':focus': {
					color: '#424770',
				},

				'::placeholder': {
					color: '#C0CCDA',
				},

				':focus::placeholder': {
					color: '#C0CCDA',
				},
			},
			invalid: {
				color: '#fff',
				':focus': {
					color: '#FA755A',
				},
				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		};

		self.cardNumberElement = self.elements.create('cardNumber', {style: elementStyles});
		let cardExpElement = self.elements.create('cardExpiry', {style: elementStyles});
		let cardCvcElement = self.elements.create('cardCvc', {style: elementStyles});

		self.cardNumberElement.mount('#card-number-element');
		cardExpElement.mount('#card-expiry-element');
		cardCvcElement.mount('#card-cvc-element');
	};

	handlers.continueClick = (step, isValid) => {
		let formValid = true;
		if (step.value === 'property') {
			if (!isValid) {
				self.addressSubmitted = true;
				formValid = false;
			}
			else {
				// Reset form if the address changes
				resetForm();
				if (self.manualAddressEntry) {
					angular.extend(self.form, {
						isManualAddress: true,
						address: {
							line_1: self.tmpFields.address.line_1,
							line_2: self.tmpFields.address.line_2,
							line_3: self.tmpFields.address.line_3,
							city: self.tmpFields.address.city,
							postcode: self.tmpFields.address.postcode,
							single_line_address: self.tmpFields.address.line_1 + ', ' + self.tmpFields.address.postcode,
						}
					});
				}
				else {
					if (!self.tmpFields.address.line_1) {
						self.addressErrors = ["Please select an address from the list below, if you can't find your property you may enter your address manually"];
						return;
					}

					angular.extend(self.form, {
						isManualAddress: false,
						address: self.tmpFields.address,
					});
				}
				if (!angular.isNumber(self.tmpFields.bedSize)) {
					self.tmpFields.bedSize = 3;
				}

			}
			Angularytics.trackEvent("Package Builder", "Property submit click");
		}
		else if (step.value === 'beds') {
			if (self.tmpFields.bedSize !== self.form.bedSize && step.completed) {
				self.tmpFields.services = [];
				angular.extend(self.form, {
					services: null,
					payment: null,
				});
				angular.forEach(self.steps, (_step) => {
					if (_step.index > step.index) {
						_step.completed = false;
					}
				});
			}

			self.form.bedSize = self.tmpFields.bedSize;
			Angularytics.trackEvent("Package Builder", "Beds submit click");

		}
		else if (step.value === 'package') {

			self.steps[3].visible = true; // change

			if (self.totalMonthlyCost <= 0) {
				console.log('must add at least one service');
			}

			angular.extend(self.form, {
				services: self.tmpFields.services,
				monthlyCost: self.totalMonthlyCost,
				mangoFee: self.mangoFee,
			});
			Angularytics.trackEvent("Package Builder", "Package submit click");
		}
		else if (step.value === 'details') {
			if (self.tmpFields.details.correspondence === null || !angular.isDefined(self.tmpFields.details.correspondence)) {
				self.correspondenceValidation = false;
				isValid = false;
			}
			// Validate dob
			self.dobValidation = true;
			let dob = self.tmpFields.details.dob_year + '-' + self.tmpFields.details.dob_month + '-' + self.tmpFields.details.dob_day;
			let dobDate = moment(dob, 'YYYY-MM-DD');
			if (!dobDate.isValid()) {
				self.dobValidation = false;
				isValid = false;
			}

			angular.extend(self.tmpFields.details, {
				dob: dobDate,
			});

			// Validate email address
			self.emailValidation = true;
			let emailTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(!emailTest.test(String(self.tmpFields.details.email).toLowerCase())) {
				self.emailValidation = false;
				isValid = false;
			}

			if (self.emailExists) {
				self.emailValidation = false;
				isValid = false;
			}

			// Validate password
			self.passwordValidation = true;
			self.passwordError = '';
			let pwdTest = /^(?=.*\d).{6,}$/;
			if(!pwdTest.test(String(self.tmpFields.details.password))) {
				self.passwordValidation = false;
				isValid = false;
				self.passwordError = 'Min six characters and at least one number';
			}
			else if (self.form.password !== self.tmpFields.details.passwordConfirm) {
				isValid = false;
				self.passwordValidation = false;
				self.passwordError = 'Passwords do not match';
			}


			if (isValid) {
				formatManualAddress();
				angular.extend(self.form, {
					details: self.tmpFields.details,
				});
				Angularytics.trackEvent("Package Builder", "Details submit click");
			}
			else {
				self.tmpFields.details.submitted = true;
				formValid = false;
			}
		}

		if (formValid) {
			step.completed = true;
			self.activeStep = step.index + 1;

			angular.forEach(self.steps, (step) => {
				if (step.index === self.activeStep) {
					console.log('scrolling to ' + step.element);
					scrollTo(step.element);
				}
			});

			// scrollTo(step.element);
			if (step.value === 'package') {
				focus('first_name');
			}
			else if (step.value === 'details') {
				focus('name_on_card');
			}
			$localStorage.account = angular.copy(self.form);
		}
	};

	// handlers.quickSubmitClick = () => {
	// 	angular.extend(self.form, {
	// 		services: self.tmpFields.services,
	// 		monthlyCost: self.totalMonthlyCost,
	// 		mangoFee: self.mangoFee,
	// 		voucher: angular.isObject(self.vouchers[0]) ? self.vouchers[0].voucher : null,
	// 	});
	//
	// 	return PropertySvc.saveProperty(self.form).then((response) => {
	// 		self.processing = false;
	// 		Angularytics.trackEvent("Package Builder", "Signed up");
	// 		delete $localStorage.account;
	// 		// AuthSvc.setUser(response.data.user);
	// 		$state.go('main.customer.property.index');
	// 		notify({
	// 			message: 'New property added successfully',
	// 		});
	// 	}, (errors) => {
	// 		self.errors = errors;
	// 	});
	// };

	handlers.submitClick = function (isValid) {
		self.errors = [];
		if (!isValid) {
			self.tmpFields.payment.submitted = true;
			return;
		}

		angular.extend(self.form, {
			details: self.tmpFields.details,
		});

		if (angular.isObject(self.referral)) {
			angular.extend(self.form, {
				voucher: self.referral.voucher,
			});
		}

		transformData();

		self.submitPromise = AuthSvc.register(self.formData).then((response) => {
			self.nearlyThere = true;
			Angularytics.trackEvent("Package Builder", "Signed up");

			delete $localStorage.account;
			delete $localStorage.voucher;
			delete $localStorage.referral;

			let params = {
				email: self.formData.email,
				password: self.formData.password
			};
			self.submitPromise = AuthSvc.loginWithCredentials(params).then((response) => {
				self.processing = false;
				$state.go('main.customer.dashboard.index');
			}, (errors) => {
				self.errors = errors;
			});

		}, (errors) => {
			self.setupLoading = false;
			self.errors = errors;
		});
	};

	function transformData() {
		self.formData = {
			first_name: self.form.details.first_name,
			last_name: self.form.details.last_name,
			email: self.form.details.email,
			password: self.form.details.password,
			property: {
				address: self.form.address,
				room_count: self.form.bedSize,
			}
		};

		let servicesArray = [];
		angular.forEach(self.form.services, (_service) => {
			let service = {
				utility_type: {
					id: _service.utilityType.id,
				},
				meta: {
					usage_type: _service.usageType,
					usage_kwh: _service.usageKwh,
					default_usage_kwh: _service.defaultUsageKwh,
					product_type: {
						id: _service.productType,
					},
				},
				quantity: _service.quantity,
				monthly_spend: _service.monthly_spend,
			};
			servicesArray.push(service);
		});

		if (angular.isObject(self.referral)) {
			angular.extend(self.formData, {
				voucher: self.referral.voucher,
			});
		}

		angular.extend(self.formData, {
			services: servicesArray,
		});

	}

	// Voucher
	handlers.applyVoucher = () => {
		self.voucherError = false;
		return ReferralSvc.validate({code: self.voucherCode}).then((response) => {
			if (angular.isObject(response.data.voucher)) {
				angular.extend(self.form, {
					voucher: response.data.voucher,
				});
				$localStorage.voucher = response.data.voucher;
				self.voucherAdded = true;
				updatePrices();
				$scope.$broadcast('updateBar');
			}
			else {
				self.voucherError = true;
				self.voucherAdded = false;
			}
		});
	};

	handlers.cancelVoucher = () => {
		self.addVoucher = !self.addVoucher;
		self.voucherCode = null;
		self.voucherError = false;
		self.voucherAdded = false;
	};

	handlers.test = () => {
		console.log('running test..');
		scrollTo('bedrooms-step');

	};
}
