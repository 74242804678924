angular.module('app.main.customer')
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {

		$stateProvider
			.state('main.customer.account', {
				url: '/account',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_account/AccountOverview.html',
						controller: 'AccountOverviewCtrl',
						controllerAs: 'Account',
					}
				},
				ncyBreadcrumb: {
					label: 'Account',
				},
			})


		;

	}]);
