angular.module('app.main.customer')
	.factory('QuickStartSvc', function (RequestSvc) {

		function index(params) {
			return RequestSvc.get('api/customer/quick-start/index', params);
		}

		return {
			index: index
		};

	});
