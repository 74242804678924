angular.module('app.main.shared')
	.service('FileUploadSvc', [
		'RequestSvc',
		function (RequestSvc) {

			function deleteFileUpload(fileUploadId) {
				return RequestSvc.get('api/file/delete/' + fileUploadId);
			}

			return {
				'delete': deleteFileUpload
			}

		}
	]);
