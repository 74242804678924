angular.module('app.main.shared.property')
	.component('accountSummaryPanels', {
		templateUrl: 'app/main/modules/customer/_property/_shared/accountSummaryComponent/AccountSummaryPanels.html',
		controller: AccountSummaryPanelsController,
		bindings: {
			account: '<',
		},
	});

AccountSummaryPanelsController.$inject = [
	'$scope',
	'FileDownloadSvc',
];

function AccountSummaryPanelsController(
	$scope,
	FileDownloadSvc,
) {
	let self = this;
	let handlers = {};
	angular.extend(self, {
		handlers: handlers,
	});

	this.$onInit = () => {
		self.breakpoints = [
			{
				breakpoint: 1282,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 948,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},

		];

		$scope.$watch(() => {
			return self.account;
		}, (value) => {
			if (angular.isObject(value)) {
				self.account = value;
			}
		});
	};

	handlers.downloadUsageStatementClicked = (usageStatement) => {
		FileDownloadSvc.download(usageStatement.file_id);
	}


}
