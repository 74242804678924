angular.module('app.main.customer.property')
	.controller('ThanksConfirmationCtrl', ThanksConfirmationCtrl);

ThanksConfirmationCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'AuthSvc',
	'$localStorage',
];

function ThanksConfirmationCtrl($scope, $state, $q, AuthSvc, $localStorage) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: null,
		handlers: handlers,
	});

	this.$onInit = () => {
		self.loading = true;
	};

	// $scope.$on("$destroy", function() {
	// 	delete $localStorage.authToken;
	// });

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.gotoAccountClick = () => {
		AuthSvc.login().then(() => {
			$state.go('main.customer.property.index');
		});
	};
}




