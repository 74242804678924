angular.module('app.main.customer')
    .component('paymentMethodNotices', {
        templateUrl: 'app/main/modules/customer/_settings/_paymentMethods/noticeComponent/paymentMethodNotices.html',
        controller: paymentMethodNoticesCtrl,
    });

paymentMethodNoticesCtrl.$inject = [
    '$q',
    '$filter',
    '$scope',
    'PaymentCardSvc',
    'AuthSvc',
];

function paymentMethodNoticesCtrl(
    $q,
    $filter,
    $scope,
    PaymentCardSvc,
    AuthSvc
) {
    let self = this;
    let handlers = {};

    angular.extend(self, {
        loading: true,
        handlers: handlers,
        paymentMethods: [],
    });

    this.$onInit = () => {
        self.loading = true;
        self.activeAccount = AuthSvc.getRawAccount();
        let paymentMethods = PaymentCardSvc.index({account: {id: self.activeAccount.id}})
            .then((response) => {
                self.paymentMethods = $filter('filter')(response.data.payment_method_collection, (value) => {
                    return value.stripe_pi_client_secret;
                });
            });

        let promises = {
            paymentMethods,
        };

        $q.all(promises).then(() => {
            self.loading = false;
        });
    };
}
