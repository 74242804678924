angular.module('app.main.public')
	.controller('TermsConditionsCtrl', [
		'$log',
		'$scope',
		'$window',
		'$document',
		function ($log,
				  $scope,
				  $window,
				  $document) {


			let self = this;

			angular.extend(self, {
				body: [
					{
						label: 'Intellectual Property and Acceptable Use',
						sub: [
							{
								body: "All content included on the Site is the property of Mango.",
							},
							{
								body: "You acknowledge and agree that all intellectual property rights in all content, and software (if any), that is made available to view and / or download in connection with the Site remains the property of Mango, its associated companies and / or third parties.  Such intellectual property rights include (without limitation) copyrights, database rights, registered designs, unregistered design rights, registered trademarks, passing-off rights or rights protected under competition law.  All such rights are reserved.",
							},
							{
								body: "By continuing to use the Site You acknowledge that such content is protected by copyright, trademarks, database rights and other intellectual property rights."
							},
							{
								body: "Nothing on the Site shall be construed as granting, expressly or by implication, any licence or right to use any trademark, logo or service mark displayed on the Site without prior written permission of Mango."
							},
							{
								body: "You agree, accept and undertake that You will only view and use the Site for Your own private purpose and will not publish, reproduce, store or retransmit any of the content."
							},
							{
								body: "You agree, accept and undertake not to use the Site for any unlawful or unauthorised purpose."
							},
							{
								body: "You agree, accept and undertake not to use or interfere with the Site other than in accordance with these Terms of Use and (without limitation) not in any way so that all or part of the Site or any content is or could be interrupted, reproduced, stored, retransmitted, published, damaged, rendered less efficient, corrupted or in anyway impaired."
							},
							{
								body: "You agree, accept and undertake not to use the Site or the content in any manner which constitutes an infringement of any third party rights (including but not limited to rights of copyright, trade mark or confidentiality)."
							},
							{
								body: "You agree, accept and undertake that in the event You have right, claim, or action against any other user or other third party arising out of the use of the Site You shall pursue such right, claim or action independently of and without recourse to Mango."
							},
							{
								body: "You agree, accept and undertake that all intellectual property rights whatsoever in any Site content shall remain vested in Mango at all times."
							}
						]
					},
					{
						label: "Prohibited Use",
						sub: [
							{
								body: "You may not use the Site:",
								sub: [
									{
										body: "in any way which causes, or might cause, damage to the Site or interferes with any other person’s use or enjoyment of the Site;",
									},
									{
										body: "in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law, regulation, governmental order;",
									},
									{
										body: "to make, transmit or store electronic copies of content protected by copyright without the permission of the owner.",
									}
								]
							}
						]
					},
					{
						label: "Availability of the Site and Disclaimers",
						sub: [
							{
								body: "Any online facilities, tools or services or information that Mango makes available through the Site (the “Service”) is provided without warranties that the Service will be free of defects or faults.  To the maximum extent permitted by law, We provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality.  Mango is under no obligation to update information on the Site.",
							},
							{
								body: "While Mango uses every reasonable endeavour to ensure that the Site and its Service are secure and free of error, viruses and any other malware, the Company gives no warranties or guaranties in that regard and all Users take responsibility for their own security, that of their personal details and their computers."
							},
							{
								body: "Mango accepts no liability for any disruption or non-availability of the Site."
							},
							{
								body: "Mango reserves the right to alter, suspend or discontinue any part (or the whole of) the Site including, but not limited to, any products and / or services available.  These Terms shall continue to apply to any modified version of the Site unless it is expressly stated otherwise.",
							}
						]
					},
					{
						label: "Limitation of Liability",
						sub: [
							{
								body: "Nothing in these Terms will:",
								sub: [
									{
										body: "Limit or exclude our or Your liability for death or personal injury resulting from our or Your negligence, as applicable;",
									},
									{
										body: "limit or exclude our or Your liability for fraud or fraudulent misrepresentation; or"
									},
									{
										body: "limit or exclude any of our or Your liabilities in any way that is not permitted under applicable law."
									}
								]
							},
							{
								body: "We will not be liable to You in respect of losses arising out of any losses arising out of events beyond our reasonable control."
							},
							{
								body: "We will not be liable to You in respect of any advertisements or other web sites which can be accessed directly or indirectly via the Site;"
							},
							{
								body: "We will not be liable to You in respect of any opinions and expressions made by third parties which can be accessed via the Site or which are contained in any content;"
							},
							{
								body: "The information, content, products and services (and related graphics) included in or available through the Site may include inaccuracies or typographical errors.  Changes to the Site may be made from time to time.  We make no representations and give no warranties (including implied warranties) whatsoever relating to the suitability, reliability, availability, accuracy, timeliness, merchantability, fitness for a particular purpose contained in the Site."
							},
							{
								body: "We will not be liable to You in respect of any damage to You, Your computer hardware, software or other material or equipment resulting from Your accessing and / or using the Site and / or any content; and any direct, indirect or consequential loss or damage incurred by any user in connection with our Site or use of our Site."
							},
							{
								body: "Mango does not warrant that Your use of the Site or any content will be uninterrupted or that the operation of the Site or any content will be error free, virus free or secure, or that the Site or any content and the functions of the Site or any content will be merchantable and will meet Your requirements, or that no third parties' rights are infringed thereby."
							},
							{
								body: "Mango shall have no obligation to maintain any content, products and / or services available at any time through the Site."
							},
							{
								body: "All brand names, product names and / or service names used on the Site and within any content are trademarks, trade names, service marks or copyrights of their respective holders.  Any use of any such brand name, product name and / or service name without the express, prior, written consent of the relevant rights holder may constitute an infringement of that rights holder’s rights and Mango gives no permission for the use of any such item in any way whatsoever.",
							}
						]
					},
					{
						label: "General",
						sub: [
							{
								body: "You may not transfer any of Your rights under these Terms to any other person.  We may transfer our rights under these Terms where We reasonably believe Your rights will not be affected."
							},
							{
								body: "Mango may amend or vary these Terms of Use (including the Privacy & cookies policy) from time to time by posting an amended version of these Terms (and any such amendment or variation shall be effective immediately).  You are responsible for regularly reviewing these Terms and any additional terms posted on third party sites.  Continued use of the Site constitutes Your agreement to all such revised Terms of Use.",
							},
							{
								body: "These Terms contain the whole agreement between the parties relating to its subject matter and supersede all prior discussions, arrangements or agreements that might have taken place in relation to the Terms."
							},
							{
								body: "The Contracts (Rights of Third Parties) Act 1999 shall not apply to these Terms and no third party will have any right to enforce or rely on any provision of these Terms."
							},
							{
								body: "If any court or competent authority finds that any provision of these terms and conditions (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these Terms will not be affected."
							},
							{
								body: "Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.",
							},
							{
								body: "This Agreement shall be governed by and interpreted according to the law of England and Wales and all disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts."
							}
						]
					},
					{
						label: "Contact",
						sub: [
							{
								body: "You can contact Mango at any time using <a href='mailto:support@help.hellomango.com'>support@help.hellomango.co</a>, or the contact number provided on the homepage of the Site."
							}
						]
					}


				]
			});

			setIndex(self.body);

			function setIndex(items, prefix) {
				let index = 1;
				angular.forEach(items, (item) => {
					item.index = (angular.isDefined(prefix) ? prefix : '') + index++;
					if (angular.isArray(item.sub)) {
						setIndex(item.sub, item.index + '.');
					}
				});
			}

		}


	]);

