angular.module('app.main.customer')
	.factory('ContractTermsSvc', function (RequestSvc) {

		function view(params) {
			return RequestSvc.get('api/contract-terms/view', params);
		}

		return {
			view: view
		};

	});
