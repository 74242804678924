angular.module('app.main.public.connection-acknowledgement')
    .controller('ConnectionAcknowledgementCtrl', ConnectionAcknowledgementCtrl);

ConnectionAcknowledgementCtrl.$inject = [
    '$location',
    '$log',
    '$scope',
    '$state',
    '$window',
    '$document',
    '$mdSidenav',
    '$q',
    'RequestSvc',
    'ConnectionAcknowledgementSvc',
];

function ConnectionAcknowledgementCtrl(
    $location,
    $log,
    $scope,
    $state,
    $window,
    $document,
    $mdSidenav,
    $q,
    RequestSvc,
    ConnectionAcknowledgementSvc
) {
    let self = this;
    let handlers = {};

    angular.extend(self, {
        loading: true,
        params: {
            'token': $location.search().t,
        },
        request: {},
        supplierLabel: '',
    });

    this.$onInit = () => {

        let promises = {
            connectionRequest: ConnectionAcknowledgementSvc.getConnectionRequest(self.params),
        };

        $q.all(promises)
            .then((responses) => {
                self.request = responses.connectionRequest.data.connectionRequest;
                self.supplierLabel = responses.connectionRequest.data.supplier_label;
            }, function () {
                $state.go('main.public.index');
            })
            .finally(() => {
                self.loading = false;
            });
    };

    // Handlers --------------------------------------------------------------------------------------------------------

}
