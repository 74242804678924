angular.module('app.main.public.magic-link')
    .factory('MagicLinkSvc', function (
        RequestSvc
    ) {

        function loginMagicLink(credentials) {
            return RequestSvc.post('api/login-magic-link', credentials)
        }

        return {
            loginMagicLink: loginMagicLink
        };
    });