
angular.module('app.main')
	.directive('avatar', function () {
		return {
			restrict: 'E',
			scope: {
				user: '=',
				size: '=',
				showTooltip: '='
			},
			replace: true,
			templateUrl: 'app/main/directives/avatar/Avatar.html',
			link: function (scope) {
				if (!isNaN(parseInt(scope.size))) {
					scope.customSize = true;
				}

				if (angular.isUndefined(scope.showTooltip)) {
					scope.showTooltip = true;
				}
			}
		};
	});

