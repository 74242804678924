angular.module('app.main.public')
	.controller('PrivacyPolicyCtrl', [
		'$log',
		'$scope',
		'$state',
		'$window',
		'$document',
		'$timeout',
		function ($log,
				  $scope,
				  $state,
				  $window,
				  $document,
				  $timeout) {


			let self = this;
			let handlers = {};

			angular.extend(self, {
				handlers: handlers,
				contents: [
					{
						label: 'Introduction',
					},
					{
						label: 'How we Collect Data and Information',
					},
					{
						label: 'What We Collect',
					},
					{
						label: 'Mango Assurances',
					},
					{
						label: 'Data Protection Principles',
					},
					{
						label: 'Information Collected',
					},
					{
						label: 'Why We Collect the Data and Information and How We Use It',
					},
					{
						label: 'How Mango Shares Data and Information',
					},
					{
						label: 'Credit Referencing and Fraud Prevention',
					},
					{
						label: 'Duration of Storage of Data and Information',
					},
					{
						label: "Customers' Rights to Correct, Access and Request the Erasure of Data and Information",
					},
					{
						label: 'Keeping Information Secure',
					},
					{
						label: 'How to Complain',
					},
					{
						label: 'Cookies',
					},
				],
				body: [
					{
						label: 'Introduction',
						body: "At Mango, we are passionate about your security and privacy.  We've all seen the terrible consequences of misused data, so Mango does – and will do – everything necessary to ensure that any information personal and private  to you is protected at all times.  We want our customers to trust us, so we have drafted and will adhere systematically to a rigorous data-management system, in accordance with the General Data Protection Regulation (GDPR), all European and British data legislation, and the parameters of sound judgment and informed discretion.  We have adopted fully the principles of this important Regulation when managing our clients' data and any information unique to them.  For this reason, we are never going to sell or release any collected or stored information we hold about an individual to anyone (“Personal Data”).  We believe that companies, like people, should aim for and adopt a culture of respect and trust because we think that's just nicer for everyone.\n" +
							"\n" +
							"Please ensure that you read this notice and any other notices we may provide to you from time to time when we collect or process personal information about you.  The most recent version of this notice will always be available on our Website.\n.\n",
					},
					{
						label: 'How we Collect information',
						body: "",
						sub: [
							{
								body: "Mango may collect the following information from (1) customers either directly when they register to use our services, and / or (2) from customers when they use our services and Website.  We may collect this information directly from customers using online registration forms or telephone calls.",

							},
							{
								body: "We may also collect some information indirectly by customers' use of our services and websites.",
							}
						]
					},
					{
						label: 'Data We Collect',
						sub: [
							{
								body: "Mango is a 'data controller' according to the applicable law.  We collect and use certain information about customers.",
							},
							{
								body: 'Personal and Company Data',
								sub: [
									{
										body: "Personal Data is any information that relates to an identified or identifiable individual.  The Personal Data that is provided to us directly through our Website will be apparent from the context in which it has been provided.  Company Data is any information that relates to a company or a business.  Personal and Company Data are provided to us directly through our Website, and will be apparent from the context in which they have been provided to us by our customers.",
									},
								]
							},
							{
								body: "Types of Personal and Company Data",
								sub: [
									{
										body: "When anyone registers for a Mango account we will collect their full name, email address, account log-in credentials, date of birth and the addresses of the properties to be registered with Mango.  When they complete our online form to make contact with our sales team, we will collect their full name, postal and email addresses, date of birth and and anything else a customer chooses to share with us.",
									},
									{
										body: "Anything a customer shares with us in emails or any correspondence more generally, whether sent electronically or in paper form, will be stored for the benefit of the customer.  If we are contacted by phone, we will collect the phone number used to call us. If we are contacted by phone by a previously signed customer we may collect additional information in order to verify that customer's identity.",
									},
									{
										body: "As part of a customer's business relationship with us, we may also receive financial and personal information, such as dates of birth and bank account details.",
									},
									{
										body: "Signed customers (whether as individuals or as companies and businesses) will be required on occasion to make payments to Mango to when customers make payments or conduct transactions through the Mango Website or any Mango remote application, we will receive customers' transaction information.  Depending on how the customer implements our services, we may receive this information directly from a customer or third parties.  The information we collect will include payment method information (such as credit or debit card numbers, or bank account information), purchase amounts, dates of purchases, and payment methods.  Different payment methods may require the collection of different categories of information.  The customer will determine the payment method, where appropriate, and the information we collect will depend upon the payment method chosen by a customer from the list of available payment methods provided by Mango.",
									},
									{
										body: "When we conduct fraud monitoring, prevention and detection activities, we may also receive personal data about customers from our business partners, financial service providers, identity verification services, and publicly available sources (e.g., name, address, phone number, country), as necessary to confirm a customer's identity and prevent fraud.  Our fraud monitoring, detection and prevention services may use technology that helps us assess the risks associated with an attempted transaction that is enabled on the Mango Website or any remote application that collects information.",
									},
									{
										body: "Customers may choose to submit information to us via other methods, including: (i) in responses to marketing or other communications, (ii) through social media or online forums, (iii) through participation in offers, programs or promotions, (iv) in connection with an actual or potential business relationship with us, or (v) by giving us their business card or contact details at trade shows or other events.",
									}
								],
							},


						]
					},
					{
						label: 'Mango Assurances',
						sub: [
							{
								body: "Mango assures all its customers (individuals and companies):",
								sub: [
									{
										body: "to apply all the safeguards we need to protect customers' data – physically and digitally;",
									},
									{
										body: "to collect only the information we need to provide Mango's services to customers;",
									},
									{
										body: "to allow only trained members of the Mango team access to customers' data.  Even then, they will only ever see what they absolutely need to;",
									},
									{
										body: "to share customers' data with third parties only if a customer has told us that we can, or we are required to as an operation of law, or we have let a customer know previously that we need to",
									}
								]
							}
						]
					},
					{
						label: "Data Protection Principles",
						sub: [
							{
								body: "Mango will comply with the data protection principles when gathering and using personal and company information, in accordance with this Privacy Policy.",
							}
						]
					},
					{
						label: "Information Collected",
						sub: [
							{
								body: "Mango may collect the following information from customers either directly when they register to use our services and / or from customers when they use our services and Website:",
								sub: [
									{
										body: "name and contact details (email address, telephone number, postal address) and payment details;",
									},
									{
										body: "security question(s) and answer information, including a customer’s date of birth;",
									},
									{
										body: "computer addresses such as IP addresses, and where appropriate customers' device's MAC address;",
									},
									{
										body: "computer information related to a customer's use of our Website using cookies or other anonymous identifiers (geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation);",
									}
								]
							}
						]
					},
					{
						label: "Why We Collect the Data and Information and How We Use It",
						sub: [
							{
								body: "We will typically use any information or data collected for the following purposes:",
								sub: [
									{
										body: "the performance of a contract with customers, or to take steps to enter into a contract;",
									},
									{
										body: "for compliance with a legal obligation;",
									},
									{
										body: "for the purposes of Mango's legitimate interests or those of trusted third parties, but only if these are not overridden by customers' interests, rights or freedoms.  Mango will always ensure that our information collection and processing are proportionate.  We will always notify customers of any material changes to (1) the information we collect or (2) the reasons for collecting it.  The latest policy will always be available on our Website.",
									}
								]
							},

						]
					},
					{
						label: "How Mango Shares Data and Information",
						sub: [
							{
								body: "Mango may need to share customers' personal information with third parties.  The recipients of any data and information will be bound by confidentiality obligations, as well as our own security requirements.",
							},
							{
								body: "Mango's data and information-use requirements include:",
								sub: [
									{
										body: "external processing (we may share personal information to enable us to process it, based on our instructions and in compliance with this notice and other security measures);",
									},
									{
										body: "legal reasons (we may share personal information where disclosing this information will meet any applicable laws, regulations or legal processes.  We may also disclose this information where detecting, preventing or addressing fraudulent activities and other types of security issues.  Where relevant, information may be shared with regulatory bodies, credit reference agencies and law enforcement);",
									},
									{
										body: "company disposal (potential buyers of some or all of our business, or the company when re-structuring, may require Mango to share data and information.  Where at all possible, data and information will be anonymised;",
									},
									{
										body: "with other suppliers (we may share information with previous or new suppliers to allow Mango to transfer a customer's services, including information about meter readings and equipment or charges owed to previous suppliers);",
									},
									{
										body: "with parties interested in customers' meters (If we suspect a tenant or customer is committing or has committed fraud or stolen energy by tampering with electricity or gas meters or by diverting the energy supply, Mango will share this information with Ofgem and any other interested parties (such as energy suppliers, housing associations, and any other interested authority);",
									},
									{
										body: "with organisations that may be able to help customers (If we believe that a customer or a member of a customer's household needs extra care, by reason of age, health, disability or financial circumstances, then we may record and share this information with:",
										sub: [
											{
												body: "social services, charities, health care and other support organisations, if we believe at any time that they may be able to help a customer, or the other members of a household, by making sure there is a gas or electricity supply to a customer's home",
											}
										]
									},
									{
										body: "with other energy suppliers if we believe a customer is considering changing supplier;",
									},
									{
										body: "the relevant gas transporter, metering agents or network operators;",
									},
									{
										body: "credit reference and fraud prevention agencies.",
									}
								]
							}
						]
					},
					{
						label: "Credit Referencing and Fraud Prevention",
						sub: [
							{
								body: "As a function of good policy, Mango may check customers' details with one or more credit reference and fraud prevention agencies to help us decide whether there is a risk that a customer might not be able to pay their bills, and also to help us make decisions about the goods and services we can offer.",
							},
							{
								body: "Mango may carry out searches using credit reference and fraud prevention agencies for information about customers.  If customers give Mango false or inaccurate information and we suspect fraud then we will pass the relevant details to credit reference and fraud prevention agencies, and law enforcement agencies (such as the police and HM Revenue & Customs).",
							},
							{
								body: "Mango and other organisations may also see and use information about customers that credit reference and fraud prevention agencies may provide us so that we can:",
								sub: [
									{
										body: "check details on applications customers make for credit and credit-related services;",
									},
									{
										body: "confirm a customer's identity;",
									},
									{
										body: "prevent and detect fraud and money laundering;",
									},
									{
										body: "manage credit and credit-related accounts or services;",
									},
									{
										body: "recover debt;",
									},
									{
										body: "check details on proposals and claims for all types of insurance;",
									},
									{
										body: "check details of employees and people applying for jobs with us.",
									}
								]
							},
							{
								body: "When credit reference agencies receive a search from UM, they will record this on a customer's credit file whether the application is successful or not.  If a customer has an account with UM, we may provide this to credit reference agencies.  If a customer has an account and does not repay any money owing in full or on time then credit reference agencies will record this debt.  They may give this information to other organisations and fraud prevention agencies to carry out similar checks, or to find out where a customer is residing, so as to deal with any money that is owing.  Credit reference agencies keep records for six years after an account has been closed, or the customer has paid the debt or action has been taken against the customer to recover it.",
							},
							{
								body: "Mango and other organisations may see and use information from other countries that is recorded by fraud prevention agencies.",
							},
							{
								body: "If a customer wants to see what information credit reference and fraud prevention agencies hold about them they can contact those agencies in the UK.",
							}
						]
					},
					{
						label: "Where Data and information May be Held",
						sub: [
							{
								body: "Data and information may be held at our offices and those of our group companies, and third party agencies, service providers, representatives and agents as described above.  Data and information may be transferred internationally to other countries around the world, including countries that do not have data protection laws equivalent to those in the UK, for the reasons described above.  We have security measures in place to seek to ensure that there is appropriate security for information we hold.",
							}
						]
					},
					{
						label: "Duration of Storage of Data and Information",
						sub: [
							{
								body: "We keep customers' information during and after the activation of a contract, but for no longer than is necessary for the purposes for which the information is processed. This means that Mango deletes a customers' data within 12 months of the end of a contract, unless we are obliged to retain that data and information for longer in consequence of legal obligations, dispute resolution, or the enforcing of agreements.  Where we need to hold information for longer, we will hold only what is necessary, in consequence of our obligations.",
							}
						]
					},
					{
						label: "Customers' Rights to Correct, Access and Request the Erasure of Data and Information",
						sub: [
							{
								body: "Every customer can make contact with Mango's Data Protection Officer (“DPO”). Their contact details are listed at the bottom of this Policy document.  If you would like to request access to or correct information that we hold relating to you (in accordance with applicable law and subject to identity verification), or you have any questions concerning this notice, you should contact Mango's DPO.",
							},
							{
								body: "Customers have the right to object to the processing of data and information, or to ask us to restrict the processing of data and information, as well as requesting the portability of data and information."
							},
							{
								body: "Customers have the right to ask our DPO for data or information that we hold and process to be erased (known otherwise as the “right to be forgotten”), but only in certain circumstances.  Our DPO will provide you with further information about the right to be forgotten on request.",
							}
						]
					},
					{
						label: "Keeping Information Secure",
						sub: [
							{
								body: "We have appropriate security measures in place to prevent personal information from being accidentally lost, used or accessed in an unauthorised way.  We limit access to data and information to those who have a genuine business need to know it.  Any individuals processing your information will do so only in an authorised manner and are subject to a duty of confidentiality.",
							},
							{
								body: "We also have procedures in place to deal with any suspected data security breach.  We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.",
							}
						]
					},
					{
						label: "How to complain",
						sub: [
							{
								body: "We trust that our DPO can resolve any query or concern that a customer may raise about the use of your information.  If you are unhappy with a response from Mango's DPO then you are free to make contact with the Information Commisioner at ico.org.uk/concerns/ or telephone: 0303 123 1113.  They can and will provide you with further information about your rights and how to make a formal complaint.<br><br><strong>Data Protection Officer Contact Information</strong><br><br>Name: James Aveyard<br>Email: support@help.hellomango.co"
							}
						]
					},
					{
						label: "Cookies",
						id: 'cookies',
						sub: [
							{
								body: "A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and stored by the browser.  The identifier is then sent back to the server each time the browser requests a page from the server.  This enables the web server to identify and track the web browser.  We may use both “session” cookies and “persistent” cookies on the website.  Session cookies will be deleted from a customer's computer when a browser is closed.  Persistent cookies will remain stored on a computer until deleted, or until they reach a specified expiry date.",
							},
							{
								body: "Mango uses cookies in the following circumstances:",
								sub: [
									{
										body: "<strong>Google Analytics tracking cookies</strong> We use Google Analytics to gain insight into, among other things, visitor flows, traffic sources and page views on our website. For more information, please refer to Google's Privacy Policy (https://policies.google.com/privacy). If you wish to avoid this tracking, you can customize the settings of your browser so that it refuses cookies.",
									},
									{
										body: "<strong>Google AdWords tracking cookies</strong> As part of its digital advertising, Mango uses Google AdWords to remarket content to website visitors once they have viewed Mango's Website and exit the site.  To this end, a tracking cookie will be used to serve adverts to customers on other websites based on their usage of the Mango website.  To opt out of Google's cookies please visit Google's Ads Settings.  Alternatively, please visit the Network Advertising Initiative “opt-out” page.",
									}
								]
							},
							{
								body: "For more information on how to disable cookies, please visit <a href='www.allaboutcookies.org' target='_blank'>www.allaboutcookies.org</a>"
							},
							{
								body: "Customers may also set their browsers to block all cookies, including cookies associated with Mango's services, or to indicate when a cookie is being set by us.  However, it's important to remember that many of our services may not function properly if cookies are disabled.",
							},
							{
								body: "This privacy and cookie policy covers the use of cookies on this website. It does not cover the use of cookies by third parties.",
							}
						]
					}

				]
			});

			handlers.init = () => {
				if (angular.isDefined($state.params.cookies)) {
					$timeout(() => {
						handlers.goToCookies();
					},800);
				}
			};

			handlers.goToCookies = () => {
				let someElement = angular.element(document.getElementById('cookies'));
				let $element = angular.element('html');
				$element.scrollToElement(someElement, 100, 750);
			};

			setIndex(self.body);

			function setIndex(items, prefix) {
				let index = 1;
				angular.forEach(items, (item) => {
					item.index = (angular.isDefined(prefix) ? prefix : '') + index++;
					if (angular.isArray(item.sub)) {
						setIndex(item.sub, item.index + '.');
					}
				});
			}

		}




	]);

