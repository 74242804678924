angular.module('app.main.shared.utility')
	.factory('UtilityAccountSvc', [
		'RequestSvc',
		function (RequestSvc) {

			return {
				index: function (params) {
					return RequestSvc.get('api/customer/account/index', params);
				},
				options: function (params) {
					return RequestSvc.get('api/customer/account/options', params);
				},
				save: function (account) {
					return RequestSvc.post('api/customer/account/save', account);
				},
				read: function (id) {
					return RequestSvc.get('api/customer/account/read/' + id);
				},
				archive: function(id) {
					return RequestSvc.post('api/customer/account/archive', id);
				},
				bills: function(param) {
					return RequestSvc.get('api/customer/bill/index', param);
				},
				reorder: function(param) {
					return RequestSvc.post('api/customer/account/reorder', param);
				},
				billChart: function(param) {
					return RequestSvc.get('api/customer/account/bill-chart', param);
				}
			};

		}
	]);
