angular.module('app.main')
    .directive('apiImg', function($http, $q, $localStorage, RequestSvc) {

        function _arrayBufferToBase64(buffer) {
            let binary = '';
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                file: '=',
                classes: '=',
            },
            template: '<div class="api-img" ng-show="encodedPath"><img ng-src="{{encodedPath}}" ng-class="classes"/></div>',
            link: function(scope) {
                    // scope.path = "https://www.utilitymix.com/images/v3/logo.png" // Testing

                    $http({
                        url: scope.file.url,
                        method: 'GET',
                        responseType: 'arraybuffer',
                        headers: {
                            AccessToken: angular.isObject($localStorage.authToken) ? $localStorage.authToken.token : null,
                        },
                    })
                        .then(function (response) {
                            let base64Image = _arrayBufferToBase64(response.data);
                            scope.encodedPath = 'data:'+scope.file.mime+';base64,'+base64Image;
                        }, function (error) {
                            scope.encodedPath = "app/main/directives/api-img/error.png";
                        });
                }
            };
    });
