angular.module('app.main.public.auth')
	.controller('ResetPasswordCtrl', ResetPasswordCtrl);

ResetPasswordCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$timeout',
	'notify',
	'AuthSvc',
];

function ResetPasswordCtrl($scope, $state, $q, $timeout, notify, AuthSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		submitted: false,
	});

	this.$onInit = () => {
		// Validate that the key is valid
		if (angular.isString($state.params.key)) {
			AuthSvc.validatePasswordToken({
				token: $state.params.key
			}).then(function(response) {
				self.validated = true;
				self.user = response.data.user;
				self.loading = false;
			}, function(errors) {
				self.formErrors = errors;
				self.loading = false;
			}).finally(() => {

			});
		}
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function (isValid) {
		self.submitted = true;
		// Validate password
		self.passwordValidation = true;
		let pwdTest = /^(?=.*\d).{6,}$/;
		if(!pwdTest.test(String(self.form.password))) {
			self.passwordValidation = false;
			isValid = false;
			self.submitted = false;
		}

		if (isValid) {
			angular.extend(self.form, {
				key: $state.params.key
			});
			return AuthSvc.resetPassword(self.form)
				.then(function (response) {
					notify({
						message: 'Password updated, logging in..'
					});
					/** Not ideal faking delay, however due to notification overlap */
					$timeout(() => {
						AuthSvc.redirect();
					}, 500);
				}, function (errors) {
					self.formErrors = errors;
				});
		}
		else {
			self.form.submited = true;
			self.submitted = false;
		}
	};
}