angular.module('app.main.customer.property')
	.controller('PropertyAddCtrl', PropertyAddCtrl);

PropertyAddCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'focus',
	'$timeout',
	'notify',
	'$filter',
	'WizardHandler',
	'Angularytics',
	'$intercom',
	'PropertySvc',
	'AuthSvc',
	'AccountSvc',
	'PropertyTypeSvc',
];

function PropertyAddCtrl(
	$scope,
	$state,
	$q,
	focus,
	$timeout,
	notify,
	$filter,
	WizardHandler,
	Angularytics,
	$intercom,
	PropertySvc,
	AuthSvc,
	AccountSvc,
	PropertyTypeSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		activeStepIndex: 0,
		activeStepName: '',
		isLastStep: false,
		submitPromise: null,
		options: {},
		form: {
			// account: {},
			address: {},
		},
	});

	self.steps = [
		// {
		// 	index: 0,
		// 	title: 'What type of property would you like to add?',
		// 	shortTitle: 'Property type',
		// 	description: '',
		// 	value: 'property_type',
		// 	template: 'app/main/modules/customer/_property/_add/partials/PropertyType.html',
		// 	submitted: false,
		// 	completed: false,
		// 	disabled: false,
		// },
		{
			index: 1,
			title: "Find your property address",
			shortTitle: 'Address',
			description: 'Type in your postcode below to lookup your property address',
			value: 'address',
			template: 'app/main/modules/customer/_property/_add/partials/Address.html',
			submitted: false,
			completed: false,
			disabled: false,
		},
		{
			index: 2,
			title: "How many bedrooms does your property have?",
			shortTitle: 'Beds',
			description: 'We can provide more accurate costs when we know how big your property is',
			value: 'beds',
			template: 'app/main/modules/customer/_property/_add/partials/Beds.html',
			submitted: false,
			completed: false,
			disabled: true,
		},
		{
			index: 3,
			title: "Which account will you use to pay for your services?",
			shortTitle: 'Account',
			description: "",
			value: 'account',
			template: 'app/main/modules/customer/_property/_add/partials/Account.html',
			submitted: false,
			completed: false,
			disabled: true,
		},
		{
			index: 4,
			title: "Add your new account details",
			shortTitle: 'New Account',
			description: "We'll only ask for your payment details when you add Mango services",
			value: 'new_account',
			template: 'app/main/modules/customer/_property/_add/partials/NewAccount.html',
			submitted: false,
			completed: false,
			disabled: true,
		}
	];

	this.$onInit = () => {
		self.CustomerCtrl = $scope.Customer;
		self.CustomerCtrl.backButtonConfig.hide = true; // initially hide header back button
		self.accounts = AuthSvc.getRawAccounts();
		self.activeAccount = AuthSvc.getRawAccount();



		let promises = {
			accounts: AccountSvc.index(),
			// types: PropertyTypeSvc.options(),
		};

		$q.all(promises)
			.then((responses) => {
				self.accounts = responses.accounts.data.accounts;
				// self.options.propertyTypes = responses.types.data.options;
				toggleBedroomsStep(true);
				toggleAccountStep();
				self.loading = false;
			}, (error) => {
				console.log(error);
			});
	};

	$scope.$on('navBackButtonClicked', () => {
		handlers.backClick();
	});

	$scope.$on('wizard:stepChanged', function (event, args) {
		let lastEnabledStep = getLastEnabledStep();
		self.isLastStep = args.step.wzData.index === lastEnabledStep.index;
		self.activeStepIndex = args.index;
		self.activeStepValue = args.step.wzTitle;
		setFocus();
		self.CustomerCtrl.backButtonConfig.hide = args.index === 0; // show/hide header back button
	});

	function getLastEnabledStep() {
		let enabledSteps = $filter('filter')(self.steps, (step) => {
			return !step.disabled;
		});
		if (enabledSteps.length) {
			return enabledSteps[enabledSteps.length - 1];
		}
	}

	function toggleBedroomsStep(addStep) {
		angular.extend(getStepByValue('beds'), {
			disabled: !addStep,
		});
	}

	function toggleAccountStep() {
		self.options.accounts = self.accounts;
		// self.options.accounts = $filter('filter')(self.accounts, (account) => {
		// 	return account.property_type.id === self.form.property_type.id;
		// });


		// Show or hide the account step based on whether we already have an account with same property type
		angular.extend(getStepByValue('account'), {
			disabled: !self.options.accounts.length,
		});

		if (self.options.accounts.length) {
			self.form.account = self.options.accounts[0]; // default to the first account
		}
	}

	function getStepByValue(value) {
		let stepFound = null;
		angular.forEach(self.steps, (step) => {
			if (step.value === value) {
				stepFound = step;
			}
		});
		return stepFound;
	}

	function setFocus() {
		if (self.activeStepValue === 'address') {
			focus('postcode');
		}
		else if (self.activeStepValue === 'nickname') {
			focus('property_label');
		}
	}

	function validateStep(step) {
		let validated = true;
		if (step.value === 'property_type') {
			if (!self.form.property_type) {
				step.errors = ["You must choose a property type"];
				validated = false;
			}
		}
		else if (step.value === 'address') {
			if (angular.isUndefined(self.form.address) || !self.form.address.line_1) {
				step.errors = ["You must supply your address"];
				validated = false;
			}
		}
		return validated;
	}

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.goToStep = (step) => {
		WizardHandler.wizard()
			.goTo(step.value);
	};

	handlers.propertyTypeClick = (type) => {
		angular.extend(getStepByValue('property_type'), {
			errors: null,
		});
		if (type.enabled) {
			self.form.property_type = type;
			toggleBedroomsStep(self.form.property_type.require_bedrooms);
			toggleAccountStep();
		}
	};

	handlers.postcodeSearchClick = () => {
		self.searchingAddress = true;
		return PropertySvc.addressLookup({postcode: self.postcode_entered})
			.then((response) => {
				self.options.addresses = response.data.addresses;
				self.searchingAddress = false;
			});
	};

	handlers.selectAddressClick = (address) => {
		self.form.address = address;
	};

	handlers.manualEntryClick = () => {
		self.isManualAddress = true;
		focus('line_1');
	};

	handlers.removeManualAddress = () => {
		self.isManualAddress = false;
		self.form.address = undefined;
		focus('postcode');
	};

	handlers.newAccountClick = () => {
		self.form.account = null;
		self.newAccount = true;
		angular.extend(getStepByValue('new_account'), {
			disabled: false,
		});

		let lastEnabledStep = getLastEnabledStep();
		self.isLastStep = WizardHandler.wizard()
			.currentStep().wzData.index === lastEnabledStep.index;

	};

	handlers.accountClick = (account) => {
		self.form.account = account;
		self.newAccount = false;
		angular.extend(getStepByValue('new_account'), {
			disabled: true,
		});

		let lastEnabledStep = getLastEnabledStep();
		self.isLastStep = WizardHandler.wizard()
			.currentStep().wzData.index === lastEnabledStep.index;
	};

	handlers.continueClick = (isValid, step, form) => {
		step.errors = null;
		if (!isValid) {
			step.submitted = true;
			step.errors = [];

			for (let key in form.$error) {
				for (let index = 0; index < form.$error[key].length; index++) {
					let errorField = form.$error[key][index].$name;
					errorField = errorField.replace(/^\w/, c => c.toUpperCase());
					step.errors.push(errorField + ' is required.');
				}
			}
			return;
		}

		if (validateStep(step)) {
			step.completed = true;

			let lastEnabledStep = getLastEnabledStep();
			if (step.index === lastEnabledStep.index) {
				return handlers.submitClick();
			}
			else {
				WizardHandler.wizard()
					.next();
			}

		}
	};

	handlers.backClick = () => {
		WizardHandler.wizard()
			.previous();
	};

	handlers.submitClick = () => {
		// Track if we're adding the property to a different account than the active account
		let accountDifferentFromActive = false;


		if (angular.isObject(self.activeAccount)) {
			accountDifferentFromActive = parseInt(self.form.account.id) !== parseInt(self.activeAccount.id);
		}

		self.submitPromise = PropertySvc.save(self.form)
			.then((response) => {
				notify({
					message: 'Property added',
				});
				AuthSvc.setAccount(response.data.account);
				$scope.$emit('propertyAdded', response.data.property); // Update Customer
				$timeout(() => {
					if ($state.params.redirectTo === 'quick-start') {
						$state.go('main.customer.dashboard.quick-start-guide');
					}
					else if ($state.params.redirectTo === 'account-payment') {
						$state.go('main.customer.account-payment', {showDifferentAccountNotice: accountDifferentFromActive});
					}
					else {
						$state.go('main.customer.property.overview', {propertyId: response.data.property.id});
					}
				});
			});


	};
}
