angular.module('app.main.customer')
	.factory('AccountSvc', function (RequestSvc, $mdDialog) {

		function index(params) {
			return RequestSvc.get('api/customer/account/index', params);
		}

		function read(params) {
			return RequestSvc.get('api/customer/account/read', params);
		}

		function save(params) {
			return RequestSvc.post('api/customer/account/save', params);
		}

		function secureAccountSave(params) {
			return RequestSvc.post('api/customer/account/secure-account-save', params);
		}

		function openAccountModal(account) {
			return $mdDialog.show({
				templateUrl: 'app/main/modules/customer/_account/_add/AccountAddModal.html',
				controller: 'AccountAddModalCtrl',
				controllerAs: 'AccountSave',
				locals: {
					account: account
				},
				preserveScope: true,
				clickOutsideToClose: false,
				escapeToClose: true
			});
		}

		return {
			index,
			read,
			save: save,
			secureAccountSave: secureAccountSave,
			openAccountModal: openAccountModal
		};

	});
