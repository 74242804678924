angular.module('app.main.customer')
	.component('accountHistoryList', {
		templateUrl: 'app/main/modules/customer/components/accountHistoryList/AccountHistoryList.html',
		controller: AccountHistoryListCtrl,
		bindings: {
			account: '<',
		},
	});

AccountHistoryListCtrl.$inject = [
	'$window',
	'$rootScope',
	'$scope',
	'$global',
	'CustomerAccountSvc',
	'FileDownloadSvc',
];

function AccountHistoryListCtrl(
	$window,
	$rootScope,
	$scope,
	$global,
	CustomerAccountSvc,
	FileDownloadSvc,
) {
	let self = this;
	let handlers = {
		onLoadOlderClicked: loadOlder,
		onLoadNewerClicked: loadNewer,
		onDownloadUsageStatementClicked: downloadUsageStatement,
	};

	// By default last year including this month is shown
	let dateTo = moment.utc().endOf('month');
	let dateFrom = moment.utc().startOf('month').subtract(11, 'months');
	angular.extend(self, {
		loaded: false,
		errors: [],
		query: {},
		refreshPromise: null,
		monthCollection: [],
		canLoadOlder: canLoadOlder,
		canLoadNewer: canLoadNewer,
		handlers: handlers,
	});

	this.$onInit = () => {
		$scope.in_array = $rootScope.in_array;
		self.config = angular.copy($global.config);
		angular.merge(self.query, {
			account: {
				id: self.account.id,
			},
		});
		initDates();
		self.loaded = true;
		initWatches();
	};

	let initWatches = () => {
		$scope.$watch(() => {
			return angular.copy(self.query);
		}, (newQuery, oldQuery) => {
			refresh();
		}, true);
	};

	let initDates = () => {
		self.minDate = moment.utc(self.account.created_at).startOf('month');
		self.maxDate = moment.utc().endOf('month');
		self.dateTo = self.maxDate.clone();
		// normally show 12 months if there are enough valid months
		let suggestedDateFrom = self.dateTo.clone().startOf('month').subtract(11, 'month');
		self.dateFrom = moment.max(self.minDate.clone(), suggestedDateFrom);
		angular.merge(self.query, {
			filter: {
				dateFrom: self.dateFrom.toISOString(),
				dateTo: self.dateTo.toISOString(),
			}
		});
	};

	let refresh = () => {
		if (self.loaded !== true) {
			return;
		}
		if (self.refreshPromise) {
			self.refreshPromise.cancel();
		}
		self.errors.length = 0;
		self.refreshing = true;

		if (self.query.account.id) {
			self.refreshPromise = CustomerAccountSvc.listHistory(self.query);
			self.refreshPromise.then((response) => {
				self.historyCount = response.data.history_filtered_count;
				self.monthCollection = response.data.month_collection;
			}, (errors) => {
				if (angular.isDefined(errors)) {
					angular.merge(self.errors, errors);
				}
			});
			self.refreshPromise.finally(() => {
				self.refreshing = false;
			});
		}
		else {
			// No account selected - clear history list
			self.monthCollection = [];
			self.refreshing = false;
		}
	};

	function canLoadOlder() {
		return self.dateFrom.isAfter(self.minDate);
	}

	function loadOlder() {
		self.dateFrom.subtract(12, 'months');
		self.dateTo = self.dateFrom.clone().add(11, 'months').endOf('month');
		applyDates();
	}

	function canLoadNewer() {
		return self.dateTo.isBefore(self.maxDate);
	}

	function loadNewer() {
		self.dateFrom.add(12, 'months');
		self.dateTo = self.dateFrom.clone().add(11, 'months').endOf('month');
		applyDates();
	}

	function applyDates() {
		let appliedFromDate = moment.max(self.minDate, self.dateFrom);
		let appliedToDate = moment.min(self.maxDate, self.dateTo);
		angular.extend(self.query.filter, {
			dateFrom: appliedFromDate.toISOString(),
			dateTo: appliedToDate.toISOString(),
		});
	}

	function downloadUsageStatement(usageStatement) {
		FileDownloadSvc.download(usageStatement.file_id);
	}
}
