angular.module('app.main.shared').factory('UtilitySupplierSvc', function (RequestSvc) {

	function index(params) {
		return RequestSvc.get('api/supplier/index', params);
	}

	function options(params) {
		return RequestSvc.get('api/supplier/options', params);
	}

	//function save(property) {
	//	return RequestSvc.post('api/customer/property/save', property);
	//}
	//
	//function read(id) {
	//	return RequestSvc.get('api/customer/property/read/' + id);
	//}

	return {
		index: index,
		options: options
		//save: save,
		//read: read
	};

});
