angular.module('app.main.customer.menu')
    .controller('CustomerMenuCtrl', CustomerMenuCtrl);

CustomerMenuCtrl.$inject = [
    '$scope',
    '$state',
];

function CustomerMenuCtrl(
    $scope,
    $state,
) {

    let self = this;
    let handlers = {};

    angular.extend(self, {
        loading: true,
        handlers: handlers,
        popoverConfig:  {
            xPosition: 'right',
            yPosition: 'bottom',
            openOnHover: false,
            minWidth: 250,
        },
    });

    this.$onInit = () => {
       //
    };

    // Handlers --------------------------------------------------------------------------------------------------------

    handlers.test = (params) => {
        console.log(params);
    };
}
