angular.module('app.main').factory('HardSoftDeleteDialog', function ($mdDialog) {

	function show(title, subTitle, cancelText) {
		return $mdDialog.show({
			controller: 'HardSoftDeleteDialogCtrl',
			templateUrl: 'app/main/directives/hardSoftDeleteConfirmation/Dialog.html',
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			locals: {
				data: {
					title: title,
					subTitle: subTitle,
					cancelText: cancelText
				}
			}
		});
	}

	return {
		show: show
	};

});
