angular.module('app.main.public')
	.component('simplePackageSelectorComponent', {
		templateUrl: 'app/main/modules/public/components/simple-package-selector/SimplePackageSelectorComponent.html',
		controller: SimplePackageSelectorController,
		bindings: {

		},
	});

SimplePackageSelectorController.$inject = [
	'$scope',
	'$state',
	'$localStorage',
	'$filter',
	'Angularytics',
];

function SimplePackageSelectorController(
	$scope,
	$state,
	$localStorage,
	$filter,
	Angularytics,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		beds: 3,
		handlers: handlers,
	});

	this.$onInit = () => {
		self.utilities = [
			{
				icon: 'bolt-duotone.svg',
				label: 'Electric',
				value: 'electric',
				active: true,
			},
			{
				icon: 'burn-duotone.svg',
				label: 'Gas',
				value: 'gas',
				active: false,
			},
			{
				icon: 'wifi-2-duotone.svg',
				label: 'Broadband',
				value: 'broadband',
				active: false,
			},
			{
				icon: 'tint-duotone.svg',
				label: 'Water',
				value: 'water',
				active: false,
			},
			{
				icon: 'tv-licence.svg',
				// icon: 'file-certificate-duotone.svg',
				label: 'TV Licence',
				value: 'tv_licence',
				active: false,
			},
		];

		self.account = $localStorage.account || {};
		self.inProgress = angular.isObject(self.account.services);
	};

	handlers.utilityToggleClick = (utility) => {
		utility.active = !utility.active;
		if (utility.active) {
			self.noUtilitiesSelected = false;
		}
	};

	handlers.startOverClick = () => {
		delete $localStorage.account;
		self.account = {};
		self.inProgress = false;
		Angularytics.trackEvent("Package Builder", "Started over");
	};

	handlers.submitClick = (isValid) => {
		if (isValid) {
			let activeUtilityCount = 0;
			angular.forEach(self.utilities, (utility) => {
				if (utility.active) {
					activeUtilityCount++;
				}
			});
			if (activeUtilityCount === 0) {
				self.noUtilitiesSelected = true;
			}
			else {
				// Good to go
				Angularytics.trackEvent("Package Builder", "Simple package selector - submit");
				$localStorage.account = {
					simple_utility_selector: true,
					postcode_entered: self.postcode.toUpperCase(),
					beds_entered: self.beds,
					selected_utilities: $filter('filter')(self.utilities, {active: true}, true),
				};
				$state.go('main.customer.property.build');
			}
		}
		else {
			self.submitted = true;
		}
	};

}
