angular.module('app.main.shared')
	.factory('PropertyBudgetSvc', function (RequestSvc) {

		function lookup(params) {
			return RequestSvc.get('api/customer/property/budget/lookup', params);
		}

		return {
			lookup: lookup
		};

	});
