angular.module('app.main', [
	'app.main.customer',
	'app.main.public',
	'app.main.shared'
])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {

		$httpProvider.interceptors.push('RequestInterceptor');

		$stateProvider
			.state('main', {
				abstract: true,
				templateUrl: 'app/main/templates/Main.html',
				controller: 'MainCtrl',
				data: {
					checkLogin: true,
				},
			})
			.state('main.403', {
				views: {
					'content@main': {
						templateUrl: 'app/main/templates/errors/403.html'
					}
				},
				data: {
					checkLogin: false,
				}
			})
			.state('main.404', {
				views: {
					'content@main': {
						templateUrl: 'app/main/templates/errors/404.html'
					}
				},
				data: {
					checkLogin: false,
				}
			})
			.state('main.public', {
				abstract: true,
				views: {
					'content@main': {
						templateUrl: 'app/main/templates/Public.html',
						controller: 'PublicCtrl'
					},
					'nav@main': {
						templateUrl: 'app/main/templates/PublicNav.html'
					}
				},
				data: {
					checkLogin: false,
				}
			})
			.state('main.customer', {
				abstract: true,
				views: {
					'content@main': {
						templateUrl: 'app/main/templates/Customer.html',
						controller: 'CustomerCtrl',
						controllerAs: 'Customer',
					}
				},
			})




		;

	}]);
