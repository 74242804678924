angular.module('app.main.shared')
	.service('FileDownloadSvc', [
		'RequestSvc',
		'$window',
		'FILE_DISPLAY_MODE',
		function (RequestSvc, $window, FILE_DISPLAY_MODE) {

			let download = (fileId) => {
				RequestSvc.post('api/file/get-download-key', {
					file_id: fileId,
				})
					.then((response) => {
						if (response.data.download_key) {
							$window.open($window.api_url + 'file/download/' + response.data.download_key);
						}
					});
			};

			// function download(path, FILE_DISPLAY_MODE, disk) {
			// 	var s3Disk = (angular.isString(disk)) ? ('/disk/' + disk) : '/disk';
			// 	$window.open('api/file/download/' + path + '/mode/' + FILE_DISPLAY_MODE + s3Disk);
			// }

			function remove(path, disk) {
				return RequestSvc.post('api/file/remove', path);
			}
			return {
				download: download,
				remove: remove
			}


		}
	]).constant('FILE_DISPLAY_MODE', {
	VIEW: 0,
	DOWNLOAD: 1
});

