angular.module('app.main.customer')
	.controller('ProfileCtrl', ProfileCtrl);

ProfileCtrl.$inject = [
	'$scope',
	'$rootScope',
	'$state',
	'$q',
	'notify',
	'AuthSvc',
	'ProfileSvc',
];

function ProfileCtrl($scope, $rootScope, $state, $q, notify, AuthSvc, ProfileSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		pwd: {},
		details: {},
		user: {},
		updatePassword: false,
	});

	this.$onInit = () => {
		ProfileSvc.read().then((response) => {
			self.details = response.data.user;
			self.user.hasPassword = true;
			self.user.pwd = 'password1';
			if (self.details.dob) {
				self.details.dob_day = moment(self.details.dob).format('DD');
				self.details.dob_month = moment(self.details.dob).format('MM');
				self.details.dob_year = moment(self.details.dob).format('YYYY');
			}
			self.loading = false;
		});

	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.updateProfilePic = () => {
		return ProfileSvc.profilePicModal().then(function(result) {
			var params = {
				profile_pic_base64: result
			};
			self.savingProfileImage = true;
			ProfileSvc.saveProfilePic(params).then(function(response) {
				self.savingProfileImage = false;
				notify({
					message: 'Profile picture updated'
				});
				angular.extend(AuthSvc.getRawUser(), {
					avatar: response.data.url
				});
			});
		});
	};

	handlers.removeProfilePic = () => {
		return ProfileSvc.removeProfilePic().then(function() {
			angular.extend(AuthSvc.getRawUser(), {
				avatar: null
			});
		});
	};

	handlers.updatePassword = (isValid) => {
		if (isValid) {
			angular.extend(self.user, {
				email: self.details.email,
			});
			return ProfileSvc.updatePassword(self.user)
				.then(function (response) {
					self.updatePasswordErrors = false;
					self.updatePassword = false;
					notify({
						message: 'Password updated'
					});
				}, function (errors) {
					self.updatePasswordErrors = errors;
				});
		}
		else {
			self.updatePasswordForm.submitted = true;
		}
	};

	handlers.submitDetailsClick = function (isValid) {
		// Validate dob
		self.dobValidation = true;
		let dob = self.details.dob_year + '-' + self.details.dob_month + '-' + self.details.dob_day;
		let dobDate = moment.utc(dob, 'YYYY-MM-DD');
		if (!dobDate.isValid()) {
			self.dobValidation = false;
			isValid = false;
		}

		angular.extend(self.details, {
			dob: dobDate,
		});

		// Validate email address
		self.emailValidation = true;
		let emailTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if(!emailTest.test(String(self.details.email).toLowerCase())) {
			self.emailValidation = false;
			isValid = false;
		}

		if (isValid) {
			return ProfileSvc.save(self.details).then((response) => {
				notify({
					message: 'Successfully updated your profile',
				});
			}, (errors) => {
				self.details.errors = errors;
			});
		}
		else {
			self.details.submitted = true;
		}
	};

}
