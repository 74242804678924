angular.module('app.main')
	.controller('HardSoftDeleteDialogCtrl', [
		'$log',
		'$scope',
		'$mdDialog',
		'data',
		function ($log,
				  $scope,
				  $mdDialog,
				  data) {


			$scope.title = data.title;
			$scope.subTitle = data.subTitle;
			$scope.cancelText = (angular.isString(data.cancelText)) ? data.cancelText : 'Cancel';
			$scope.deleteStatus = 'soft';

			function cancel() {
				$mdDialog.cancel();
			}

			function submit() {
				$mdDialog.hide($scope.deleteStatus);
			}

			var self = $scope.Dialog = {
				methods: {
					cancel: cancel,
					submit: submit
				}
			}
		}
	]);