angular.module('app.main')
	.directive('popover', function ($document, $window) {
		return {
			restrict: 'A',
			transclude: true,
			scope: {
				open: '=?',
				onSelect: '&',
				onClose: '&',
				appendTo: '@',
			},
			replace: false,
			template: '<div ng-transclude></div>',
			link: function (scope, el, attr, ngModelCtrl) {
				scope.$watch(function() {
					return scope.open;
				}, function(open) {
					if (open) {
						scope.isOpen = true;
					}
					else {
						scope.isOpen = false;
						scope.$parent.open = false;
						scope.onClose();
						angular.element(scope.appendTo)
							.find('.po-container')
							.remove();
					}
				});



				scope.appendTo = attr.appendTo;
				scope.offsetTop = scope.offsetLeft = 0;
				let offsetString = attr.popoverOffset;
				if (angular.isDefined(offsetString)) {
					let offsetArr = offsetString.split(' ');
					if (offsetArr.length === 2) {
						scope.offsetLeft = parseFloat(offsetArr[0].trim()) || 0;
						scope.offsetTop = parseFloat(offsetArr[1].trim()) || 0;
					}
				}

				if (angular.isDefined(attr.popoverPosition)) {
					scope.popoverPosition = attr.popoverPosition;
				}
				if (angular.isDefined(attr.popoverValign)) {
					scope.popoverVAlign = attr.popoverValign;
				}

			},
			controller : function($scope){

			}
		};
	});


angular.module('app.main')
	.directive('popoverTrigger', function ($document, $window, $timeout, popoverSvc, $parse) {
		return {
			restrict: 'E',
			require : '^popover',
			transclude: true,
			replace: true,
			template: '<div ng-transclude class="popover-transclude"></div>',
			link: function (scope, el, attr, ngModelCtrl) {
				$document.on('click', function (e) {
					let keepOpen = scope.$eval(attr.keepOpen);
					if (el !== e.target && !el[0].contains(e.target) && !keepOpen) {
						scope.$apply(function () {
							if (scope.$parent.isOpen) {
								scope.$parent.isOpen = false;
								scope.$parent.open = false;
								scope.$parent.onClose();
								angular.element(document.body)
									.find('.po-container')
									.remove();
							}
						});
					}

				});

				el.on('click', function(event) {
					closeAll();
					let appendTo = null;
					if (angular.isDefined(scope.$parent.appendTo)) {
						appendTo = document.getElementById('cs-column-center');
					}
					else {
						appendTo = document.body;
					}

					let body = angular.element(appendTo);

					let panelEl = el.parent().find('.popover-panel-container');
					let newContainer = angular.element("<div class='po-container popover-panel'></div>");

					popoverSvc.add({
						panelEl: panelEl,
						container: newContainer,
						el: el,
						scope: scope,
					});

					newContainer.append(panelEl);
					setPosition(el, newContainer, body);
					if (newContainer) {
						angular.element(body)
							.append(newContainer);

					}
					scope.$apply(function() {
						scope.$parent.isOpen = !scope.$parent.isOpen;
						scope.$parent.open = !scope.$parent.open;
					});

					body.on('scroll', function() {
						setPosition(el, newContainer, body);

					});

					event.stopPropagation();
				});

				function setPosition(el, newContainer, body) {
					// Calculate if it should be on the left or right, depending on if user has
					// explicitly set it
					if (angular.isUndefined(scope.$parent.popoverPosition)) {
						let screenMiddle = $window.innerWidth / 2;
						let elementRight = el.offset().left + el.width();
						scope.$parent.popoverPosition = (elementRight <= screenMiddle) ? 'left' : 'right';
					}

					// Calculate if it should be on the top or bottom
					let windowHeight = $window.innerHeight;
					let remainingBottomSpace = windowHeight - el.offset().top + el.height();


					let left = el.offset().left;
					let leftOffset = (angular.isDefined(scope.$parent.offsetLeft)) ? scope.$parent.offsetLeft : 0;
					if (scope.$parent.popoverPosition === 'right') {
						left += el.width();
					}
					left += leftOffset;


					let topPosition = el.offset().top + el.height() + scope.$parent.offsetTop + $('body').scrollTop();
					let bottomPosition = null;
					if (remainingBottomSpace < 250) {
						// topPosition = null;
						// bottomPosition = $('body').scrollTop();

					}

					newContainer.css({
						top: topPosition,
						// bottom: bottomPosition,
						left: left,
						position: 'absolute'
					});
				}

				function closeAll() {
					let popovers = popoverSvc.get();
					angular.forEach(popovers, function(item) {
						if (angular.isDefined(item.el.scope())) {
							item.el.parent()
								.find('.popover-transclude')
								.append(item.panelEl);
							item.container.remove();
							item.el.scope().$parent.isOpen = false;
							item.el.scope().$parent.open = false;
						}
					});
					popoverSvc.empty();
				}
			}
		};
});



angular.module('app.main')
	.directive('popoverContent', function ($document) {
		return {
			restrict: 'E',
			require : '^popover',
			transclude: true,
			replace: true,
			templateUrl: 'app/main/directives/popover/popover.html',
			link: function(scope) {

				scope.$watch(function() {
					return scope.$parent.isOpen
				}, function(isOpen) {
					scope.popoverPosition = scope.$parent.popoverPosition;
					scope.popoverVAlign = scope.$parent.popoverVAlign;
					scope.isEnabled = isOpen;
				});
			}
		};
	});

angular.module('app.main').service('popoverSvc', function() {
	let self = this;
	let popoverArray = [];

	self.add = function(item) {
		popoverArray.push(item);
	};

	self.get = function() {
		return popoverArray;
	};

	self.empty = function() {
		popoverArray = [];
	}
});