angular.module('app.main')
	.service('PanelSvc', [
		'$mdPanel',
		'$state',
		'$rootScope',
		function ($mdPanel,
				  $state,
				  $rootScope) {

			var animation = $mdPanel.newPanelAnimation();
			animation.withAnimation($mdPanel.animation.FADE);
			animation.duration(300);

			var panelConfig = {
				animation: animation,
				attachTo: angular.element(document.body),
				hasBackdrop: true,
				trapFocus: true,
				clickOutsideToClose: false,
				escapeToClose: true,
				focusOnOpen: true
			};

			function show(config, params, templateUrl, toState, onOpen, onClose) {
				if (angular.isObject(config)) {
					angular.merge(panelConfig, config);
				}

				var panelState = {};

				angular.extend(panelConfig, {
					controller: 'PanelCtrl',
					controllerAs: 'Panel',
					templateUrl: 'app/main/services/panel/Panel.html',
					locals: {
						params: angular.extend(params, {templateUrl: templateUrl})
					},
					onDomAdded: function() {
						if (angular.isFunction(onOpen)) {
							onOpen();
						}

						angular.element(document.body)
							.css('overflow', 'hidden');

					},
					onDomRemoved: function() {
						if (angular.isFunction(onClose)) {
							onClose();
						}

						angular.element(document.body)
							.css('overflow', 'auto');
					},
					onOpenComplete: function() {
						$rootScope.$broadcast('onPanelOpenComplete');
					}
				});

				$mdPanel.open(panelConfig)
					.then(function (response) {
						self.panelRef = response;
					});

				if (angular.isObject(toState)) {
					$state.go(toState.name, toState.params, {notify: false});
				}
				else if (angular.isString(toState)) {
					$state.go(toState, {}, {notify: false});
				}
			}

			return {
				show: show
			}

		}
	]);
