angular.module('app.main.customer.account-settings',[])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {

		$stateProvider
			.state('main.customer.account-settings', {
				abstract: true,
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_settings/AccountSettingMain.html',
						controller: 'AccountSettingMainCtrl',
						controllerAs: 'SettingsMain',
					}
				},
			})

			.state('main.customer.account-settings.save', {
				url: '/account/settings/save',
				views: {
					'content@main.customer.account-settings': {
						templateUrl: 'app/main/modules/customer/_settings/_account/AccountSave.html',
						controller: 'AccountSettingSaveCtrl',
						controllerAs: 'AccountSettingSave',
					}
				},
				ncyBreadcrumb: {
					label: 'Account Settings'
				},
			})

			.state('main.customer.account-settings.payment-methods', {
				url: '/account/payment-methods',
				views: {
					'content@main.customer.account-settings': {
						templateUrl: 'app/main/modules/customer/_settings/_paymentMethods/PaymentMethods.html',
						controller: 'PaymentMethodsCtrl',
						controllerAs: 'PaymentMethod',
					}
				},
				ncyBreadcrumb: {
					label: 'Payment Methods'
				},
			})

		;

	}]);
