angular.module('app.main.customer.property')
	.controller('MeterReadSaveCtrl', MeterReadSaveCtrl);

MeterReadSaveCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$filter',
	'PropertySvc',
	'MeterReadSvc',
	'MeterReadTypeSvc',
	'AuthSvc',
];

function MeterReadSaveCtrl(
	$scope,
	$state,
	$q,
	$filter,
	PropertySvc,
	MeterReadSvc,
	MeterReadTypeSvc,
	AuthSvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		options: {},
		//
		readingValidationStatus: false,
	});

	this.$onInit = () => {
		self.activeAccount = AuthSvc.getRawAccount();
		let promises = {
			properties: PropertySvc.options({filter: {account: self.activeAccount.id}}),
			readingTypes: MeterReadTypeSvc.options(),
		};
		$q.all(promises).then((responses) => {
			self.options.properties = responses.properties.data.property_options;
			self.options.meterTypes = responses.readingTypes.data.reading_type_options;

			let customerType = $filter('filter')(self.options.meterTypes, (type) => {
				return type.value === 'customer';
			});
			self.reading_type = customerType[0];
		});
		PropertySvc.options({filter: {account: self.activeAccount.id }}).then((response) => {

			// self.property = self.options.properties[0];
			// handlers.addressSelected();

			self.loading = false;
		});
	};


	// Handlers --------------------------------------------------------------------------------------------------------

	handlers.goBackClick =  () => {
		$state.go('main.customer.dashboard.index');
	};

	handlers.addressSelected = () => {
		self.readingValidationStatus = false;
		self.loadingMeters = true;
		MeterReadSvc.meterOptions({property_id: self.property.id}).then((response) => {
			self.propertyUtilityTypes = response.data.property_utility_type_collection;
			self.loadingMeters = false;
		});
	};

	handlers.readValidation = (isValid, endpoint) => {
		let normalRead = '';
		let lowRead = '';
		angular.forEach(endpoint.meter.new_reading.normal, function(normal) {
			normalRead += normal;
		});
		angular.forEach(endpoint.meter.new_reading.low, function(low) {
			lowRead += low;
		});
		let params = {
			meter: endpoint.meter,
			normal: parseInt(normalRead) || null,
			low: parseInt(lowRead) || null,
			reading_type: {
				id: self.reading_type.id,
			}
			// read_at: endpoint.meter.new_reading.read_at,
		};

		/** Do a check */
		if (!endpoint.confirmedReading) {
			let checkParams = angular.copy(params);
			self.readingValidationStatus = false;
			checkParams.read_at = moment();

			return MeterReadSvc.check(checkParams)
				.then((response) => {
					self.readingValidationStatus = response.data.read_ok ? response.data.read_ok : false;
				}, (errors) => {
					endpoint.errors = errors;
					console.log(error);
				}).finally(() => {
				if (!self.readingValidationStatus && !endpoint.confirmedReading) {
					endpoint.readingValidationFailed = true;
				} else {
					return self.handlers.submitClick(isValid, params, endpoint);
				}
			});
		} else {
			self.handlers.submitClick(isValid, params, endpoint);
		}

	};

	handlers.submitClick = (isValid, params, endpoint) => {
		if (isValid) {
			endpoint.errors = null;
			return MeterReadSvc.save(params).then((response) => {
				angular.extend(endpoint, {
					submitted: true,
				});
			}, (errors) => {
				endpoint.errors = errors;
			});
		}
	};

	handlers.meterReadChanged = (endpoint) => {
		if (endpoint.readingValidationFailed) {
			self.readingValidationStatus = false;
			endpoint.readingValidationFailed = false;
		}
	}
}
