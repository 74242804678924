angular.module('app.main')
	.directive('scrollPosition', function ($window) {
		return {
			scope: {
				scroll: '=scrollPosition'
			},
			link: function (scope, element, attrs) {
				// SR Changed 2019-08-19: Hope I've not broken anything here, but this stopped working since it's now the <html> element that scrolls rather than the <body>
				// angular.element($window).on('scroll', () => {
				// 		scope.scroll = element.offset().top - angular.element($window).scrollTop();
				// 		console.info('scroll is now: ', scope.scroll);
				// 		console.info('window scroll top');
				// 		console.info(angular.element($window).scrollTop());
				// 		console.info('element scroll position');
				// 		console.info(element.offset().top);
				//
				// });

				let windowEl = angular.element($window);

				let handler = () => {
					scope.scroll = element.offset().top - windowEl.scrollTop();
				};

				// let windowEl = angular.element(document).find('html');
				// console.info(windowEl);
				//
				// let handler = function (args) {
				// 	console.info('hit');
				// 	console.info(element.offset().top);
				// 	scope.scroll = 0 - element.offset().top;
				// };
				//
				let throttled = _.throttle(scope.$apply.bind(scope, handler), 50);
				// windowEl.on('scroll', throttled);
				// windowEl.on("touchmove", scope.$apply.bind(scope, handler));

				windowEl[0].addEventListener('scroll', throttled, {
					capture: true,
					passive: true
				});

				handler();

			}
		};
	});
