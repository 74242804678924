angular.module('app.main.customer.account-setup')
    .controller('AccountSetupCtrl', AccountSetupCtrl);

AccountSetupCtrl.$inject = [
    '$scope',
    '$state',
    '$q',
    '$filter',
    '$localStorage',
    'moment',
    'notify',
    'ConfirmationDialog',
    'AuthSvc',
    'AccountSetupSvc',
];

function AccountSetupCtrl(
    $scope,
    $state,
    $q,
    $filter,
    $localStorage,
    moment,
    notify,
    ConfirmationDialog,
    AuthSvc,
    AccountSetupSvc,
) {

    let self = this;
    let handlers = {};

    angular.extend(self, {
        loading: true,
        handlers: handlers,
        steps: [],
        hide: false,
        hidden: !!($localStorage.accountSetupNotification),
    });

    /**
     *  @todo rewrite account-setup due to changes to User sign-up
     *  Users will/should have a 'skeleton' Group/Account created on set-up.
     *  This will mean a more accurate/simplified set-up report
     */

    this.$onInit = () => {
        if (!self.user) {
            self.hidden = true;
            return;
        }

        let promises = {
            setup: AccountSetupSvc.setup({id: self.user.id}),
        };

        $q.all(promises)
            .then((response) => {
                self.steps = response.setup.data.setup;
            }, () => {
                console.log('something went wrong');
            }).finally(() => {
            self.loading = false;
        });
    };

    // Handlers --------------------------------------------------------------------------------------------------------

    handlers.close = () => {
        if (self.hide) {
            $localStorage.accountSetupNotification = true;
        }
        self.hidden = !self.hidden;
    };
}