angular.module('app.main.customer')
	.controller('DashboardCtrl', DashboardCtrl);

DashboardCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'$filter',
	'$timeout',
	'$localStorage',
	'RequestSvc',
	'AuthSvc',
	'CustomerAccountSvc',
	'PropertySvc',
];

function DashboardCtrl(
	$scope,
	$state,
	$q,
	$filter,
	$timeout,
	$localStorage,
	RequestSvc,
	AuthSvc,
	CustomerAccountSvc,
	PropertySvc,
) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loading: true,
		handlers: handlers,
		user: {},
		billingGroups: [],
		properties: {},
	});

	this.$onInit = () => {

		let user = AuthSvc.getRawUser();
		// Do we have multiple properties for this active account?
		// If so we'll redirect to properties, otherwise go directly to the property overview
		self.activeAccount = AuthSvc.getRawAccount();
		if (!user.seen_quick_start_guide || !angular.isObject(self.activeAccount)) {
			console.log('redirecting..', self.activeAccount);
			$state.go('main.customer.dashboard.quick-start-guide');
		}
		else if (angular.isDefined(self.activeAccount)) {
			if (self.activeAccount.property_count === 1 && angular.isDefined(self.activeAccount.single_property_id)) {
				$state.go('main.customer.property.overview', {propertyId: self.activeAccount.single_property_id});
			}
			else {
				$state.go('main.customer.property.index');
			}
		}

		self.loading = false;

		// let promises = {
		// 	user: AuthSvc.getRawUser(),
		// 	account: AuthSvc.getRawAccount(),
		// 	accounts: AuthSvc.getRawAccounts(),
		// };
		// $q.all(promises)
		// 	.then((responses) => {
		// 		self.user = responses.user;
		// 		self.account = responses.account;
		// 		self.accounts = responses.accounts;
		//
		// 		if (self.accounts) {
		// 			// account
		// 			CustomerAccountSvc.read({id: self.account.id}).then((response) => {
		// 				self.account = response.data.account;
		// 			}).finally(() => {
		// 				self.loading = false;
		// 			});
		// 			// properties
		// 			PropertySvc.index({filter: {account: self.account.id}})
		// 				.then((response) => {
		// 					self.properties = response.data.property_collection;
		// 					console.log(self.properties);
		// 				}).finally(() => {
		// 				setChartColours();
		// 			});
		// 		}
		// 	}).finally(() => {
		// 	self.loading = false;
		// });
	};

	// $scope.$on('groupChanged', (event, group) => {
	// 	self.billingGroup = group;
	// 	PropertySvc.index({filter: {billingGroup: group.id}})
	// 		.then((response) => {
	// 			self.properties = response.data.property_collection
	// 		}).finally(() => {
	// 		setChartColours();
	// 	});
	// });

	// function setChartColours() {
	// 	angular.forEach(self.properties, (property) => {
	// 		if (property.active_budget_period) {
	// 			let status = property.active_budget_period.forecast_status;
	// 			let barColour = '';
	// 			let trackColour = '';
	// 			let difference = property.active_budget_period.budget_forecast_difference;
	// 			if (status === 'under-budget') {
	// 				barColour = '#13CE66';
	// 				trackColour = '#f5fdf4';
	// 			}
	// 			else if (status === 'over-budget') {
	// 				barColour = '#FF7849';
	// 				trackColour = '#fbd692';
	// 				difference -= 100;
	// 			}
	// 			else if (status === 'over-fair-usage') {
	// 				barColour = '#FF4949';
	// 				trackColour = '#f9f2f2';
	// 				difference = property.active_budget_period.forecast_fair_usage_difference - 100;
	// 			}
	// 			angular.extend(property.active_budget_period, {
	// 				budget_forecast_difference: difference,
	// 				// pieOptions: {
	// 				// 	size: 50,
	// 				// 	animate:{
	// 				// 		duration:1000,
	// 				// 		enabled:true
	// 				// 	},
	// 				// 	barColor:barColour,
	// 				// 	trackColor: trackColour,
	// 				// 	scaleColor:false,
	// 				// 	lineWidth:10,
	// 				// 	lineCap:'circle'
	// 				// }
	// 			});
	// 		}
	// 	});
	// }

	// Handlers --------------------------------------------------------------------------------------------------------

	handlers.addPropertyClick = () => {
		$state.go('main.customer.property.add');
	};

	handlers.propertyViewClick = (property) => {
		// $state.go('main.customer.services.index', {billingGroupId: self.billingGroup.id, propertyId: property.id});
		$localStorage.propertyId = property.id;
		$state.go('main.customer.property.overview', {propertyId: property.id});
	};

	handlers.billingGroupClick = (group) => {
		$scope.$emit('groupChanged', group);
		$timeout(() => {
			$state.go('main.customer.dashboard.index', {id: group.id});
		});
	};
}
