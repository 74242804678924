angular.module('app.main.customer.myAccount', [

])
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {


		$stateProvider
			.state('main.customer.my-account', {
				abstract: true,
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_myAccount/MyAccountMain.html',
						controller: 'MyAccountMainCtrl',
						controllerAs: 'AccountMain',
					}
				},
				data: {
					hideMenu: true,
				}
			})

			.state('main.customer.my-account.profile', {
				url: '/account/profile',
				views: {
					'content@main.customer.my-account': {
						templateUrl: 'app/main/modules/customer/_myAccount/_profile/Profile.html',
						controller: 'ProfileCtrl',
						controllerAs: 'Profile',
					}
				},
				ncyBreadcrumb: {
					label: 'Profile'
				},
			})

			.state('main.customer.my-account.contact-us', {
				url: '/contact-us',
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_myAccount/_contact/ContactUs.html',
						controller: 'CustomerContactUsCtrl',
						controllerAs: 'Contact',
					}
				},
				ncyBreadcrumb: {
					label: 'Contact Us'
				},
			})


		;

	}]);
