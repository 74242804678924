angular.module('app.main.shared')

	.filter('yesNo', function () {
		return function (input) {
			return input ? 'Yes' : 'No';
		};
	})

	.filter('ifNull', function () {
		return function (input, replaceWith) {
			return (angular.isUndefined(input) || _.isNull(input)) ? replaceWith : input;
		}
	})

	.filter('ifEmpty', function () {
		return function (input, replaceWith) {
			if (angular.isUndefined(input) || input === '') {
				return replaceWith;
			}
			else {
				return input;
			}
		}
	})

	.filter('orIfEmpty', function () {
		return function (input, replaceWith) {
			if (angular.isUndefined(input) || input == null || input === '') {
				return replaceWith;
			}
			else {
				return input;
			}
		}
	})

	.filter('firstName', function () {
		return function (input) {
			if (angular.isDefined(input)) {
				var nameParts = input.split(" ");
				return nameParts[0].charAt(0)
					.toUpperCase() + nameParts[0].slice(1);
			}
		}
	})

	.filter('tickCross', ['$sce', function ($sce) {
		return function (input, showCross) {
			var output;
			if (input !== null) {
				if (input) {
					output = $sce.trustAsHtml('<i class="material-icons">done</i>');
				}
				else {
					if (showCross) {
						output = $sce.trustAsHtml('<i class="material-icons">clear</i>');
					}
				}
			}
			return output;
		};
	}])

	.filter('growth', [
		'$sce',
		function ($sce) {
			return function (input) {
				var output;
				if (input >= 0) {
					output = $sce.trustAsHtml('<i class="fa fa-arrow-up text-success"></i>');
				}
				else {
					output = $sce.trustAsHtml('<i class="fa fa-arrow-down text-danger"></i>');
				}
				return output;
			};
		}
	])

	.filter('numberToLetter', [
		'$sce',
		function ($sce) {
			return function (input, lowercase) {
				if (lowercase) {
					return String.fromCharCode(97 + input);
				}
				else {
					return String.fromCharCode(65 + input);
				}
			}
		}
	])

	.filter('positive', function () {
		return function (input) {
			if (!input) {
				return 0;
			}

			return Math.abs(input);
		};
	})

	.filter('cardNumber', function () {
		return function (input) {
			return "****-****-****-" + input;
		};
	})

	.filter('setDecimal', function () {
		return function (input, places) {
			if (isNaN(input))
				return input;
			// If we want 1 decimal place, we want to mult/div by 10
			// If we want 2 decimal places, we want to mult/div by 100, etc
			// So use the following to create that factor
			var factor = "1" + new Array(+(places > 0 && places + 1)).join("0");
			return Math.round(input * factor) / factor;
		};
	})

	.filter('bytes', function () {
		return function (bytes, precision) {
			if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
			if (typeof precision === 'undefined') precision = 1;
			var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
				number = Math.floor(Math.log(bytes) / Math.log(1024));
			return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
		};
	})

	.filter('arraychunk', function () {
		return function (input, chunksize) {
			var tempArray = [];
			var chunks = Math.round(input.length / chunksize);
			var offset = 0;
			var i;
			for (i = 0; i < chunks; i++) {
				tempArray.push(input.slice(offset, offset + chunksize));
				offset += chunksize;
			}
			return tempArray;
		};
	})

	.filter('suffix', function () {
		return function (input, suffix) {
			return String(input) + String(suffix);
		};
	})

	.
	filter('prefix', function () {
		return function (input, prefix) {
			return String(prefix) + String(input);
		};
	})

	.filter('toPercent', function () {
		return function (input, precision) {
			if (input === false || input == null) {
				return 'n/a';
			}
			return String(parseFloat(input)
				.toFixed(precision)) + '%';
		};
	})

	.filter('ddDiscount', function () {
		return function (input, precision) {
			var discountRate = parseFloat(input);
			return (discountRate * 100).toFixed(0) + '%';
		};
	})

	.filter('duration', function () {
		return function (seconds, minUnit) {
			minUnit = angular.isDefined(minUnit) ? minUnit : 'minute';
			var hours = Math.floor(seconds / 3600);
			var minutes = Math.floor(seconds / 60);
			var minutesSubHours = minutes - hours * 60;
			var string = '';
			if (hours || minutesSubHours) {
				if (hours) {
					string += String(hours) + (hours == 1 ? 'hr ' : 'hrs ');
				}
				if (minutesSubHours) {
					string += String(minutesSubHours) + (minutesSubHours == 1 ? 'min ' : 'mins ');
				}
			}
			else {
				string += '-';
			}
			string = string.trim();
			return string;
		};
	})

	.filter('json', function ($sce) {
		return function (object) {
			return '<pre>' + JSON.stringify(object, null, 4) + "</pre>";
		}
	})

	.filter('trusted', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	})

	.filter('trustedUrl', ['$sce', function ($sce) {
		return function(url) {
			return $sce.trustAsResourceUrl(url);
		};
	}])

	.filter('filenameTruncate', function () {
		return function (input, maxSize) {
			retValue = '';
			fileInfo = /(.+?)(\.[^.]*$|$)/.exec(input);
			if (Array.isArray(fileInfo)) {
				maxSize = angular.isDefined(maxSize) ? maxSize : 5;

				if (fileInfo[1].length > maxSize) {
					retValue = fileInfo[1].substr(0, maxSize) + "...";
					retValue += fileInfo[2];
				}
				else {
					retValue = input;
				}
			}
			else {
				retValue = input;
			}

			return retValue;

		}
	})

	.filter('titlecase', function () {
		return function (input) {

			if (angular.isString(input)) {
				var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

				input = input.toLowerCase();
				return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (match, index, title) {
					if (index > 0 && index + match.length !== title.length &&
						match.search(smallWords) > -1 && title.charAt(index - 2) !== ":" &&
						(title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
						title.charAt(index - 1).search(/[^\s-]/) < 0) {
						return match.toLowerCase();
					}

					if (match.substr(1).search(/[A-Z]|\../) > -1) {
						return match;
					}

					return match.charAt(0).toUpperCase() + match.substr(1);
				});
			}
			else {
				return '';
			}
		}


	})

	.filter('numberEx', function () {
		return function (input, dp) {
			dp = dp || 2;
			return input.toFixed(dp) * 1;
		};
	})

	.filter('accountTypeInitial', function () {
		return function (input) {
			return input.charAt(0)
				.toUpperCase();
		};
	})

	.filter('highlightMentions', [
		'$sce',
		function ($sce) {
			return function(input) {
				// let str = input.replace(/John/g , "<span style='color:red'>$&</span>");
				let str = input.replace(/\B\@([\w\-]+)/gim, '<span style="color:#13CE66">$&</span>');
				return $sce.trustAsHtml(str);
			}
		}
	])


	.filter('range', function() {
		return function(input, total) {
			total = parseInt(total);

			for (var i=0; i<total; i++) {
				input.push(i);
			}

			return input;
		};


});

;

