angular.module('app.main.public.auth')
	.controller('InviteModalCtrl', [
		'$log',
		'$scope',
		'$mdDialog',
		function ($log,
				  $scope,
				  $mdDialog) {

			function close() {
				$mdDialog.hide();
			}

			function submit() {
				$mdDialog.hide(self.inviteCode);
			}

			var self = $scope.InviteModalCtrl = {
				inviteCode: '',
				methods: {
					close: close,
					submit: submit
				}
			}


		}
	]);