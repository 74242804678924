angular.module('app.main.public.short-url', [])
    .config([
        '$stateProvider',
        function ($stateProvider) {

            let baseTemplateUrl = 'app/main/modules/public/modules/short-url/templates/';

            $stateProvider
                .state('main.public.short-url', {
                    url: '^/url-expired',
                    views: {
                        'content@main.public': {
                            templateUrl: baseTemplateUrl + 'Expired.html',
                            controller: 'ShortUrlCtrl',
                            controllerAs: 'ShortUrl'
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Short URL'
                    },
                });
        }
    ]);
