angular.module('app.main')
	.controller('ContractTermsCtrl', ContractTermsCtrl);

ContractTermsCtrl.$inject = [
	'$scope',
	'$state',
	'$q',
	'ContractTermsSvc',
];

function ContractTermsCtrl($scope, $state, $q, ContractTermsSvc) {
	let self = this;
	let handlers = {};

	angular.extend(self, {
		loaded: false,
		handlers: handlers,
	});

	this.$onInit = () => {
		ContractTermsSvc.view().then((response) => {
			self.contractView = response.data.view;
			self.loaded = true;
		});
		self.loading = true;
	};

	// Handlers --------------------------------------------------------------------------------------------------------
	handlers.submitClick = function () {

	};
}