angular.module('app.main.customer')
	.config(['$httpProvider', '$stateProvider', function ($httpProvider,
														  $stateProvider) {

		$stateProvider
			.state('main.customer.account-payment', {
				url: '/account-payment',
				params: {
					skipSummary: undefined,
					showDifferentAccountNotice: false,
				},
				views: {
					'content@main.customer': {
						templateUrl: 'app/main/modules/customer/_accountPayment/AccountPayment.html',
						controller: 'AccountPaymentCtrl',
						controllerAs: 'AccountPayment',
					}
				},
				ncyBreadcrumb: {
					label: 'Account Payment',
				},
				data: {
					isWizardPage: true,
					hidePaymentRequiredBanner: true,
				}
			})
		;

	}]);
