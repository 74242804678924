angular.module('app.main.customer.menu', [])
    .component('customerMenu', {
        templateUrl: 'app/main/modules/customer/components/menu/templates/CustomerMenu.html',
        controller: 'CustomerMenuCtrl',
        controllerAs: 'CustomerMenu',
        // bindings: {
        //     state: '<',
        // },
    })
;
