angular.module('app.main.shared')
    .factory('AccountServiceRemindersSvc', [
        'RequestSvc',
        function (RequestSvc) {
            return {
                index,
            };

            function index(params) {
                return RequestSvc.get('api/customer/account/reminders', params);
            }
        }
    ]);
