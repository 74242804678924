// Dropzone.autoDiscover = false;

(function () {
	'use strict';
	let app = angular.module('app', [
		'templates',
		'ngMaterial',
		'ngAnimate',
		'ngSanitize',
		'ui.router',
		'app.main',
		'filters',
		'slick',
		'angularPromiseButtons',
		'angularMoment',
		'ngMessages',
		'ngPasswordMeter',
		'focusOn',
		'vAccordion',
		'ng-currency',
		'mgo-angular-wizard',
		'md.data.table',
		'ncy-angular-breadcrumb',
		// 'thatisuday.dropzone',
		'duScroll',
		'cgNotify',
		'ui.select',
		// 'ui.tinymce',
		'highcharts-ng',
		'moment-picker',
		'checklist-model',
		'as.sortable',
		'ngIdle',
		'angularInlineEdit',
		'720kb.tooltips',
		'ngclipboard',
		'ngFileUpload',
		'updateMeta',
		'angularytics',
		'ngImgCrop',
		// 'slick',
		'ngIntercom',
		'LocalStorageModule',
		// 'daterangepicker',
		// 'snap',
		'focus-if',
		'nemLogging',
		// 'uiGmapgoogle-maps',
		// 'infinite-scroll',
		// 'google.places',
		// 'mgcrea.bootstrap.affix',
		'mentio',
		'bw.paging',
		'ngStorage',
		'countTo',
		'angularjs-stripe-elements',
		'ngTextTruncate',
		// 'simple-web-notification',
		'angular-web-notification',
		'ngCsv',
		'angularSlideables',
		'easypiechart',
		'vcRecaptcha',
	]);

	app.config([
		'$locationProvider',
		'$urlRouterProvider',
		'$mdThemingProvider',
		'$mdDateLocaleProvider',
		// '$compileProvider',
		'$localStorageProvider',
		'momentPickerProvider',
		'moment',
		// 'dropzoneOpsProvider',
		'IdleProvider',
		'KeepaliveProvider',
		'$intercomProvider',
		'tooltipsConfProvider',
		'localStorageServiceProvider',
		// 'snapRemoteProvider',
		// 'uiGmapGoogleMapApiProvider',
		'StripeElementsProvider',
		'AngularyticsProvider',
		'$sceDelegateProvider',

		function ($locationProvider,
				  $urlRouterProvider,
				  $mdThemingProvider,
				  $mdDateLocaleProvider,
				  // $compileProvider,
				  $localStorageProvider,
				  momentPickerProvider,
				  moment,
				  // dropzoneOpsProvider,
				  IdleProvider,
				  KeepaliveProvider,
				  $intercomProvider,
				  tooltipsConfProvider,
				  localStorageServiceProvider,
				  // snapRemoteProvider,
				  // uiGmapGoogleMapApiProvider,
				  StripeElementsProvider,
				  AngularyticsProvider,
				  $sceDelegateProvider,
				  ) {

			// gRecaptcha.initialize({key: '6LeiEtAaAAAAADcnmDLsEGovBdlrfVQ4haWCpRf1'}); // returns a promise

			$sceDelegateProvider.resourceUrlWhitelist([
				// Adding 'self' to the whitelist, will allow requests from the current origin.
				'self',
				// Using double asterisks here, will allow all URLs to load.
				// We recommend to only specify the given domain you want to allow.
				'**'
			]);

			// AngularyticsProvider.setEventHandlers(['GoogleUniversal']);

			StripeElementsProvider.setAPIKey(window.config.app.stripe_key);

			// snapRemoteProvider.globalOptions = {
			// 	minPosition:-200,
			// 	maxPosition:200,
			// };

			// uiGmapGoogleMapApiProvider.configure({
			// 	key: window.config.app.google_maps.api_key,
			// 	libraries: 'places',
			// });

			localStorageServiceProvider
				.setPrefix('mango-'); // don't think this is used

			$localStorageProvider.setKeyPrefix('mango-');

			// $compileProvider.preAssignBindingsEnabled(true);
			// $intercomProvider.appID('enl9pxm8');
			// $intercomProvider.asyncLoading(true);

			// use the HTML5 History API
			$locationProvider.html5Mode(true);

			// For any unmatched url, redirect to 404
			$urlRouterProvider.otherwise(function ($injector) {
				var $state = $injector.get('$state');
				$state.go('main.404');
			});

			// // set default format for moments when converted to JSON
			// moment.fn.toJSON = function () {
			// 	return this.format();
			// };

			momentPickerProvider.options({
				'max-view': 'month',
				'format': 'ddd Do MMM YYYY',
				'locale': 'en'
			});

			// $mdThemingProvider.disableTheming();

			$mdDateLocaleProvider.firstDayOfWeek = 1;

			$mdDateLocaleProvider.parseDate = function (dateString) {
				//var m = moment(dateString, 'L', true);
				//return m.isValid() ? m.toDate() : new Date(NaN);
			};
			$mdDateLocaleProvider.formatDate = function (date) {
				var m = moment(date);
				return angular.isDefined(date) && m.isValid() ? m.format('L') : '';
			};

			// dropzoneOpsProvider.setOptions({
			// 	url: '/api/file/upload',
			// 	acceptedFiles: 'application/pdf',
			// 	parallelUploads: 3,
			// 	maxFilesize: '100',
			// 	uploadMultiple: false
			// });

			// configure Idle settings
			IdleProvider.idle(14300); // Just below 4 hours (laravel session timeout) 14300
			IdleProvider.timeout(60); // in seconds (how long the user has to stay logged in)
			// KeepaliveProvider.interval(1); // in seconds

			tooltipsConfProvider.configure({
				'smart': true,
				'size': 'small',
				'speed': 'fast',
				'tooltipTemplateUrlCache': true,
				'class': 'um-tooltip'
				//etc...
			});

		}
	]);

	app.value('angularMomentConfig', {
		timezone: 'Europe/London'
	});

	app.run([
		'$state',
		'$rootScope',
		'$global',
		'Angularytics',
		'AuthSvc',
		'amMoment',
		'$window',
		'notify',
		'$timeout',
		'Idle',
		'$mdMedia',
		'$location',
		'$intercom',
		'$localStorage',
		function ($state,
				  $rootScope,
				  $global,
				  Angularytics,
				  AuthSvc,
				  amMoment,
				  $window,
				  notify,
				  $timeout,
				  Idle,
				  $mdMedia,
				  $location,
				  $intercom,
				  $localStorage) {


			Angularytics.init();

			$rootScope.$mdMedia = $mdMedia;
			// $intercom.boot({});

			notify.config({
				container: 'body',
				startTop: 55,
				position: 'right',
				duration: 5000,
				templateUrl: 'app/main/templates/notify-template/gc-notify-template.html'
			});

			$rootScope.Auth = {
				is: AuthSvc.is,
				can: AuthSvc.can,
				user: AuthSvc.getRawUser
			};

			$rootScope.$state = $state;

			$rootScope.toggleDrawMenu = function() {
				$rootScope.drawMenuOpen = !$rootScope.drawMenuOpen;
			};

			$rootScope.in_array = (needle, haystack) => {
				return typeof haystack === 'object' && haystack.indexOf(needle) > -1;
			};

			// copy global config to root scope
			$rootScope.config = $global.config;

			$rootScope.$on('onLogin', function () {
				$intercom.shutdown();
			});

			$rootScope.$on('logout', function () {
				$rootScope.$broadcast('loggingOut');
				AuthSvc.setUser(null);
				$state.go('main.public.auth.login');
			});

			$rootScope.$on('$locationChangeSuccess', function() {
				$rootScope.actualLocation = $location.absUrl();
			});

			$rootScope.$watch(function () {return $location.absUrl()}, function (newLocation, oldLocation) {
				if($rootScope.actualLocation === newLocation) {
					$rootScope.$broadcast('onBrowserBackButtonClick');
				}
			});

			$rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
				$rootScope.$broadcast('stateChanged');
				// Hide intercom on the build package page and we're on mobile
				if (toState.name === 'main.customer.property.build' && $mdMedia('xs')) {
					$intercom.shutdown();
				}
				// else {
				// 	$intercom.boot({});
				// }

			});

			$rootScope.$on('$stateChangeStart', function () {
				// console.log('starting changing state');
			});

			$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
				// Stop the page from initially loading and then suddenly reverting
				$rootScope.authenticatingInProgress = true;
				angular.extend($rootScope, {
					state: {
						name: toState.name
					}
				});

				let checkLogin = toState.data.checkLogin;
				if (toState.data.hideMenu) {
					$rootScope.hideAccountMenu = true;
				}
				else {
					$rootScope.hideAccountMenu = false;
				}

				if (toState.data.isWizardPage) {
					$rootScope.isWizardPage = true;
				}
				else {
					$rootScope.isWizardPage = false;
				}

				if (toState.data.hidePaymentRequiredBanner || toState.data.hideMenu) {
					$rootScope.hidePaymentRequiredBanner = true;
				}
				else {
					$rootScope.hidePaymentRequiredBanner = false;
				}

				// Do we have a user object, ie. have we just refreshed the page
				if (!angular.isObject(AuthSvc.user) && checkLogin === true) {
					AuthSvc.getUser()
						.then(function (user) {
							checkAccess(event, toState, toParams, user);
							$rootScope.authenticatingInProgress = false;
						});
				}
				else {
					checkAccess(event, toState, toParams, AuthSvc.user);
					$rootScope.authenticatingInProgress = false;
				}

			});

			function checkAccess(event, toState, toParams, user) {
				let stateData = angular.isObject(toState.data) ? toState.data : {};
				if (angular.isDefined(stateData.requireLogin)) {
					if (!AuthSvc.isAuthenticated()) {
						$state.go('main.public.index');
					}
					else {
						// Authenticated successfully. Set the current user to the root scope for easy accessibility
						angular.extend($rootScope, {
							auth: {
								user: user
							}
						});

						// Authenticated OK but do we have access to this state?
						var roles = user.role_list.split(',');
						var rolePassed = (angular.isUndefined(stateData.authorisedRoles) || _.intersection(stateData.authorisedRoles, roles).length > 0);
						if (!rolePassed) {
							console.error('Permission denied');
							$state.go('main.403');
						}
					}
				}
				else {
					// We've reached a state which doesn't require authentication, but if we are authenticated we
					// should redirect to the web app anyway.
					// NOTE: This does restrict logged in users from accessing the public site.
					if (toState.name.indexOf('main.public') !== -1 && AuthSvc.isAuthenticated()) {
						var isSwitchSite = toState.name.indexOf('main.public.switch') >= 0;
						if (!isSwitchSite) {
							event.preventDefault();
							// AuthSvc.redirect();
						}
					}
				}
			}

			// amMoment.changeLocale('en-gb');

			$rootScope.stopImpersonating = function () {
				AuthSvc.stopImpersonating();
			};

			$rootScope.showChat = function () {
				// $intercom.boot({});
				// $intercom.show();
			};
		}
	]);


	function isCurrentStatePublic(toState) {
		var isPublic = false;
		if (toState.name.indexOf('main.public') !== -1) {
			return true;
		}
		else {
			return false;
		}
	}

	function isPublicState(toState) {
		var publicStates = [
			'main.404',
			'main.403',
			// 'main.public'
		];

		var isPublic = false;
		publicStates.forEach(function (val) {
			if (toState.name.indexOf(val) !== -1) {
				isPublic = true;
				return false;
			}
		});

		return isPublic;
	}

})();
