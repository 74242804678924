angular.module('app.main.customer.property.services', [])
    .config([
        '$stateProvider',
        function ($stateProvider) {

            let baseTemplateUrl = 'app/main/modules/customer/_property/_services/';

            $stateProvider
                .state('main.customer.property.services', {
                    abstract: true,
                    data: {
                        checkLogin: true,
                    }
                })

                .state('main.customer.property.services.index', {
                    url: '^/services/{propertyId}',
                    params: {
                        propertyId: undefined,
                    },
                    views: {
                        'content@main.customer': {
                            templateUrl: baseTemplateUrl + 'ServicesIndex.html',
                            controller: 'ServicesIndexCtrl',
                            controllerAs: 'ServicesIndex',
                        }
                    },
                    data: {
                        isWizardPage: true,
                    },
                    ncyBreadcrumb: {
                        label: 'Overview | {{ property }}',
                    },
                })

                .state('main.customer.property.services.add', { // remove this!
                    url: '^/services/{propertyId}',
                    params: {
                        propertyId: undefined,
                    },
                    views: {
                        'content@main.customer': {
                            templateUrl: baseTemplateUrl + 'ServicesIndex.html',
                            controller: 'ServicesIndexCtrl',
                            controllerAs: 'ServicesIndex',
                        }
                    },
                    data: {
                        isWizardPage: true,
                    },
                    ncyBreadcrumb: {
                        label: 'Overview | {{ property }}',
                    },
                })
            ;

        }
    ]);
