angular.module('app.main.shared.utility')
	.factory('UtilityBillItemSvc', [
		function () {

			function transformBillItem(billItem) {
				if (angular.isObject(billItem)) {
					if (angular.isString(billItem.starts_at)) {
						billItem.starts_at = billItem.starts_at ? moment(billItem.starts_at) : null;
					}
					if (angular.isString(billItem.ends_at)) {
						billItem.ends_at = billItem.ends_at ? moment(billItem.ends_at) : null;
					}
				}
				return billItem;
			}

			return {
				transformBillItem: transformBillItem
			};

		}
	]);
